import { useTranslation } from "react-i18next"
import { useCompanyInfo } from "../../hooks/useCompanyInfo"
import { useUserInfo } from "../../hooks/useUserInfo"
import { useTemplateVariables } from "../../utils/templateUtils"
import Utils from "../../utils/utils"
import { Grid } from "@mui/material"
import AmountView from "./amountView"
import Constants from '../../constants'

export default function MaxWithdrawalDetailsView() {
    const user = useUserInfo()
    const company = useCompanyInfo()
    const template = useTemplateVariables()
    const { t } = useTranslation()

    const playerVirtualCredits = user?.playerVirtualCredits ?? 0
    const withdrawalFee = user?.withdrawalFee ?? 0
    const maxWithdrawal = user?.maxWithdrawal

    const currencyCode = template.design === Constants.templateDesignModern ? '' : company?.currencyCode ?? ''

    if (maxWithdrawal && maxWithdrawal > 0) {
        var maxWithdrawalDisplay = ''

        var maxWithdrawalAmount = 0
        if (maxWithdrawal < 1) {
            //Percentage
            maxWithdrawalAmount = (maxWithdrawal * playerVirtualCredits)
        } else {
            //Fixed
            maxWithdrawalAmount = maxWithdrawal
        }

        //Check if need deduct withdrawal fee
        if (withdrawalFee && withdrawalFee > 0) {
            //Deduct max withdrawal with fee
            let finalFees = (withdrawalFee / 100) * maxWithdrawalAmount
            maxWithdrawalAmount = maxWithdrawalAmount - finalFees
        }

        if (maxWithdrawal < 1) {
            //Percentage
            let maxWithdrawalPercentage = maxWithdrawal * 100

            maxWithdrawalDisplay = `${Utils.shared.getAmountString(maxWithdrawalAmount, currencyCode, company)} (${maxWithdrawalPercentage}%)`
        } else {
            //Fixed
            maxWithdrawalDisplay = Utils.shared.getAmountString(maxWithdrawalAmount, currencyCode, company)
        }

        const title = 'Max Withdrawal'

        if (template.design === Constants.templateDesignModern) {
            return (
                <Grid item flexGrow={1}>
                    <AmountView title={title} value={maxWithdrawalDisplay} />
                </Grid>
            )
        } else {
            return (
                <div style={{ fontSize: '12px' }}>
                    {`${title} : ${maxWithdrawalDisplay}`}
                </div>
            )
        }
    } else {
        return null
    }
}