import { Box, CircularProgress, Typography } from "@mui/material";
import { useTemplateVariables } from "../../utils/templateUtils";

export default function CircularProgressWithLabel({ value = 0, max = 30 }) {
    const template = useTemplateVariables()

    let percentage = value / max * 100
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={25} variant="determinate" sx={{ color: template.mainFontColor }} value={percentage} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color={template.mainFontColor}>
                    <b>{value}</b>
                </Typography>
            </Box>
        </Box>
    );
}