import { useNavigate } from "react-router-dom"
import { AlertDialogContext, CompanyInfoContext, PopupContext, RegisterPopupContext } from "../../App"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import Constants, { templateDesignModern } from '../../constants'
import BaseButton from "./button"
import { AppRegistrationOutlined } from "@mui/icons-material"
import Utils from "../../utils/utils"
import { useTemplateVariables } from "../../utils/templateUtils"

export default function RegisterButton({ fullWidth = true }) {
    const navigate = useNavigate()
    const template = useTemplateVariables()

    //Contexts
    const { companyInfo } = useContext(CompanyInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setPopup } = useContext(PopupContext)
    const { setOpenRegister } = useContext(RegisterPopupContext)
    const { t } = useTranslation()

    // Register button theme
    var registerButtonColor = companyInfo.company?.registerButtonColor ?? ''
    if (registerButtonColor.length === 0) {
        registerButtonColor = Constants.defaultButtonColor
    }

    var registerButtonFontColor = companyInfo.company?.registerButtonFontColor ?? ''
    if (registerButtonFontColor.length === 0) {
        registerButtonFontColor = Constants.defaultButtonFontColor
    }

    const registerButtonCSS = companyInfo?.company?.registerButtonCSS

    const getBorderRadius = () => {
        if (template.design === templateDesignModern) {
            return '16px'
        } else {
            return null
        }
    }
    const getIcon = () => {
        if (template.design === templateDesignModern || registerButtonCSS) {
            return null
        } else {
            return (
                <AppRegistrationOutlined />
            )
        }
    }
    const getPadding = () => {
        if (template.design === templateDesignModern) {
            return '8px 36px'
        } else {
            return null
        }
    }
    const getEnableGradient = () => {
        if (template.design === templateDesignModern) {
            return false
        } else {
            return null
        }
    }

    return (
        <BaseButton
            title={t('HOME_REGISTER')}
            icon={getIcon()}
            fullWidth={fullWidth}
            color={registerButtonColor}
            textColor={registerButtonFontColor}
            className={'registerButton'}
            customCSS={registerButtonCSS}
            padding={getPadding()}
            borderRadius={getBorderRadius()}
            enableGradient={getEnableGradient()}
            onTap={() => {
                Utils.shared.showRegisterPopup({
                    companyInfo: companyInfo,
                    setAlertDialog: setAlertDialog,
                    setPopup: setPopup,
                    navigate: navigate,
                    referralCode: null,
                    setOpenRegister: setOpenRegister
                })
            }} />
    )
}