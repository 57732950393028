import { useTranslation } from "react-i18next"
import { useCompanyInfo } from "../../hooks/useCompanyInfo"
import { useUserInfo } from "../../hooks/useUserInfo"
import { useTemplateVariables } from "../../utils/templateUtils"
import Utils from "../../utils/utils"
import Constants from '../../constants'
import { Grid } from "@mui/material"
import AmountView from "./amountView"

export default function WithdrawalFeeDetailsView() {
    const user = useUserInfo()
    const company = useCompanyInfo()
    const template = useTemplateVariables()
    const { t } = useTranslation()

    const playerVirtualCredits = user?.playerVirtualCredits ?? 0
    const maxWithdrawal = user?.maxWithdrawal
    const withdrawalFee = user?.withdrawalFee ?? 0
    const currencyCode = template.design === Constants.templateDesignModern ? '' : company?.currencyCode ?? ''

    if (withdrawalFee) {
        let withdrawalFeeDisplay = `(${withdrawalFee}%)`

        var maxWithdrawalAmount = 0
        if (maxWithdrawal && maxWithdrawal < 1) {
            //Percentage
            maxWithdrawalAmount = (maxWithdrawal * playerVirtualCredits)
        } else if (maxWithdrawal && maxWithdrawal > 0) {
            //Fixed
            maxWithdrawalAmount = maxWithdrawal
        } else {
            maxWithdrawalAmount = playerVirtualCredits
        }

        let finalFees = (withdrawalFee / 100) * maxWithdrawalAmount
        let finalFeesDisplay = Utils.shared.getAmountString(finalFees, currencyCode, company)

        const title = t('WITHDRAWAL_WITHDRAWAL_FEE')

        if (template.design === Constants.templateDesignModern) {
            return (
                <Grid item flexGrow={1}>
                    <AmountView title={title} value={`${finalFeesDisplay} ${withdrawalFeeDisplay}`} />
                </Grid>
            )
        } else {
            return (
                <div style={{ fontSize: '12px' }}>
                    {`${title} : ${finalFeesDisplay} ${withdrawalFeeDisplay}`}
                </div>
            )
        }
    }

    return null
}