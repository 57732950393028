import { Stack } from "@mui/material";
import { useUserInfo } from "../../hooks/useUserInfo";
import { useTemplateVariables } from "../../utils/templateUtils";
import { Person } from "@mui/icons-material";

export default function AvatarView({ size = '40px', primaryColor = null }) {
    const user = useUserInfo()
    const template = useTemplateVariables()
    const fullName = user?.fullName ?? ''

    return (
        <Stack sx={{
            height: size,
            width: size,
            borderRadius: size,
            border: '2px solid #36B37E',
            color: primaryColor ?? template.mainFontColor,
        }} alignItems={'center'} justifyContent={'center'} >
            <Stack sx={{
                height: `calc(${size} - 4px)`,
                width: `calc(${size} - 4px)`,
                borderRadius: `calc(${size} - 4px)`,
                backgroundColor: 'white'
            }} alignItems={'center'} justifyContent={'center'}>
                {
                    (fullName.length > 0) ? (
                        <div style={{
                            textTransform: 'uppercase',
                            fontSize: `calc(${size} * 0.5)`
                        }}><b>{fullName.charAt(0)}</b></div>
                    ) : (
                        <Person />
                    )
                }
            </Stack>
        </Stack>
    )
}