import { IconButton, Stack } from "@mui/material"
import { useTemplateVariables } from "../../utils/templateUtils"
import { ContentCopy } from "@mui/icons-material"
import { SnackBarContext } from "../../App"
import { useContext } from "react"
import { useTranslation } from "react-i18next"

export default function LabelDisplayView({
    title,
    value,
    alignItems = 'start',
    titleFontSize = '12px',
    valueFontSize = '16px',
    showCopyButton = false,
    customButton,
    onCustomButtonTapped
}) {
    const template = useTemplateVariables()
    const { setSnackBar } = useContext(SnackBarContext)
    const { t } = useTranslation()

    return (
        <Stack direction={'row'} alignItems={'center'}>
            <Stack sx={{ flexGrow: 1 }} alignItems={alignItems}>
                <div style={{
                    fontSize: titleFontSize,
                    fontWeight: '400',
                    color: '#828282',
                    lineHeight: '16px'
                }}>{title}</div>
                <Stack direction={'row'} alignItems={'start'} spacing={'2px'} sx={{
                    fontSize: valueFontSize,
                    fontWeight: '700',
                    letterSpacing: '1.28px',
                    color: '#585858',
                    lineHeight: '24px'
                }}>
                    <div>{value}</div>
                </Stack>
            </Stack>
            {
                showCopyButton ? (
                    <IconButton onClick={() => {
                        navigator.clipboard.writeText(value)
                                setSnackBar(snackBar => ({
                                    ...snackBar,
                                    title: t('GLOBAL_COPIED'),
                                    isOpen: true
                                }))
                    }}>
                        <ContentCopy htmlColor="#828282" />
                    </IconButton>
                ) : customButton ? (
                    <IconButton onClick={() => {
                        onCustomButtonTapped()
                    }}>
                        {customButton}
                    </IconButton>
                ) : null
            }
        </Stack>
    )
}