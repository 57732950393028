import { Modal, Box, Backdrop, Zoom } from '@mui/material'
import { useContext } from 'react'
import { CompanyInfoContext, PopupContext } from '../../App'
import Constants from '../../constants'
import BaseButton from '../../components/button/button'
import useScreenType from '../../hooks/useScreenType'

function BasePopup({ children = null, isOpen = null, onClosed = null }) {
    //Context
    const screenType = useScreenType()
    const { popup, setPopup } = useContext(PopupContext)
    const { companyInfo } = useContext(CompanyInfoContext)

    //Theme
    var buttonColor = Constants.defaultMainColor
    let otherButtonColor = companyInfo?.company?.otherButtonColor
    if (otherButtonColor && otherButtonColor.length > 0) {
        buttonColor = otherButtonColor
    }

    var buttonFontColor = Constants.defaultMainColor
    let otherButtonFontColor = companyInfo?.company?.otherButtonFontColor
    if (otherButtonFontColor && otherButtonFontColor.length > 0) {
        buttonFontColor = otherButtonFontColor
    }

    const style = {
        width: popup?.dynamicWidth ? null : (screenType.isMobile ? null : '800px'),
        maxWidth: screenType.isMobile ? '90%' : '80%',
        maxHeight: '85%',
        backgroundColor: 'black',
        color: 'white',
        border: `2px solid ${Constants.defaultPopupBorderColor}`,
        borderRadius: '5px',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        overflow: 'scroll',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
            display: 'none'
        }
    }

    //Handlers
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' && (popup?.shouldNotClosePopupOnBackClick ?? false)) {
            return
        }

        if (onClosed) {
            setPopup(popup => ({
                ...popup,
                children: null,
                dynamicWidth: false,
                onClosed: () => { }
            }))

            onClosed()
        } else {
            if (popup.onClosed()) {
                setPopup(popup => ({
                    ...popup,
                    isOpen: false,
                    children: null,
                    dynamicWidth: false,
                    shouldNotClosePopupOnBackClick: false,
                    onClosed: () => { }
                }))

                popup.onClosed()
            } else {
                setPopup(popup => ({
                    ...popup,
                    isOpen: false,
                    children: null,
                    dynamicWidth: false,
                    shouldNotClosePopupOnBackClick: false,
                    onClosed: () => { }
                }))
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen ?? popup.isOpen}
            onClose={handleClose}
            components={{
                Backdrop
            }}
            closeAfterTransition
        >
            <Zoom in={isOpen ?? popup.isOpen}>
                <div style={{ maxWidth: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', outline: 'none' }}>
                    <Box sx={style}>
                        {children ?? popup.children}
                        {(popup.showCloseButton ?? false) ? (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 10 }}>
                                <BaseButton title={'OK'} color={buttonColor} textColor={buttonFontColor} fullWidth={false} onTap={handleClose} />
                            </div>
                        ) : null}
                    </Box>
                </div>
            </Zoom>
        </Modal>
    )
}

export default BasePopup