import React, { useContext, useTransition } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AlertDialogContext } from '../../App';
import { useTranslation } from 'react-i18next';

function AlertDialog() {
  const { alertDialog, setAlertDialog } = useContext(AlertDialogContext)
  const { t } = useTranslation()
  const isConfirmation = alertDialog.isConfirmation ?? false

  const handleConfirm = (event, reason) => {
    var onConfirmed = () => {}
    if (typeof(alertDialog?.onConfirmed()) === 'function') {
      onConfirmed = alertDialog?.onConfirmed()
    }

    setAlertDialog(alertDialog => ({
      ...alertDialog,
      title: 'SERVER_HEADER_ERROR',
      translationData: null,
      closeButtonTitle: 'OK',
      isOpen: false,
      isConfirmation: false,
      onConfirmed: onConfirmed
    }))
  }

  const handleClose = (event, reason) => {
    var onClosed = () => {}
    if (typeof(alertDialog?.onClosed()) === 'function') {
      onClosed = alertDialog?.onClosed()
    }

    setAlertDialog(alertDialog => ({
      ...alertDialog,
      title: 'SERVER_HEADER_ERROR',
      translationData: null,
      closeButtonTitle: 'OK',
      isOpen: false,
      isConfirmation: false,
      onClosed: onClosed
    }))
  }

  const getTitle = () => {
    //Check if there is translation data
    if (alertDialog?.translationData?.header?.key) {
      const header = alertDialog?.translationData?.header
      const key = header?.key ?? ''
      const dynamicValues = header?.dynamicValues

      if (key.length > 0) {
        return t(key, dynamicValues)
      }
    }

    return t(alertDialog.title ?? 'SERVER_HEADER_ERROR')
  }
  const getMessage = () => {
    //Check if there is translation data
    if (alertDialog?.translationData?.message?.key) {
      const message = alertDialog?.translationData?.message
      const key = message?.key ?? ''
      const dynamicValues = message?.dynamicValues

      if (key.length > 0) {
        return t(key, dynamicValues)
      }
    }

    return t(alertDialog.description ?? '')
  }

  return (
    <div>
      <Dialog
        open={alertDialog.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={
          {
            style: {
              backgroundColor: '#282828',
              color: 'white'
            }
          }
        }
      >
        <DialogTitle id="alert-dialog-title">
          {getTitle()}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'white', whiteSpace: 'pre-line' }} id="alert-dialog-description">
            {getMessage()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: 'white' }} onClick={isConfirmation ? handleConfirm : handleClose}>{alertDialog.closeButtonTitle}</Button>
          {isConfirmation ? (<Button style={{ color: 'white' }} onClick={(event, reason) => {
            setAlertDialog(alertDialog => ({
              ...alertDialog,
              isOpen: false,
            }))
          }}>{'Cancel'}</Button>) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog


