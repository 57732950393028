import React, { useContext } from "react";
import Marquee from "react-fast-marquee";
import { CompanyInfoContext } from '../../App';
import Constants from '../../constants'
import Utils from "../../utils/utils";
import { Box, Stack } from "@mui/material";
import { Sensors } from "@mui/icons-material";
import useScreenType from "../../hooks/useScreenType";

function RunningTextBar() {
  const { companyInfo } = useContext(CompanyInfoContext)
  const templateDesign = companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic
  const newsTicker = companyInfo?.company?.newsTicker ?? ''
  const screenType = useScreenType()

  //Theme Color
  var color = Constants.defaultMainColor
  let mainFontColor = companyInfo.company.mainFontColor
  if (mainFontColor && mainFontColor.length > 0) {
    color = mainFontColor
  }

  //Theme Background
  var imageUrl = `${process.env.PUBLIC_URL}/images/runningtextborder.png`

  let newsTickerBackgroundImage = companyInfo.company.newsTickerBackgroundImage
  if (newsTickerBackgroundImage && newsTickerBackgroundImage.length > 0) {
    imageUrl = Utils.shared.getCompanyImagesLink(companyInfo, newsTickerBackgroundImage, companyInfo.company._id)
  }

  const getRunningTextView = ({isModern = false}) => {
    return (
      <Marquee gradient={false} speed={60} style={{ height: '25px', backgroundImage: isModern ? null : `url(${imageUrl})`, backgroundSize: '100% 100%', color: color }}>
        {newsTicker}
      </Marquee>
    )
  }

  if (templateDesign === Constants.templateDesignModern) {
    const getMargin = () => {
      if (screenType.isMobile) {
        return '12px'
      }

      return '12px 160px'
    }

    return (
      <Stack sx={{ margin: getMargin() }} spacing='12px' direction={'row'} alignItems={'center'} justifyContent={'center'}>
        <Sensors htmlColor={color} fontSize='medium' />
        {getRunningTextView({isModern: true})}
      </Stack>
    )
  } else {
    return getRunningTextView({isModern: false})
  }
}

export default RunningTextBar;