import { Cancel, CloseRounded } from "@mui/icons-material"
import { Box, Button, IconButton } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AlertDialogContext, ChangePwdPopupContext, CompanyInfoContext, PopupContext, UserInfoContext } from "../../App"
import { useUserInfo } from "../../hooks/useUserInfo"
import Utils from "../../utils/utils"
import HomeV1 from "./homeV1"
import HomeV2 from "./homeV2"
import Constants from '../../constants'
import { useTemplateVariables } from "../../utils/templateUtils"
import useScreenType from "../../hooks/useScreenType"

function Home() {
    let navigate = useNavigate()

    //Contexts
    const { userInfo } = useContext(UserInfoContext)
    const { companyInfo } = useContext(CompanyInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setOpenChangePwd } = useContext(ChangePwdPopupContext)
    const { setPopup } = useContext(PopupContext)
    const data = useUserInfo()
    const screenType = useScreenType()
    const template = useTemplateVariables()

    //States
    const [showSpinAndWin, setShowSpinAndWin] = useState(companyInfo?.company?.isSpinAndWinEnabled ?? false)

    //Get game categories that have game
    let companyGames = companyInfo?.companyGames ?? []
    let gameCategories = companyInfo?.gameCategories?.filter((e) => {
        let index = companyGames?.findIndex((game) => game?.game?.gameCategory?.includes(e.key)) ?? -1
        if (index > -1) {
            return true
        } else {
            return false
        }
    }) ?? []

    //Component Methods
    const getHomePage = () => {
        return (
            <HomeV1 gameCategories={gameCategories} contentHeight={template.contentHeight} onDepositTapped={() => {
                Utils.shared.navigateDeposit(userInfo, companyInfo, setAlertDialog, navigate)
            }} onWithdrawalTapped={() => {
                Utils.shared.navigateWithdrawal(userInfo, companyInfo, setAlertDialog, navigate)
            }} />
        )
    }
    const getSpinAndWinView = () => {
        let spinAndWinImage = companyInfo?.company?.spinAndWinImage
        let imageLink = spinAndWinImage ? Utils.shared.getCompanyImagesLink(companyInfo, spinAndWinImage, companyInfo?.company?._id) : `${process.env.PUBLIC_URL}/images/defaultSpinAndWin.png`

        if (showSpinAndWin) {
            return (
                <Box sx={{ position: 'fixed', bottom: '76px', right: '20px', zIndex: 2 }}>
                    <Box sx={{ position: 'relative', height: '150px', width: '150px' }}>
                        <Button onClick={() => {
                            navigate('/promotion')
                        }}>
                            <img src={imageLink} height='150px' width='auto' />
                        </Button>
                        <IconButton sx={{ position: 'absolute', top: '0px', right: '0px', height: '20px', width: '20px' }} onClick={() => {
                            setShowSpinAndWin(false)
                        }}>
                            <Cancel htmlColor="red" fontSize='small' />
                        </IconButton>
                    </Box>
                </Box>
            )
        } else {
            return null
        }
    }

    //Use Effect
    useEffect(() => {
        //Check if show first time login change password or welcome notice
        let isFirstTimeLogin = data?.isFirstTimeLogin ?? false
        if (isFirstTimeLogin) {
            //Show change password popup
            setAlertDialog(alertDialog => ({
                ...alertDialog,
                title: 'First Time Login',
                description: 'Please change your password',
                isOpen: true,
                onClosed: () => {
                    if (template.design === Constants.templateDesignModern) {
                        setOpenChangePwd(true)
                    } else {
                        navigate('/profile/settings/changePassword')
                    }
                }
            }))
        } else {
            //Show welcome notice if any
            Utils.shared.showWelcomeNotice(companyInfo, setPopup)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box sx={{ position: 'relative' }}>
            {getHomePage()}
            {/* {getSpinAndWinView()} */}
        </Box>
    )
}

export default Home