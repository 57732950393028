import { useContext, useEffect, useState } from "react"
import { UserInfoContext } from "../App"
import { useGet } from 'figbird'

export function useUserInfo() {
    //Contexts
    const { userInfo } = useContext(UserInfoContext)
    const { data } = useGet('users', userInfo?._id)

    //State
    const [user, setUser] = useState(userInfo)

    useEffect(() => {
        if (data) {
            setUser(data)
        } else {
            setUser(userInfo)
        }
    }, [data])

    return user
}