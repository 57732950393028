import React, { useContext, useEffect, useState } from "react"
import { TextField, InputAdornment, Button, MenuItem, Menu, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { KeyboardArrowDown } from '@mui/icons-material'
import { AlertDialogContext, CompanyInfoContext } from "../../App"
import Constants from '../../constants'
import InputMask from 'react-input-mask'
import Utils from "../../utils/utils"
import { useTemplateVariables } from "../../utils/templateUtils"

function InputTextField({
    title,
    value,
    placeholder = '',
    helperText,
    titleColor,
    containerColor = 'white',
    /**
    * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
    * 'tel' will show phone code in front and trigger onPhoneCodeChanged and will ignore prefix
    */
    type = 'text',
    showError = false,
    errorText = '',
    disabled = false,
    prefix = null,
    suffix = null,
    mask = null,
    maskChar = null,
    onTextChanged,
    onFileSelected = null,
    inputRef = null,
    onSubmit = null,
    onPhoneCodeChanged = null,
}) {
    //Contexts
    const { companyInfo } = useContext(CompanyInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const template = useTemplateVariables()

    //States
    const [phoneCode, setPhoneCode] = useState('')

    // For Phone Codes Only
    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color: 'white',
            backgroundColor: 'rgb(0, 0, 0, 0.9)',
            boxShadow:
                'rgb(0, 0, 0, 0.9) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: 'rgb(0, 0, 0, 0.7)',
                },
                '&:hover': {
                    backgroundColor: 'rgb(25, 25, 25, 1.0)',
                },
            },
        },
    }))
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handlePhoneCodeDropDownClick = (event) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget)
        }
    }

    const getPrefix = () => {
        switch (type) {
            case 'tel':
                let phoneCodes = companyInfo.company.phoneCodes ?? []
                if (phoneCodes.length > 0) {
                    if (phoneCodes.length === 1) {
                        return (
                            <InputAdornment position="start" >
                                <div style={{ color: titleColor ?? template.mainFontColor }} >
                                    {phoneCodes[0]}
                                </div>
                            </InputAdornment>
                        )
                    } else {
                        return (
                            <div>
                                <Button
                                    variant="text"
                                    sx={{ backgroundColor: 'transparent', color: titleColor ?? template.mainFontColor, paddingLeft: 0 }}
                                    disableElevation
                                    onClick={handlePhoneCodeDropDownClick}
                                    endIcon={<KeyboardArrowDown />}
                                >
                                    {phoneCode}
                                </Button>
                                <StyledMenu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                >
                                    {phoneCodes.map((e, index) => {
                                        return (
                                            <MenuItem key={`phoneCodeMenuItem${index}`} onClick={() => {
                                                setAnchorEl(null)
                                                setPhoneCode(e)
                                                onPhoneCodeChanged(e)
                                            }} disableRipple>
                                                {e}
                                            </MenuItem>
                                        )
                                    })}
                                </StyledMenu>
                            </div>
                        )
                    }
                } else {
                    return null
                }
            default:
                return prefix
        }
    }

    const getSuffix = () => {
        if (suffix) {
            return (
                <InputAdornment position="end">
                    {suffix}
                </InputAdornment>
            )
        } else {
            return null
        }
    }

    const getInputLabelProps = () => {
        switch (type) {
            case 'file':
                return {
                    style: {
                        color: titleColor ?? template.mainFontColor
                    },
                    shrink: true,
                }
            default:
                return {
                    style: {
                        color: titleColor ?? template.mainFontColor
                    },
                    shrink: true,
                }
        }
    }

    useEffect(() => {
        //Check type and assign default value if any
        switch (type) {
            case 'tel':
                let phoneCodes = companyInfo.company.phoneCodes ?? []
                //By default select the first if no selected
                if (!phoneCode) {
                    setPhoneCode(phoneCodes[0])

                    if (onPhoneCodeChanged) {
                        onPhoneCodeChanged(phoneCodes[0])
                    }
                }
                break
            default:
                break
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <InputMask
                mask={mask}
                maskChar={maskChar}
                label={title}
                value={value ?? ''}
                variant='outlined'
                inputMode={type === 'pin' ? 'tel' : type}
                type={type === 'pin' ? 'tel' : type}
                helperText={showError ? errorText : helperText}
                placeholder={placeholder}
                fullWidth={true}
                error={showError}
                inputRef={inputRef}
                disabled={disabled}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (onSubmit) {
                            onSubmit()
                        }
                    }
                }}
                onChange={(e) => {
                    if (type === 'pin') {
                        onTextChanged(e.target.value)
                    }
                }}
                sx={{
                    input: {
                        color: titleColor ?? template.mainFontColor,
                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                        },
                    },
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: template.design === Constants.templateDesignModern ? '#D5D5D5' : 'white'
                        },
                        "&:hover fieldset": {
                            borderColor: 'gray'
                        },
                        "&.Mui-disabled fieldset": {
                            borderColor: 'gray',
                        },
                        "& fieldset": {
                            borderColor: template.design === Constants.templateDesignModern ? '#D5D5D5' : 'white'
                        },
                        borderRadius: template.design === Constants.templateDesignModern ? '20px' : null,
                        backgroundColor: template.design === Constants.templateDesignModern ? containerColor : null,
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: template.mainFontColor,
                    },
                }}
                InputLabelProps={getInputLabelProps()}
                InputProps={
                    {
                        style: {
                            color: "white",
                        },
                        startAdornment: getPrefix(),
                        endAdornment: getSuffix(),
                    }
                }
                FormHelperTextProps={
                    {
                        style: {
                            color: showError ? 'red' : template.mainFontColor
                        }
                    }
                }
            >
                {(inputProps) => (
                    <Tooltip title={placeholder}>
                        <TextField
                            {...inputProps}
                            value={value ?? ''}
                            disabled={disabled}
                            type={type}
                            onInput={(e) => {
                                switch (type) {
                                    case 'pin':
                                        break
                                    case 'file':
                                        if (e.target.files.length > 0) {
                                            const file = e.target.files[0]
                                            if (file.size > Constants.file_upload_size_limit) {
                                                setAlertDialog(alertDialog => ({
                                                    ...alertDialog,
                                                    description: 'File size should not exceed 500kb',
                                                    isOpen: true,
                                                }))

                                                e.target.value = null
                                            } else {
                                                Utils.shared.getBase64FromFile(file).then((result) => {
                                                    onFileSelected(result, file.name)
                                                }).catch((error) => {
                                                    setAlertDialog(alertDialog => ({
                                                        ...alertDialog,
                                                        description: error.message,
                                                        isOpen: true,
                                                    }))
                                                })
                                            }
                                        }
                                        break
                                    default:
                                        if (type === 'number') {
                                            let dec = e.target.value.indexOf(".")
                                            let tooLong = e.target.value.length > dec + 3
                                            let invalidNum = isNaN(parseFloat(e.target.value))

                                            if ((dec >= 0 && tooLong) || invalidNum) {
                                                e.target.value = e.target.value.slice(0, -1)
                                            }
                                        } else if (type === 'tel') {
                                            let re = /^[0-9\b]+$/

                                            // if value is not blank, then test the regex

                                            if (!re.test(e.target.value)) {
                                                e.target.value = e.target.value.slice(0, -1)
                                            }
                                        }

                                        onTextChanged(e.target.value)
                                        break
                                }
                            }}
                        />
                    </Tooltip>
                )}
            </InputMask>
        </div>
    )
}

export default InputTextField;