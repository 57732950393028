import { Sensors } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { CompanyInfoContext } from "../../App";
import Constants from '../../constants'
import Utils from "../../utils/utils";
import './liveTransactionsViewStyles.css'
import { useTranslation } from "react-i18next"
import { useLiveDeposit, useLiveGameWithdrawal } from "./liveDepositHook";
import { TableContainer, Table, TableHead, TableRow, TableBody, Paper, Box, TableCell, Stack, Divider } from "@mui/material";
import { useTemplateVariables } from "../../utils/templateUtils";
import '../../styles/hideScrollbarStyle.css'
import useScreenType from "../../hooks/useScreenType";

export default function LiveTransactionsView() {
    const screenType = useScreenType()
    let rowFontSize = screenType.isMobile ? '12px' : '14px'

    //Context
    const { companyInfo } = useContext(CompanyInfoContext)
    const { t } = useTranslation()
    const liveDeposits = useLiveDeposit()
    const liveGameWithdrawals = useLiveGameWithdrawal()
    const template = useTemplateVariables()
    const templateDesign = companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic

    //States
    const [list, setList] = useState(Array(5).fill({
        depositContactNumber: null,
        depositAmount: null,
        withdrawalContactNumber: null,
        withdrawalAmount: null,
        game: null,
    }))

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    const getRows = () => {
        return list.map((e, index) => {
            let depositContactNumber = e?.depositContactNumber
            let depositAmount = e?.depositAmount
            let withdrawalContactNumber = e?.withdrawalContactNumber
            let withdrawalAmount = e?.withdrawalAmount
            let game = e?.game

            return (
                <TableRow key={`row${index}`} hover={true}>
                    <TableCell padding="none" sx={{ color: 'white', fontSize: rowFontSize, borderRight: '1px solid white', paddingLeft: '5px', paddingRight: '5px' }}>{depositContactNumber}</TableCell>
                    <TableCell padding="none" sx={{ color: '#caac43', fontSize: rowFontSize, borderLeft: '1px solid white', borderRight: '1px solid white', paddingLeft: '5px', paddingRight: '5px' }} align='right'><b>{depositAmount}</b></TableCell>
                    <TableCell padding="none" sx={{ color: 'white', fontSize: rowFontSize, borderLeft: '1px solid white', borderRight: '1px solid white', paddingLeft: '5px', paddingRight: '5px' }}>{withdrawalContactNumber}</TableCell>
                    <TableCell padding="none" sx={{ color: '#caac43', fontSize: rowFontSize, borderLeft: '1px solid white', borderRight: '1px solid white', paddingLeft: '5px', paddingRight: '5px' }} align='right'><b>{withdrawalAmount}</b></TableCell>
                    <TableCell padding="none" sx={{ color: 'white', fontSize: rowFontSize, borderLeft: '1px solid white', paddingLeft: '5px', paddingRight: '5px' }} align='right'>{game}</TableCell>
                </TableRow>
            )
        })
    }
    const getDepositRows = () => {
        return list.map((e, index) => {
            let depositContactNumber = e?.depositContactNumber
            let depositAmount = e?.depositAmount

            return (
                <Box sx={{ padding: '0px 8px' }}>
                    <Box sx={{ padding: '12px 24px', borderRadius: '16px', backgroundColor: '#F4F6F8' }}>
                        <Stack direction='row' spacing='16px' alignItems={'center'}>
                            <div style={{ fontSize: '14px', fontWeight: '400', lineHeight: '22px', color: '#585858' }}>{depositContactNumber}</div>
                            <div style={{ fontSize: '16px', fontWeight: '700', lineHeight: '24px', color: '#173559' }}>{depositAmount}</div>
                        </Stack>
                    </Box>
                </Box>
            )
        })
    }
    const getWithdrawalRows = () => {
        return list.map((e, index) => {
            let withdrawalContactNumber = e?.withdrawalContactNumber
            let withdrawalAmount = e?.withdrawalAmount
            let game = e?.game

            return (
                <Box sx={{ padding: '0px 8px' }}>
                    <Box sx={{ padding: '12px 24px', borderRadius: '16px', backgroundColor: '#F4F6F8' }}>
                        <Stack direction='row' spacing='16px' alignItems={'center'}>
                            <div style={{ fontSize: '14px', fontWeight: '400', lineHeight: '22px', color: '#585858' }}>{withdrawalContactNumber}</div>
                            <div style={{ fontSize: '16px', fontWeight: '700', lineHeight: '24px', color: '#173559' }}>{withdrawalAmount}</div>
                            <div style={{ fontSize: '10px', fontStyle: 'italic', fontWeight: '400', lineHeight: '120%', color: '#173559' }}>{game}</div>
                        </Stack>
                    </Box>
                </Box>
            )
        })
    }
    const getWidth = () => {
        if (screenType.isMobile) {
            return '100%'
        }

        return null
    }
    const getMinWidth = () => {
        if (screenType.isMobile) {
            return '80%'
        }

        return null
    }
    const getTitleFontSize = () => {
        if (screenType.isMobile) {
            return '18px'
        }

        return '28px'
    }
    const getTitleLineHeight = () => {
        if (screenType.isMobile) {
            return '26px'
        }

        return '36px'
    }
    const getTitleStackAlign = () => {
        if (screenType.isMobile) {
            return 'left'
        }

        return 'center'
    }
    const getTitleBorderBottomLineWidth = () => {
        if (screenType.isMobile) {
            return '25%'
        }

        return '80%'
    }

    useEffect(() => {
        let mounted = true

        if (mounted) {
            let tempList = list.map((e, index) => {
                let depositObject = liveDeposits[index]
                let depositContactNumber = Utils.shared.getMaskedPhoneNumber(depositObject?.user?.contactNumber ?? '-')
                let depositAmount = Utils.shared.getAmountString(depositObject?.amount ?? 0)

                let withdrawalObject = liveGameWithdrawals[index]
                let withdrawalContactNumber = Utils.shared.getMaskedPhoneNumber(withdrawalObject?.user?.contactNumber ?? '-')
                let withdrawalAmount = Utils.shared.getAmountString(withdrawalObject?.amount ?? 0)
                let game = withdrawalObject?.game?.label ?? '-'

                var tempObject = { ...e }
                tempObject.depositContactNumber = depositContactNumber
                tempObject.depositAmount = depositAmount
                tempObject.withdrawalContactNumber = withdrawalContactNumber
                tempObject.withdrawalAmount = withdrawalAmount
                tempObject.game = game

                return tempObject
            })

            setList(tempList)
        }

        return function cleanup() {
            mounted = false
        }
    }, [liveDeposits, liveGameWithdrawals])

    let showLiveTransactions = companyInfo?.company?.showLiveTransactions ?? false
    if (showLiveTransactions) {
        if (templateDesign === Constants.templateDesignModern) {
            return (
                <Stack spacing='32px' alignItems={getTitleStackAlign()} sx={{ padding: '16px 0px' }} >
                    <Stack sx={{
                        fontSize: getTitleFontSize(),
                        fontWeight: '700',
                        lineHeight: getTitleLineHeight(),
                        color: color,
                        padding: '0px 16px'
                    }} alignItems={getTitleStackAlign()}>
                        {'Live Transaction'}
                        <Box sx={{ borderBottom: `4px solid ${template.navigationFontColor}`, width: getTitleBorderBottomLineWidth() }} />
                    </Stack>
                    <Stack direction='row' spacing='24px' className="hideScrollBar" sx={{
                        overflowX: 'scroll',
                        width: `calc(${getWidth()} - 32px)`,
                        paddingBottom: '10px',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                    }}>
                        <Box sx={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.16)', borderRadius: '16px', backgroundColor: 'white', minWidth:'320px' }}>
                            <Stack spacing='8px' sx={{ paddingBottom: '8px' }}>
                                <Box sx={{ backgroundColor: '#167FFC', padding: '12px 24px', borderRadius: '16px 16px 0px 0px' }}>
                                    <Stack direction='row' spacing='16px' alignItems={'center'}>
                                        <div style={{ color: 'white', fontSize: '14px', fontWeight: '600', lineHeight: '22px' }}>{t('HOME_DEPOSIT')}</div>
                                        <Box sx={{ padding: '0px 5px 0px 5px', backgroundColor: '#FF5630', borderRadius: '16px' }}>
                                            <Stack direction='row' spacing='5px' alignItems={'center'} sx={{ padding: '4px 4px' }} >
                                                <Sensors htmlColor='white' />
                                                <Box sx={{ color: 'white', fontSize: '12px', fontWeight: '400', lineHeight: '20px' }}>LIVE</Box>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Box>
                                {getDepositRows()}
                            </Stack>
                        </Box>
                        <Box sx={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.16)', borderRadius: '16px', backgroundColor: 'white', minWidth:'320px' }}>
                            <Stack spacing='8px' sx={{ paddingBottom: '8px' }}>
                                <Box sx={{ backgroundColor: '#36B37E', padding: '12px 24px', borderRadius: '16px 16px 0px 0px' }}>
                                    <Stack direction='row' spacing='16px' alignItems={'center'}>
                                        <div style={{ color: 'white', fontSize: '14px', fontWeight: '600', lineHeight: '22px' }}>{t('HOME_WITHDRAWAL')}</div>
                                        <Box sx={{ padding: '0px 5px 0px 5px', backgroundColor: '#FF5630', borderRadius: '16px' }}>
                                            <Stack direction='row' spacing='5px' alignItems={'center'} sx={{ padding: '4px 4px' }} >
                                                <Sensors htmlColor='white' />
                                                <Box sx={{ color: 'white', fontSize: '12px', fontWeight: '400', lineHeight: '20px' }}>LIVE</Box>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Box>
                                {getWithdrawalRows()}
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
            )
        } else {
            return (
                <Box sx={{ padding: '10px' }}>
                    <TableContainer component={Paper} className="hideScrollBar" sx={{
                        backgroundColor: 'black',
                        border: '1px solid white',
                    }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={5} sx={{ color: color, fontSize: rowFontSize }}>
                                        <Stack direction='row' spacing='5px' sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                            <Box>
                                                <b>LIVE TRANSACTION</b>
                                            </Box>
                                            <Box className="blink_view" sx={{ padding: '0px 5px 0px 5px', backgroundColor: 'red', borderRadius: '3px' }}>
                                                <Stack direction='row' spacing='5px'>
                                                    <Sensors htmlColor='white' />
                                                    <Box sx={{ color: 'white' }}>LIVE</Box>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell size='small' padding="none" align="center" colSpan={2} sx={{
                                        color: 'white',
                                        fontSize: rowFontSize,
                                        backgroundColor: '#388E3C',
                                        borderRight: '1px solid white'
                                    }}><b>{t('HOME_DEPOSIT')}</b></TableCell>
                                    <TableCell size='small' padding="none" align="center" colSpan={3} sx={{
                                        color: 'white',
                                        fontSize: rowFontSize,
                                        backgroundColor: '#f54242',
                                        borderLeft: '1px solid white'
                                    }}><b>{t('HOME_WITHDRAWAL')}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(list?.length ?? 0) > 0 ? getRows() : <TableRow key='norecordrow'>
                                    <TableCell component="th" scope="row" align="center" colSpan={5} sx={{ color: 'white' }}>
                                        No Record Found
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )
        }
    } else {
        return null
    }
}