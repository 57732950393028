import { Checkbox, FormControlLabel, Stack } from "@mui/material"
import { CompanyInfoContext, PopupContext } from "../../App"
import parse from 'html-react-parser'
import { useTranslation } from "react-i18next"
import { useContext, useState } from "react"
import Constants from '../../constants'
import BaseButton from "../button/button"
import Utils from "../../utils/utils"
import './popupStyles.css'

function WelcomeNotice() {
    //Contexts
    const { companyInfo } = useContext(CompanyInfoContext)
    const { popup, setPopup } = useContext(PopupContext)
    const { t } = useTranslation()

    //States
    const [isStopShowingChecked, setIsStopShowingChecked] = useState(false)

    let company = companyInfo.company
    let notice = company.notice ?? ''
    let todayDate = Utils.shared.toDateOnly(Date.now())

    var buttonColor = Constants.defaultMainColor
    let otherButtonColor = companyInfo.company.otherButtonColor
    if (otherButtonColor && otherButtonColor.length > 0) {
        buttonColor = otherButtonColor
    }

    return (
        <Stack className="popupContainer" sx={{ overflowX: 'hidden' }}>
            {parse(notice)}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginRight: '10px' }}>
                <FormControlLabel sx={{ color: 'white', fontSize: '12px', margin: 0 }} control={
                    <Checkbox sx={{
                        color: buttonColor,
                        '&.Mui-checked': {
                            color: buttonColor,
                        },
                    }} checked={isStopShowingChecked} onChange={(event, checked) => {
                        setIsStopShowingChecked(checked)
                    }} />
                } disableTypography={true} label={t('HOME_POPUP_DO_NOT_SHOW')} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 10 }}>
                <BaseButton title={'OK'} fullWidth={false} onTap={() => {
                    //Check if stop showing checkbox is checked
                    if (isStopShowingChecked) {
                        window.localStorage.setItem(`${Constants.keys_welcome_notice}${todayDate}`, 'true')
                    }

                    setPopup(popup => ({
                        ...popup,
                        isOpen: false,
                        children: null,
                        onClosed: () => {}
                    }))
                }} />
            </div>
        </Stack>
    )
}

export default WelcomeNotice