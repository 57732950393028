import Utils from "../../utils/utils"
import Constants from '../../constants'
import BaseButton from "../button/button"
import { useNavigate } from "react-router-dom"
import { AlertDialogContext, BonusPopupContext, CompanyInfoContext, SnackBarContext, UserInfoContext } from "../../App"
import { request } from "../../feathers/feathersClient"
import { useContext, useEffect, useRef } from "react"
import parse from 'html-react-parser'
import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Check, Close } from "@mui/icons-material"
import { useTemplateVariables } from "../../utils/templateUtils"

export default function BonusPopup({ bonus, requirement, onClosed }) {
    const navigate = useNavigate()

    //Contexts
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setSnackBar } = useContext(SnackBarContext)
    const { bonusPopup, setBonusPopup } = useContext(BonusPopupContext)
    const { t } = useTranslation()
    const client = request({
        setAlertDialog: setAlertDialog, 
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })
    const { i18n } = useTranslation()
    const template = useTemplateVariables()

    //Ref
    const bonusLastClaimed = useRef(Date.now())
    const isBonusClaiming = useRef(false)

    const currency = bonus?.company?.currencyCode ?? ''
    const isEligible = bonus?.isEligible ?? false
    const name = bonus?.name ?? 'Bonus'
    const description = bonus?.description ?? ''

    const minTopup = bonus?.minTopup ?? 0
    const minTimesTopup = bonus?.minTimesTopup ?? 0
    const bonusPayoutWithdrawalAmount = bonus?.bonusPayoutWithdrawalAmount ?? 0
    const playerTurnoverRolloverAmountAfterClaimed = bonus?.playerTurnoverRolloverAmountAfterClaimed ?? 0
    const bonusPayoutWithdrawalType = bonus?.bonusPayoutWithdrawalType
    const bonusClaimButtonCSS = companyInfo?.company?.bonusClaimButtonCSS
    const bonusCancelButtonCSS = companyInfo?.company?.bonusCancelButtonCSS

    //Theme Color
    var color = Constants.defaultMainColor
    const mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    //Styles
    const styles = {
        requirement: {
            color: 'white',
            fontSize: 12
        }
    }

    //Component Methods
    const getRequirementRow = ({ title, isFullfill = false, showFullfillment = true }) => {
        return (
            <Stack direction={'row'} spacing='5px' alignItems={'center'}>
                <Typography sx={styles.requirement}>
                    {title}
                </Typography>
                {
                    showFullfillment ? (
                        bonusPopup?.callingBonusRequirement ? (
                            <CircularProgress size={styles.requirement.fontSize} sx={{ color: color }} />
                        ) : isFullfill ? (
                            <Check fontSize="small" htmlColor="green" />
                        ) : (
                            <Close fontSize="small" htmlColor="red" />
                        )
                    ) : null
                }
            </Stack>
        )
    }
    const getRequirementView = () => {
        if (minTopup > 0 ||
            minTimesTopup > 0 ||
            (bonusPayoutWithdrawalType === 1 && bonusPayoutWithdrawalAmount > 0) ||
            (bonusPayoutWithdrawalType === 2 && bonusPayoutWithdrawalAmount > 0)) {
            const bonusRequirementMetChecklist = requirement?.bonusRequirementMetChecklist
            const claimWhenCreditLessThan = bonusRequirementMetChecklist?.claimWhenCreditLessThan
            const registeredDownline = requirement?.registeredDownline ?? 0
            const paymentMethod = bonusPopup?.callingBonusRequirement ? null : bonusRequirementMetChecklist?.paymentMethod

            return (
                <Stack sx={{ marginLeft: '10px', marginRight: '10px' }}>
                    <Typography sx={styles.requirement}>
                        <b>{t('GLOBAL_REQUIREMENT')}</b>
                    </Typography>
                    {
                        minTopup > 0 ? getRequirementRow({
                            title: `- ${t('GLOBAL_MIN_DEPOSIT')}: ${Utils.shared.getAmountString(minTopup, currency, companyInfo?.company)}`,
                            isFullfill: bonusRequirementMetChecklist?.minTopup ?? false,
                        }) : null
                    }
                    {
                        minTimesTopup > 0 ? getRequirementRow({
                            title: `- ${t('GLOBAL_MIN_TIMES_OF_TOPUP')}: ${minTimesTopup}`,
                            isFullfill: bonusRequirementMetChecklist?.minTimesTopup ?? false,
                        }) : null
                    }
                    {
                        (bonus?.claimWhenCreditLessThan) ? getRequirementRow({
                            title: `- Credit Less Than: ${Utils.shared.getAmountString(bonus?.claimWhenCreditLessThan, currency, companyInfo?.company)}`,
                            isFullfill: claimWhenCreditLessThan,
                        }) : null
                    }
                    {
                        (paymentMethod != null) ? getRequirementRow({
                            title: `- Payment Method`,
                            isFullfill: paymentMethod,
                        }) : null
                    }
                    {
                        (bonusPayoutWithdrawalType === 1 && bonusPayoutWithdrawalAmount > 0) ? getRequirementRow({
                            title: `- ${t('GLOBAL_MIN_WITHDRAWAL')}: ${Utils.shared.getAmountString(playerTurnoverRolloverAmountAfterClaimed, currency, companyInfo?.company)}`,
                            showFullfillment: false,
                        }) : null
                    }
                    {
                        (bonusPayoutWithdrawalType === 2 && bonusPayoutWithdrawalAmount > 0) ? getRequirementRow({
                            title: `- ${t('GLOBAL_ROLLLOVER')}: ${Utils.shared.getAmountString(playerTurnoverRolloverAmountAfterClaimed, currency, companyInfo?.company)}`,
                            showFullfillment: false,
                        }) : null
                    }
                    {
                        (bonus?.type === Constants.bonusTypeShare) ? getRequirementRow({
                            title: `- Registered Downline: ${registeredDownline}`,
                            showFullfillment: false,
                        }) : null
                    }
                </Stack>
            )
        } else {
            return null
        }
    }
    const getBannerDisplay = () => {
        let bannerDisplay = bonus?.bannerDisplay ?? ''

        if (bannerDisplay.length > 0) {
            //Have banner, display preview
            let imageLink = Utils.shared.getBonusImagesLink(companyInfo, bannerDisplay)

            return (
                <Box sx={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
                    <img src={imageLink} alt='bannerDisplay' width='100%' height='auto' />
                </Box>
            )
        } else {
            //No banner
            return null
        }
    }
    const getDefaultLanguage = () => {
        //Get initial default language
        const availableLanguages = companyInfo?.languages ?? []
        const defaultLanguage = availableLanguages?.find((e) => e.default ?? false)?.language

        let cachedLanguage = i18n.language
        if (cachedLanguage && cachedLanguage.length > 0) {
            //Has cached language, check if language still available
            let index = availableLanguages?.findIndex((e) => e.language === cachedLanguage) ?? -1
            if (index > -1) {
                return cachedLanguage
            }
        }

        //No cached language, select BO set default language
        return defaultLanguage ?? Constants.languageCodeEnglish
    }
    const getLocaleDescription = () => {
        let defaultLanguage = getDefaultLanguage()
        let desc = bonus?.descriptionLocales?.find((e) => e?.locale === defaultLanguage)?.translation ?? bonus?.description ?? ''
        return desc
    }

    //Api Methods
    const performClaimBonus = () => {
        //Prevent double calling
        if (bonusLastClaimed.current > Date.now() || isBonusClaiming.current) {
            return
        }

        //Set is bonus claiming
        isBonusClaiming.current = true

        //Begin claim bonus
        client.create('claim-player-bonus', {
            bonusPayout: bonus.bonusPayoutId,
            platform: Utils.shared.getCurrentPlatform() === 'app' ? Constants.bonusPlatformApp : Constants.bonusPlatformWeb
        }, null, (response) => {
            setSnackBar(snackBar => ({
                ...snackBar,
                title: 'Bonus claimed successfully',
                isOpen: true
            }))

            setBonusPopup(bonusPopup => ({
                ...bonusPopup,
                bonus: null,
                callingBonusRequirement: false,
                bonusResult: response
            }))
        }, null, false, () => {
            //Set is bonus claiming to false
            isBonusClaiming.current = false

            //Set the bonus claimed time with additional 1 second
            bonusLastClaimed.current = Date.now() + 1000
        })
    }
    const getBonusRequirements = () => {
        setBonusPopup(bonusPopup => ({
            ...bonusPopup,
            callingBonusRequirement: true,
        }))
        client.create('bonus-requirement-met-checklist', {
            'company': companyInfo?.company?._id,
            'user': userInfo?._id,
            'bonus': bonusPopup?.bonus?._id
        }, null, (response) => {
            setBonusPopup(bonusPopup => ({
                ...bonusPopup,
                requirement: response,
            }))
        }, (error) => {

        }, false, () => {
            setBonusPopup(bonusPopup => ({
                ...bonusPopup,
                callingBonusRequirement: false,
            }))
        })
    }

    useEffect(() => {
        if (userInfo && bonusPopup?.bonus) {
            if (bonusPopup?.bonus?._id != bonusPopup?.requirement?.bonus?._id) {
                getBonusRequirements()
            }
        }
    }, [])

    return (
        <div>
            <div style={{ color: Constants.defaultPopupBorderColor, fontSize: '14px', margin: '10px' }}>
                <b>
                    {name}
                </b>
            </div>
            <div style={{ border: `1px solid ${Constants.defaultPopupBorderColor}`, marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} />
            {getBannerDisplay()}
            <div style={{ marginLeft: '10px', marginRight: '10px', overflowWrap: 'break-word', overflow: 'hidden' }}>
                {parse(getLocaleDescription())}
            </div>
            {getRequirementView()}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 10 }}>
                <Stack direction={'row'} spacing={'10px'} alignItems={'center'}>
                    {isEligible ? (
                        <BaseButton title={'CLAIM'} color={Constants.buttonGreenColor} textColor={'black'} fullWidth={false} customCSS={bonusClaimButtonCSS} className="angpaoClaimButton" onTap={() => {
                            onClosed()

                            //Check if is logged in
                            if (userInfo) {
                                //Check if bank account is required
                                let memberBankAccountRequired = companyInfo?.company?.memberBankAccountRequired ?? true
                                if (memberBankAccountRequired) {
                                    //Check if you have setup bank account
                                    Utils.shared.getPlayerWithdrawalAccounts(companyInfo, userInfo, setAlertDialog, (banks, accounts) => {
                                        if (accounts.length > 0) {
                                            performClaimBonus()
                                        } else {
                                            //No account setup yet, navigate to account setup page
                                            if (template.design === Constants.templateDesignModern) {
                                                navigate('/profile#details')
                                            } else {
                                                navigate('/profile/bankAccount')
                                            }
                                        }
                                    })
                                } else {
                                    //No bank account required, proceed claim bonus
                                    performClaimBonus()
                                }
                            } else {
                                //Not logged in, naviagte to login screen
                                navigate('/login')
                            }
                        }} />
                    ) : null}
                    <BaseButton title={'CANCEL'} color={Constants.buttonRedColor} textColor={'black'} fullWidth={false} customCSS={bonusCancelButtonCSS} className="angpaoCancelButton" onTap={() => {
                        onClosed()
                    }} />
                </Stack>
            </div>
        </div>
    )
}