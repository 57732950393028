import React, { useContext } from "react"
import { CompanyInfoContext } from '../../App'
import Constants from '../../constants'
import Utils from "../../utils/utils"

function BannerStaticView() {
    //Contexts
    const { companyInfo } = useContext(CompanyInfoContext)

    let bannerStatic = companyInfo?.company?.bannerStatic ?? ''

    if (bannerStatic.length > 0) {
        let bannerLink = Utils.shared.getCompanyImagesLink(companyInfo, bannerStatic, companyInfo?.company?._id)
        let bannerStaticAction = companyInfo?.company?.bannerStaticAction ?? ''

        return (
            <div style={{ width: '100%' }}>
                <img src={bannerLink} alt="bannerStatic" width='100%' height='calc((3/20) * 100%)' loading="lazy" draggable={false} style={{
                    cursor: bannerStaticAction.length > 0 ? 'pointer' : 'auto'
                }} onClick={() => {
                    if (bannerStaticAction.length > 0) {
                        window.open(bannerStaticAction, '_blank', 'noopener,noreferrer')
                    }
                }} />
            </div>
        )
    } else {
        return null
    }
}

export default BannerStaticView;