import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertDialogContext, CompanyInfoContext, PopupContext, RegisterPopupContext } from "../../App"
import Utils from "../../utils/utils";
import Constants from '../../constants'

function RegisterWithAffiliate() {
    let navigate = useNavigate()
    const search = useLocation().search;
    const referralCodeFromQueryString = new URLSearchParams(search).get('referralCode') ?? '';

    //Context
    const { companyInfo } = useContext(CompanyInfoContext)
    const { setPopup } = useContext(PopupContext)
    const { setOpenRegister } = useContext(RegisterPopupContext)
    const { setAlertDialog } = useContext(AlertDialogContext)

    useEffect(() => {
        if (referralCodeFromQueryString.length > 0) {
            //Store the referral code into local storage for later use
            if (referralCodeFromQueryString) {
                Utils.shared.storeLocalStorage(Constants.keys_referral_code, referralCodeFromQueryString)
            }

            Utils.shared.showRegisterPopup({
                companyInfo: companyInfo, 
                setAlertDialog: setAlertDialog, 
                setPopup: setPopup, 
                navigate: navigate, 
                referralCode: referralCodeFromQueryString,
                setOpenRegister: setOpenRegister
            })
        } else {
            //No referral code found, redirect to home
            navigate('/', { replace: true })
        }
    }, [])

    return (
        <div>

        </div>
    )
}

export default RegisterWithAffiliate