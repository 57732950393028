import { useCompanyInfo } from "../../hooks/useCompanyInfo"
import { useUserInfo } from "../../hooks/useUserInfo"
import { useTemplateVariables } from "../../utils/templateUtils"
import Constants from '../../constants'
import Utils from "../../utils/utils"
import { Box, CircularProgress, LinearProgress, Stack, linearProgressClasses, styled } from "@mui/material"
import AmountView from "./amountView"

export default function RolloverDetailsView() {
    const user = useUserInfo()
    const company = useCompanyInfo()
    const template = useTemplateVariables()

    const playerRollover = user?.playerRollover ?? 0
    const playerDepositRollover = user?.playerDepositRollover ?? 0
    const playerTotalRollover = playerRollover + playerDepositRollover
    const playerBetsRollover = user?.playerBetsRollover ?? 0

    const currencyCode = company?.currencyCode ?? ''

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        width: '100%',
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: 'white',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: '#eaa844',
        },
    }));

    if (playerTotalRollover > 0) {
        var percentage = (template.design === Constants.templateDesignModern ? 0 : 100)
        if (playerTotalRollover > playerBetsRollover) {
            percentage = Math.max(0, playerBetsRollover / playerTotalRollover) * (template.design === Constants.templateDesignModern ? 50 : 100)
        }

        const title = 'Rollover'

        if (template.design === Constants.templateDesignModern) {
            return (
                <Stack sx={{
                    boxShadow: '0px 4px 4px 0px rgba(23, 53, 89, 0.16)',
                    padding: '8px',
                    width: '100%',
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    position: 'relative',
                    height: '98px',
                    minWidth: '140px'
                }} alignItems={'center'} justifyContent={'center'}>
                    <CircularProgress variant='determinate' value={50} thickness={8} size={130} sx={{ rotate: '-90deg', position: 'absolute', bottom: -36, color: Utils.shared.shadeColor(template.navigationFontColor, 150) }} />
                    <CircularProgress variant='determinate' value={percentage} thickness={8} size={130} sx={{ rotate: '-90deg', position: 'absolute', bottom: -36, color: template.navigationFontColor }} />
                    <Box sx={{ position: 'absolute', bottom: 12 }}>
                        <AmountView title={title} value={Utils.shared.getAmountString(playerTotalRollover, '', company)} alignItems="center" />
                    </Box>
                </Stack>
            )
        } else {
            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '12px' }}>
                        {`Balance Rollover Requirement : ${Utils.shared.getAmountString(playerTotalRollover, currencyCode, company)}`}
                    </div>
                    <div style={{ height: '5px' }} />
                    <div style={{ position: 'relative', height: '10px', minWidth: '250px', maxWidth: '40%', }}>
                        <BorderLinearProgress sx={{ position: 'absolute' }} variant="determinate" value={percentage} />
                        <Stack sx={{ alignItems: 'center', justifyContent: 'center', color: 'black', fontSize: '10px', position: 'absolute', width: '100%', height: '100%' }}>
                            <b>{Utils.shared.getAmountString(playerBetsRollover, currencyCode, company)}</b>
                        </Stack>
                    </div>
                </div>
            )
        }
    }

    return null
}