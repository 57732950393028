import { useState, useEffect } from 'react';

const DEFAULT_BREAKPOINTS = {
    largeDesktop: 1440,
    desktop: 992,
    tablet: 768,
    mobile: 320,
};

const useScreenType = () => {
    const [screenSize, setScreenSize] = useState(
        {
            isMobile: window.innerWidth < DEFAULT_BREAKPOINTS.tablet,
            isTablet:
                window.innerWidth >= DEFAULT_BREAKPOINTS.tablet &&
                window.innerWidth < DEFAULT_BREAKPOINTS.desktop,
            isDesktop:
                window.innerWidth >= DEFAULT_BREAKPOINTS.desktop &&
                window.innerWidth < DEFAULT_BREAKPOINTS.largeDesktop,
            isLargeDesktop: window.innerWidth >= DEFAULT_BREAKPOINTS.largeDesktop,
        }
    );

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(
                {
                    isMobile: window.innerWidth < DEFAULT_BREAKPOINTS.tablet,
                    isTablet:
                        window.innerWidth >= DEFAULT_BREAKPOINTS.tablet &&
                        window.innerWidth < DEFAULT_BREAKPOINTS.desktop,
                    isDesktop:
                        window.innerWidth >= DEFAULT_BREAKPOINTS.desktop &&
                        window.innerWidth < DEFAULT_BREAKPOINTS.largeDesktop,
                    isLargeDesktop: window.innerWidth >= DEFAULT_BREAKPOINTS.largeDesktop,
                }
            );
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenSize;
};

export default useScreenType;