import { Stack } from "@mui/material"
import { useCompanyInfo } from "../../hooks/useCompanyInfo"
import { useTemplateVariables } from "../../utils/templateUtils"

export default function AmountView({
    title,
    value,
    alignItems = 'start',
    titleFontColor = '#828282',
    titleFontSize = '10px',
    valueFontColor = null,
    valueFontSize = '16px',
    currencyFontSize = '10px',
    showFLoatingCurrency = true,
    swapped = false,
}) {
    const company = useCompanyInfo()
    const template = useTemplateVariables()
    const currencyCode = company?.currencyCode ?? ''
    const isPlayerSiteDisplayCurrency = company?.isPlayerSiteDisplayCurrency ?? true
    const tempValueFontColor = valueFontColor ?? template.mainFontColor

    const getTitle = () => {
        return (
            <div style={{
                fontSize: titleFontSize,
                fontWeight: '400',
                color: titleFontColor,
                textTransform: 'uppercase'
            }}>{title}</div>
        )
    }

    return (
        <Stack sx={{ flexGrow: 1 }} alignItems={alignItems}>
            {
                swapped ? null : (
                    getTitle()
                )
            }
            <Stack direction={'row'} alignItems={'start'} spacing={'2px'} sx={{
                fontSize: valueFontSize,
                fontWeight: '600',
                letterSpacing: '1.28px',
                color: tempValueFontColor,
                textTransform: 'uppercase'
            }}>
                {
                    (isPlayerSiteDisplayCurrency && showFLoatingCurrency) ? (
                        <div style={{
                            fontSize: currencyFontSize,
                            fontWeight: '600',
                            lineHeight: valueFontSize,
                            color: tempValueFontColor,
                            textTransform: 'uppercase'
                        }}>{currencyCode}</div>
                    ) : null
                }
                <div>{value}</div>
            </Stack>
            {
                swapped ? getTitle() : null
            }
        </Stack>
    )
}