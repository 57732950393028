import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BottomNavigation, Badge, Box, Button, Stack, Typography } from '@mui/material';
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction';
import { History, Campaign, ShareOutlined, Person } from '@mui/icons-material';
import { styled } from "@mui/material/styles";
import { AlertDialogContext, CompanyInfoContext, LoginPopupContext, UserInfoContext } from '../../App';
import Constants from '../../constants'
import FeathersClient from '../../feathers/feathersClient'
import { useTranslation } from 'react-i18next'
import Utils from "../../utils/utils";
import { ChatIcon, HomeIcon } from "../customSVG";
import { useTemplateVariables } from "../../utils/templateUtils";
import useScreenType from "../../hooks/useScreenType";

function BottomNavigationBar() {
  const navigate = useNavigate()
  const location = useLocation()
  const screenType = useScreenType()

  //CONTEXT
  const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
  const { userInfo, setUserInfo } = useContext(UserInfoContext)
  const { setAlertDialog } = useContext(AlertDialogContext)
  const { setOpenLogin } = useContext(LoginPopupContext)
  const { t } = useTranslation()
  const template = useTemplateVariables()

  //Template design
  const navigationBackgroundType = companyInfo?.company?.navigationBackgroundType ?? Constants.backgroundTypeImage

  //REF
  const userInfoRef = useRef(userInfo)

  //STATE
  const [unreadMessages, setUnreadMessages] = useState(0)

  const getUnselectedColor = () => {
    if (template.design === Constants.templateDesignModern) {
      return '#828282'
    } else {
      return template.navigationFontColor
    }
  }

  const BottomNavigationAction = styled(MuiBottomNavigationAction)({
    color: getUnselectedColor(),
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    letterSpacing: '0.8px',
    '&.Mui-selected': {
      color: template.navigationFontColor,
      svg: {
        color: template.navigationFontColor
      }
    },
    "@media (max-width: 768px)": {
      minWidth: "auto",
      padding: "6px 0"
    }
  });

  //Template Methods
  const getBorderRadius = () => {
    if (template.design === Constants.templateDesignModern) {
      return '24px 24px 0px 0px'
    }

    return '0px'
  }
  const getHeight = () => {
    if (template.design === Constants.templateDesignModern) {
      return '68px'
    }

    return '56px'
  }
  const getMarginTop = () => {
    if (template.design === Constants.templateDesignModern) {
      return '16px'
    }

    return '0px'
  }
  const getMiddleNavigationButton = () => {
    if (template.design === Constants.templateDesignModern) {
      return (
        <BottomNavigationAction showLabel={false} component={Link} to="/promotions" value="promotions" />
      )
    }

    return (
      <BottomNavigationAction label={t('BOTTOM_BAR_HISTORY')} component={Link} to="/history" value="history" icon={<History />} />
    )
  }

  const getBackgroundColor = () => {
    if (navigationBackgroundType === Constants.backgroundTypeColor) {
      let navigationBackgroundColor = companyInfo?.company?.navigationBackgroundColor
      if (navigationBackgroundColor) {
        return navigationBackgroundColor
      }
    }

    return 'black'
  }
  const getBackgroundImage = () => {
    //Background image
    var imageUrl = `${process.env.PUBLIC_URL}/images/navigation.png`

    let navigationBackgroundImage = companyInfo?.company?.navigationBackgroundImage
    if (navigationBackgroundImage && navigationBackgroundImage.length > 0) {
      imageUrl = Utils.shared.getCompanyImagesLink(companyInfo, navigationBackgroundImage, companyInfo?.company?._id)
    }

    if (navigationBackgroundType === Constants.backgroundTypeImage) {
      return `url('${imageUrl}')`
    }

    return null
  }
  const getBottomNavigation = () => {
    let homeSelected = window.location.pathname === '/'
    let shareSelected = template.design === Constants.templateDesignModern ? window.location.hash.startsWith('#referral') : window.location.pathname.startsWith('/share')
    let chatSelected = window.location.pathname.startsWith('/chat')
    let profileSelected = template.design === Constants.templateDesignModern ? window.location.hash.startsWith('#details') : window.location.pathname.startsWith('/profile')

    const getNavLabel = ({ title, isSelected }) => {
      return (
        <Typography sx={{
          fontSize: isSelected ? '0.875rem' : '0.75rem',
          fontWeight: '600',
          lineHeight: '12px',
          letterSpacing: '0.8px',
          color: isSelected ? template.navigationFontColor : getUnselectedColor()
        }}>
          {title}
        </Typography>
      )
    }

    return (
      <BottomNavigation showLabels value={location.pathname.split('/')[1]} sx={{
        backgroundColor: 'transparent'
      }}>
        <BottomNavigationAction label={getNavLabel({ title: t('BOTTOM_BAR_HOME'), isSelected: homeSelected })} onClick={() => {
          Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
            navigate('/', { replace: true })
          }, true)
        }} value="" icon={<HomeIcon fill={homeSelected ? template.navigationFontColor : getUnselectedColor()} />} />
        <BottomNavigationAction label={getNavLabel({ title: t('BOTTOM_BAR_SHARE'), isSelected: shareSelected })} icon={<ShareOutlined htmlColor={shareSelected ? template.navigationFontColor : getUnselectedColor()} />} onClick={() => {
          //Check if user is logged in
          if (userInfo) {
            if (template.design === Constants.templateDesignModern) {
              navigate('/profile#referral')
            } else {
              navigate('/share')
            }
          } else {
            Utils.shared.navigateLogin({
              companyInfo: companyInfo,
              navigate: navigate,
              setOpenLogin: setOpenLogin
            })
          }
        }} />
        {getMiddleNavigationButton()}
        <BottomNavigationAction label={t('BOTTOM_BAR_LIVE_CHAT')} component={Link} to="/chat" value="chat" icon={
          <Badge color='error' variant="dot" badgeContent={unreadMessages} invisible={unreadMessages <= 0}>
            <ChatIcon fill={chatSelected ? template.navigationFontColor : getUnselectedColor()} />
          </Badge>
        } onClick={() => setUnreadMessages(0)} />
        <BottomNavigationAction label={ getNavLabel({ title: t('BOTTOM_BAR_PROFILE'), isSelected: profileSelected })} icon={<Person htmlColor={profileSelected ? template.navigationFontColor : getUnselectedColor()} />} onClick={() => {
          //Check if user is logged in
          if (userInfo) {
            if (template.design === Constants.templateDesignModern && !screenType.isMobile) {
              navigate('/profile#details')
            } else {
              navigate('/profile')
            }
          } else {
            Utils.shared.navigateLogin({
              companyInfo: companyInfo,
              navigate: navigate,
              setOpenLogin: setOpenLogin
            })
          }
        }} />
      </BottomNavigation>
    )
  }
  const getMainView = ({ child }) => {
    if (template.design === Constants.templateDesignModern) {
      return (
        <div style={{
          position: 'sticky',
          left: 0,
          right: 0,
          bottom: 0,
          width: '100vw',
          overflow: 'hidden',
          zIndex: 100,
          paddingTop: getHeight(),
          marginBottom: 'calc(0px + env(safe-area-inset-bottom))',
        }}>
          <div style={{
            backgroundImage: getBackgroundImage(),
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundColor: getBackgroundColor(),
            boxShadow: '0px -0.5px 0px 0px rgba(23, 53, 89, 0.50)',
            backdropFilter: 'blur(25px)',
            borderRadius: getBorderRadius(),
            position: 'fixed',
            bottom: 0,
            width: '100%',
            height: getHeight(),
          }}>
            {child}
          </div>
          <Box sx={{
            width: '72px',
            height: '72px',
            border: `4px solid var(--n-colour-n-0, ${getBackgroundColor()})`,
            backgroundColor: template.navigationFontColor,
            position: 'fixed',
            left: 'calc(50% - 36px)',
            bottom: '12px',
            borderRadius: '40px',
            boxShadow: '0px -0.5px 0px 0px rgba(23, 53, 89, 0.50)',
            zIndex: 102
          }}>
            <Button sx={{ width: '100%', height: '100%' }} onClick={() => {
              navigate('/promotions')
            }}>
              <Stack sx={{ height: '100%' }} alignItems={'center'} spacing='0px'>
                <Campaign htmlColor={getBackgroundColor()} fontSize='large' viewBox="0 0 24 24" sx={{
                  rotate: '-20deg'
                }} />
                <div style={{ color: getBackgroundColor(), fontSize: '10px', lineHeight: '12px', fontWeight: '600', letterSpacing: '0.8px' }}>{'PROMO'}</div>
              </Stack>
            </Button>
          </Box>
        </div>
      )
    } else {
      return (
        <div style={{
          position: 'sticky',
          left: 0,
          right: 0,
          bottom: 0,
          width: '100vw',
          overflow: 'hidden',
          zIndex: 100,
          marginBottom: 'calc(0px + env(safe-area-inset-bottom))',
          backgroundImage: getBackgroundImage(),
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundColor: getBackgroundColor(),
          boxShadow: '0px -0.5px 0px 0px rgba(23, 53, 89, 0.50)',
          bottom: 0,
          width: '100%',
          height: getHeight(),
        }}>
          {child}
        </div>
      )
    }
  }

  //SOCKET METHOD
  const newMessageListener = () => {
    FeathersClient.listen('messages', 'created', 'bottomBar', (data) => {
      var roomId = window.localStorage.getItem(`${Constants.keys_room_id}${window.location.host}`) ?? ''
      if (userInfoRef.current) {
        roomId = userInfoRef.current.rooms[0]?._id ?? ''
      }

      //Check if user is logged in or public user      
      if (data.room._id === roomId) {
        //Check if message is send by myself
        if (data.user?._id === userInfoRef.current?._id ||
          data.deviceId === Utils.shared.getDeviceID()) {
          return
        }

        new Audio('../../resources/sound/newMessageTone.mp3').play()

        //Only add red dot if user is not in live chat page
        if (window.location.pathname.indexOf('/chat') < 0) {
          setUnreadMessages(unreadMessages + 1)

          //Show notification
          let message = data?.text ?? ''
          let image = data?.image ?? ''
          let hasAttachment = image.length > 0

          Utils.shared.showNotification('New Message', hasAttachment ? 'Attachment' : message, companyInfo, () => {
            navigate('/chat')
          })
        }
      }
    })

    FeathersClient.listen('messages', 'patched', 'bottomBar', (data) => {
      var roomId = window.localStorage.getItem(`${Constants.keys_room_id}${window.location.host}`) ?? ''
      if (userInfoRef.current) {
        roomId = userInfoRef.current.rooms[0]?._id ?? ''
      }

      //Check if user is logged in or public user
      if (data.room._id === roomId && data.isRead) {
        setUnreadMessages(unreadMessages - 1)
      }
    })
  }

  useEffect(() => {
    newMessageListener()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    userInfoRef.current = userInfo
  }, [userInfo])

  return (
    getMainView({ child: getBottomNavigation() })
  )
}

export default BottomNavigationBar;
