import { Box, Grid, Stack } from "@mui/material";
import { useUserInfo } from "../../hooks/useUserInfo";
import RolloverDetailsView from "./rollOverDetailsView";
import TurnoverDetailsView from "./turnoverDetailsView";
import MinWithdrawalDetailsView from "./minWithdrawalDetailsView";
import WithdrawalFeeDetailsView from "./withdrawalFeeDetailsView";
import MaxWithdrawalDetailsView from "./maxWithdrawalDetailsView";

export default function UserSummaryDetailsView() {
    const user = useUserInfo()
    const playerTurnover = user?.playerTurnover ?? 0
    const playerRollover = user?.playerRollover ?? 0
    const playerDepositRollover = user?.playerDepositRollover ?? 0
    const playerTotalRollover = playerRollover + playerDepositRollover

    return (
        <Stack sx={{
            backgroundColor: '#F4F6F8',
            padding: '16px',
            overflow: 'hidden'
        }} spacing={'16px'}>
            {
                (playerTurnover > 0 || playerTotalRollover > 0) ? (
                    <Stack direction={'row'} spacing={'16px'} sx={{
                        width: '100%'
                    }}>
                        <TurnoverDetailsView />
                        <RolloverDetailsView />
                    </Stack>
                ) : null
            }
            <Grid container columnGap={'16px'} rowGap={'16px'} columns={{ xs: 2, sm: 3, md: 4 }}>
                <MinWithdrawalDetailsView />
                <MaxWithdrawalDetailsView />
                <WithdrawalFeeDetailsView />
            </Grid>
        </Stack>
    )
}