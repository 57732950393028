import { Box, Stack, Tab, Tabs, Tooltip, Typography } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { CompanyInfoContext, LoginPopupContext, UserInfoContext } from "../../App"
import BaseButton from "../../components/button/button"
import Constants from '../../constants'
import Utils from "../../utils/utils"
import { useTemplateVariables } from "../../utils/templateUtils"
import ButtonSelectable from "../../components/button/buttonSelectable"
import '../../styles/hideScrollbarStyle.css'
import useScreenType from "../../hooks/useScreenType"

export default function Games({ isSingleGameLink = false, gameList = [], category = null, gamesPlayerObject = null }) {
    //Contexts
    const navigate = useNavigate()
    const screenType = useScreenType()
    const template = useTemplateVariables()
    const { companyInfo } = useContext(CompanyInfoContext)
    const { userInfo } = useContext(UserInfoContext)
    const { setOpenLogin } = useContext(LoginPopupContext)

    //States
    const [selectedGameType, setSelectedGameType] = useState('All')

    //Ref
    const gameLastLaunched = useRef(Date.now())
    const isGameLaunching = useRef(false)

    //Styles
    const itemCount = screenType.isMobile ? '3' : screenType.isTablet ? '4' : '6'
    const styles = {
        gridLayout: {
            padding: (template.design === Constants.templateDesignModern && !screenType.isMobile) ? '0px 160px' : '10px',
            display: 'grid',
            gridTemplateColumns: `repeat(${itemCount}, 1fr)`,
            gridAutoRows: 'auto',
            gridColumnGap: '10px',
            gridRowGap: '10px',
            width: (template.design === Constants.templateDesignModern && !screenType.isMobile) ? 'calc(100% - 320px)' : 'calc(100% - 20px)',
        }
    }
    const getTitleFontSize = () => {
        if (screenType.isMobile) {
            return '18px'
        }

        return '28px'
    }
    const getTitleLineHeight = () => {
        if (screenType.isMobile) {
            return '26px'
        }

        return '36px'
    }
    const getTitleStackAlign = () => {
        if (screenType.isMobile) {
            return 'left'
        }

        return 'center'
    }
    const getTitleBorderBottomLineWidth = () => {
        if (screenType.isMobile) {
            return '25%'
        }

        return '25%'
    }
    const getCategoryListJusticyContent = () => {
        if (template.design === Constants.templateDesignModern) {
            if (screenType.isMobile || screenType.isTablet) {
                return 'left'
            } else {
                return 'center'
            }
        }

        return null
    }
    const getPadding = () => {
        if (template.design === Constants.templateDesignModern) {
            if (screenType.isMobile) {
                return '0px 0px'
            } else {
                return '0px 160px'
            }
        }

        return null
    }

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    var buttonColor = Constants.defaultButtonColor
    let otherButtonColor = companyInfo.company.otherButtonColor
    if (otherButtonColor && otherButtonColor.length > 0) {
        buttonColor = otherButtonColor
    }

    const location = useLocation()
    const gamesPlayer = gamesPlayerObject ?? location.state?.gamesPlayer
    const game = gamesPlayer?.game
    const gameListing = location.state?.gameListing ?? gameList ?? []
    const gameCategory = category ?? location.state?.gameCategory
    const companyGameId = gamesPlayer?.companyGame ?? location.state?.companyGameId
    const gameTypes = () => {
        var uniqueGameTypes = []
        var hasHotGames = false
        gameListing.forEach((e) => {
            if (uniqueGameTypes.indexOf(e.gameType) === -1) {
                uniqueGameTypes.push(e.gameType)
            }

            //Check if have hot games
            if (e?.isHot ?? false) {
                hasHotGames = true
            }
        })

        //Add hot game type if any
        if (hasHotGames) {
            uniqueGameTypes = ['Hot Games', ...uniqueGameTypes]
        }

        //Lastly, add a ALL type to as first
        uniqueGameTypes = ['All', ...uniqueGameTypes]

        return uniqueGameTypes
    }

    //Put as gameBehavior 7 if is single game link
    const gameBehaviour = isSingleGameLink ? Constants.gameBehaviourCustomLayoutSingleCall : location.state?.gameBehaviour

    //USE EFFECT
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //COMPONENT METHODS
    const getGameTypes = () => {
        if (template.design === Constants.templateDesignModern) {
            return (
                <>
                    {
                        gameTypes().map((e, index) => {
                            const isSelected = selectedGameType === e

                            return (
                                <ButtonSelectable title={e} value={e} isSelected={isSelected} onSelected={() => {
                                    setSelectedGameType(e)
                                }} />
                            )
                        })
                    }
                </>
            )
        } else {
            return gameTypes().map((e, index) => {
                const isSelected = selectedGameType === e
                const selectedColor = Utils.shared.brighten(buttonColor, 0.8)

                return (
                    <div style={{ marginLeft: index === 0 ? '10px' : '0px', marginTop: '10px', marginBottom: '10px', marginRight: '10px' }} >
                        <BaseButton title={e} fullWidth={false} color={isSelected ? selectedColor : buttonColor} onTap={() => {
                            setSelectedGameType(e)
                        }} />
                    </div>
                )
            })
        }
    }
    const getGridItem = () => {
        //Filter the game listing by selected game type
        const filteredGameListing = gameListing.filter((e) => {
            if (selectedGameType === 'Hot Games') {
                return (e?.isHot ?? false)
            } else if (selectedGameType === 'All') {
                return true
            } else {
                return e.gameType === selectedGameType && ((e?.isHot ?? false) === false)
            }
        })

        return filteredGameListing.map((e, index) => {
            const gameName = e?.gameName ?? ''
            const gameImage = e?.gameImage ?? ''
            const isHot = e?.isHot ?? false
            const gameLink = e?.gameLink ?? ''
            const gamesPlayerId = gamesPlayer?._id ?? ''
            const companyGame = gamesPlayer?.companyGame
            const gameId = e?.gameId ?? ''
            const userId = userInfo?._id
            const companyId = companyInfo?.company?._id

            return (
                <div key={`${gameId}${index}`} style={{
                    position: 'relative',
                    cursor: 'pointer',
                }}>
                    <Tooltip title={gameName} placement='top' >
                        <Stack sx={{ height: '100%' }}>
                            <Box sx={{ flexGrow: 1, position: 'relative' }}>
                                <img src={gameImage} alt={gameName} width='100%' height='100%' loading="lazy" style={{ color: 'white' }} />
                                <img src={`${process.env.PUBLIC_URL}/images/game/hotGamebadge.png`} alt='hotGameBadge' loading="lazy" height='40px' style={{ position: 'absolute', top: 0, right: 0, display: (isHot ? 'inline' : 'none') }} />
                            </Box>
                            <Box style={{ height: '10px' }} />
                            <Stack direction='column' sx={{ alignItems: 'end', justifyContent: 'end' }}>
                                <BaseButton title='PLAY' onTap={(event) => {
                                    if (isSingleGameLink) {
                                        if (!userInfo) {
                                            //Not logged in, redirect to login page
                                            Utils.shared.navigateLogin({
                                                companyInfo: companyInfo,
                                                navigate: navigate,
                                                setOpenLogin: setOpenLogin
                                            })

                                            return
                                        }
                                    }

                                    //Prevent from control/shift click to open multiple tab
                                    if (event.ctrlKey || event.shiftKey || event.metaKey) {
                                        event.preventDefault()
                                    } else {
                                        //Prevent double calling
                                        if (gameLastLaunched.current > Date.now() || isGameLaunching.current) {
                                            return
                                        }

                                        //Set is game launching
                                        isGameLaunching.current = true

                                        var webPageName = '_blank'

                                        //Check if game link is provided
                                        if (gameLink.length === 0) {
                                            //Check game behavior
                                            let token = Utils.shared.retrieveLocalStorage(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`)
                                            switch (gameBehaviour) {
                                                case Constants.gameBehaviourCustomLayoutCallGameLink:
                                                    //Call api to get game link
                                                    window.open(`/gameLauncher?gi=${gameId}&gb=${gameBehaviour}&gc=${gameCategory}&gp=${gamesPlayerId}&u=${userId}&c=${companyId}&t=${token}`, webPageName)?.focus()
                                                    break
                                                case Constants.gameBehaviourCustomLayoutSingleCall:
                                                    //Call api to get game link
                                                    window.open(`/gameLauncher?gi=${gameId}&gb=${gameBehaviour}&gc=${gameCategory}&gp=${gamesPlayerId}&u=${userId}&c=${companyId}&cg=${companyGameId}&t=${token}`, webPageName)?.focus()
                                                    break
                                                default:
                                                    break

                                            }
                                        } else {
                                            //Check game behavior
                                            switch (gameBehaviour) {
                                                case Constants.gameBehaviourCustomLayoutFEHost:
                                                    const playTechLink = `/playTech/index.html${gameLink}`
                                                    window.open(playTechLink, webPageName)?.focus()
                                                    break
                                                default:
                                                    window.open(gameLink, webPageName)?.focus()
                                                    break
                                            }
                                        }

                                        //Set is game launching to false
                                        isGameLaunching.current = false

                                        //Set the game luanched time with additional 1 second
                                        gameLastLaunched.current = Date.now() + 1000
                                    }
                                }} />
                            </Stack>
                        </Stack>
                    </Tooltip>
                </div>
            )
        })
    }

    //Check if is single game link
    if (isSingleGameLink) {
        return (
            <div style={styles.gridLayout}>
                {getGridItem()}
            </div>
        )
    }

    //Check template
    if (template.design === Constants.templateDesignModern) {
        return (
            <Stack spacing={'32px'} sx={{
                paddingTop: '16px'
            }}>
                <Stack sx={{
                    padding: '0px 16px'
                }} alignItems={getTitleStackAlign()}>
                    <Typography sx={{
                        fontSize: getTitleFontSize(),
                        fontWeight: '700',
                        lineHeight: getTitleLineHeight(),
                        color: color,
                        borderBottom: `4px solid ${template.navigationFontColor}`
                    }}>
                        {game?.label ?? ''}
                    </Typography>
                </Stack>
                <Tabs variant='scrollable' scrollButtons={'auto'} allowScrollButtonsMobile={true} value={false} onChange={(e, value) => {
                    setSelectedGameType(value)
                }} sx={{
                    '.MuiTabs-scrollButtons': {
                        color: 'white',
                        backgroundColor: 'black'
                    },
                    '.MuiTabs-scrollButtons.Mui-disabled': {
                        opacity: 0.3
                    },
                    padding: getPadding()
                }}>
                    {
                        gameTypes().map((e, index) => {
                            const isSelected = selectedGameType === e

                            return (
                                <Tab sx={{
                                    padding: 0,
                                    minWidth: 0,
                                    paddingLeft: index === 0 ? '0px' : '10px'
                                }} label={
                                    <ButtonSelectable title={e} value={e} isSelected={isSelected} />
                                } value={e} />
                            )
                        })
                    }
                </Tabs>
                <div style={styles.gridLayout}>
                    {getGridItem()}
                </div>
            </Stack>
        )
    } else {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className="hideScrollBar" style={{
                    width: '100vw',
                    overflowX: 'scroll',
                    overflowY: 'clip',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    {getGameTypes()}
                </div>
                <div style={{ width: '100vw', borderBottom: '2px solid white' }} />
                <div style={styles.gridLayout}>
                    {getGridItem()}
                </div>
            </div>
        )
    }
}