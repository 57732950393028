import React, { useState, useEffect } from 'react'
import './styles/App.css'
import './styles/loader.css'
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import AppTopBar from './components/appBar'
import BottomNavigationBar from './components/navigationView/bottomBar'
import AlertDialog from "./components/dialogs/alertDialog"
import SnackBar from './components/snackBar/snackBar'
import FeathersClient, { request } from './feathers/feathersClient'
import { Dialog, Stack } from '@mui/material'
import Utils from './utils/utils'
import BasePopup from './components/popup/basePopup'
import { Provider } from 'figbird'
import client from './feathers/feathers'
import './styles/main.scss'
import Constants from './constants'
import { Helmet } from "react-helmet";
import { renderMetaPixel } from './components/analytics/metaPixel'
import { useTemplateVariables } from './utils/templateUtils'
import FloatingNavigationView from './components/navigationView/floatingNavigationView'
import Login from './pages/auth/login'
import Register from './pages/auth/register'
import ForgotPassword from './pages/auth/forgotPassword'
import DownloadAppPopup from './components/game/downloadAppPopup'
import ChangePassword from './pages/profile/changePassword'
import BonusPopup from './components/bonusListView/bonusPopup'
import ClearPlayerCreditBalancePopup from './components/popup/clearPlayerCreditBalancePopup'
import useScreenType from './hooks/useScreenType'

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const search = location.search
  const isFromApp = new URLSearchParams(search).get('ifa') ?? ''

  //States
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [userInfo, setUserInfo] = useState(null)

  //Contexts
  const template = useTemplateVariables()
  const screenType = useScreenType()
  const clientApi = request({
    setUserInfo: setUserInfo
  })

  ///Auth Popup States
  const [openLogin, setOpenLogin] = useState(false)
  const [openRegister, setOpenRegister] = useState(false)
  const [openForgotPwd, setOpenForgotPwd] = useState(false)
  const [openChangePwd, setOpenChangePwd] = useState(false)
  const [gamePopup, setGamePopup] = useState(null) //Accepts object only
  const [bonusPopup, setBonusPopup] = useState({
    bonus: null,
    requirement: null,
    callingBonusRequirement: false,
    bonusResult: null,
    onClaimed: () => { },
    onClosed: () => { }
  }) //Accepts object only
  const [openClearBalance, setOpenClearBalance] = useState(false)

  const [helmetInfo, setHelmetInfo] = useState({
    title: '',
    description: '',
    metaKeywords: '',
    metaThemeColor: '#2a2a2b',
    icon: null,
    facebookPixelID: null
  })

  //Alert Dialog
  const [alertDialog, setAlertDialog] = useState({
    title: 'Error',
    description: '',
    translationData: null,
    closeButtonTitle: 'OK',
    isOpen: false,
    isConfirmation: false,
    onConfirmed: () => { },
    onClosed: () => { }
  })

  //Snackbar
  const [snackBar, setSnackBar] = useState({
    title: '',
    severity: 'success',
    isOpen: false
  })

  //Popup
  const [popup, setPopup] = useState({
    children: null,
    showCloseButton: false,
    dynamicWidth: false,
    isOpen: false,
    onClosed: () => { }
  })

  //API Methods
  const getPlayerSiteCompanyInfo = (isAuthenticated = false) => {
    //If not authenticated, clear user info
    if (!isAuthenticated) {
      setUserInfo(null)
    }

    //Begin get company info
    Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
      setIsInitialLoading(false)
    })
  }

  //Use Effects
  useEffect(() => {
    //Initial calling
    //To prevent multiple call
    if (companyInfo) {
      return
    }

    //Check if is launching game
    let path = window.location.pathname
    if (path.indexOf('gameLauncher') > -1) {
      setIsInitialLoading(false)
    } else {
      //Default flow
      clientApi.reauthenticate(() => {
        getPlayerSiteCompanyInfo(true)
      }, (error) => {
        getPlayerSiteCompanyInfo(false)
      })
    }

    //Monitor connection
    FeathersClient.monitorConnection(() => {
      setSnackBar(snackBar => ({
        ...snackBar,
        title: 'Reconnected',
        severity: 'success',
        isOpen: true
      }))
    }, () => {
      setSnackBar(snackBar => ({
        ...snackBar,
        title: 'Disconnected',
        severity: 'warning',
        isOpen: true
      }))
    })
  }, [])

  useEffect(() => {
    //Do things when route changes
    setOpenForgotPwd(false)
    setOpenLogin(false)
    setOpenRegister(false)
    setOpenChangePwd(false)
    setGamePopup(null)
    setPopup({
      ...popup,
      isOpen: false
    })
  }, [location])

  const scaffold = () => {
    //Check if is launching game
    let path = window.location.pathname
    if (path.indexOf('gameLauncher') > -1) {
      //Is launching game, avoid calling api
      return (
        <div className='full-height'>
          <div className='column'>
            <Outlet />
          </div>
        </div>
      )
    } else {
      //Not launching game, proceed to show scaffold
      var bgImageUrl = `${process.env.PUBLIC_URL}/images/body.jpeg`

      let bodyBackgroundImage = companyInfo?.company?.bodyBackgroundImage
      if (bodyBackgroundImage && bodyBackgroundImage.length > 0) {
        bgImageUrl = Utils.shared.getCompanyImagesLink(companyInfo, bodyBackgroundImage, companyInfo?.company?._id)
      }

      let bodyBackgroundType = companyInfo?.company?.bodyBackgroundType ?? Constants.backgroundTypeImage
      const getBackgroundColor = () => {
        if (bodyBackgroundType === Constants.backgroundTypeColor) {
          let bodyBackgroundColor = companyInfo?.company?.bodyBackgroundColor
          if (bodyBackgroundColor) {
            return bodyBackgroundColor
          }
        }

        return 'black'
      }
      const getBackgroundImage = () => {
        if (bodyBackgroundType === Constants.backgroundTypeImage) {
          return bgImageUrl
        }

        return null
      }
      const getBottomNavigationBar = () => {
        if (companyInfo?.company?.templateDesign === Constants.templateDesignModern) {
          if (!screenType.isMobile && !screenType.isTablet) {
            return null
          }
        }

        return (
          <BottomNavigationBar />
        )
      }
      const getFloatingNavigationBar = () => {
        if (companyInfo?.company?.templateDesign === Constants.templateDesignModern) {
          if (!screenType.isMobile && !screenType.isTablet) {
            return (
              <Stack sx={{
                position: 'fixed',
                left: '32px',
                zIndex: '900',
                height: '100vh'
              }} justifyContent={'center'}>
                <FloatingNavigationView />
              </Stack>
            )
          }
        }

        return null
      }

      return (
        <div className='full-height'>
          <div style={{ backgroundColor: getBackgroundColor(), backgroundImage: getBackgroundImage() ? `url(${bgImageUrl})` : null, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }} />
          <div className='column'>
            <AppTopBar />
            <div style={{ minHeight: template.contentHeight, width: '100%' }}>
              <Outlet />
            </div>
            {getBottomNavigationBar()}
            {getFloatingNavigationBar()}
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <Helmet>
        {renderMetaPixel({ id: helmetInfo.facebookPixelID })}
      </Helmet>
      <AlertDialogContext.Provider value={{ alertDialog, setAlertDialog }}>
        <SnackBarContext.Provider value={{ snackBar, setSnackBar }}>
          <PopupContext.Provider value={{ popup, setPopup }}>
            <div>
              {
                isInitialLoading ? null :
                  (
                    <CompanyInfoContext.Provider value={{ companyInfo, setCompanyInfo }}>
                      <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
                        <RegisterPopupContext.Provider value={{ openRegister, setOpenRegister }}>
                          <LoginPopupContext.Provider value={{ openLogin, setOpenLogin }}>
                            <ForgotPwdPopupContext.Provider value={{ openForgotPwd, setOpenForgotPwd }}>
                              <ChangePwdPopupContext.Provider value={{ openChangePwd, setOpenChangePwd }}>
                                <GamePopupContext.Provider value={{ gamePopup, setGamePopup }}>
                                  <BonusPopupContext.Provider value={{ bonusPopup, setBonusPopup }}>
                                    <ClearBalancePopupContext.Provider value={{ openClearBalance, setOpenClearBalance }} >
                                      <Provider feathers={client} >
                                        {scaffold()}
                                      </Provider>
                                      <Dialog fullScreen={true} open={openLogin} onClose={() => {
                                        setOpenLogin(false)
                                      }} PaperProps={{
                                        sx: {
                                          maxWidth: screenType.isMobile ? null : '800px',
                                          maxHeight: screenType.isMobile ? null : '600px',
                                          borderRadius: screenType.isMobile ? null : '32px'
                                        }
                                      }}>
                                        <Login />
                                      </Dialog>
                                      <Dialog fullScreen={true} open={openRegister} onClose={() => {
                                        setOpenRegister(false)
                                      }} PaperProps={{
                                        sx: {
                                          maxWidth: screenType.isMobile ? null : '800px',
                                          maxHeight: screenType.isMobile ? null : '600px',
                                          borderRadius: screenType.isMobile ? null : '32px'
                                        }
                                      }}>
                                        <Register />
                                      </Dialog>
                                      <Dialog fullScreen={true} open={openForgotPwd} onClose={() => {
                                        setOpenForgotPwd(false)
                                      }} PaperProps={{
                                        sx: {
                                          maxWidth: screenType.isMobile ? null : '800px',
                                          maxHeight: screenType.isMobile ? null : '600px',
                                          borderRadius: screenType.isMobile ? null : '32px',
                                          overflow: 'hidden'
                                        }
                                      }}>
                                        <ForgotPassword />
                                      </Dialog>
                                      <Dialog fullScreen={true} open={openChangePwd} onClose={(event, reason) => {
                                        let isFirstTimeLogin = userInfo?.isFirstTimeLogin ?? false
                                        if (!isFirstTimeLogin) {
                                          setOpenChangePwd(false)
                                        }
                                      }} PaperProps={{
                                        sx: {
                                          maxWidth: '800px',
                                          maxHeight: '600px',
                                          borderRadius: '32px',
                                          overflow: 'hidden'
                                        }
                                      }}>
                                        <ChangePassword />
                                      </Dialog>
                                      <Dialog fullScreen={false} open={gamePopup != null} onClose={() => {
                                        setGamePopup(null)
                                      }} PaperProps={{
                                        sx: {
                                          borderRadius: '32px',
                                          overflow: 'hidden'
                                        }
                                      }}>
                                        <DownloadAppPopup game={gamePopup?.game} gamesPlayerAccount={gamePopup?.gamesPlayerAccount} />
                                      </Dialog>
                                      <Dialog open={bonusPopup?.bonus != null} onClose={() => {
                                        setBonusPopup(bonusPopup => ({
                                          ...bonusPopup,
                                          bonus: null,
                                          callingBonusRequirement: false
                                        }))
                                      }} PaperProps={{
                                        sx: {
                                          width: screenType.isMobile ? null : '800px',
                                          maxWidth: screenType.isMobile ? '90%' : '80%',
                                          maxHeight: '85%',
                                          backgroundColor: 'black',
                                          color: 'white',
                                          border: `2px solid ${Constants.defaultPopupBorderColor}`,
                                          borderRadius: '5px',
                                          boxShadow: 24,
                                          display: 'flex',
                                          flexDirection: 'column',
                                          zIndex: 1,
                                          overflow: 'scroll',
                                          msOverflowStyle: 'none',
                                          scrollbarWidth: 'none',
                                          '::-webkit-scrollbar': {
                                            display: 'none'
                                          }
                                        }
                                      }}>
                                        {
                                          bonusPopup?.bonus ? (
                                            <BonusPopup bonus={bonusPopup.bonus} requirement={bonusPopup.requirement} onClosed={() => {
                                              setBonusPopup(bonusPopup => ({
                                                ...bonusPopup,
                                                bonus: null,
                                                callingBonusRequirement: false
                                              }))
                                            }} />
                                          ) : null
                                        }
                                      </Dialog>
                                      {/* Clear Balance Popup */}
                                      <Dialog fullScreen={false} open={openClearBalance} onClose={() => {
                                        setOpenClearBalance(null)
                                      }} PaperProps={{
                                        sx: {
                                          borderRadius: '32px',
                                          overflow: 'hidden'
                                        }
                                      }}>
                                        <ClearPlayerCreditBalancePopup onConfirm={() => {
                                          Utils.shared.performClearPlayerCreditBalance({
                                            setAlertDialog: setAlertDialog,
                                            setCompanyInfo: setCompanyInfo,
                                            setUserInfo: setUserInfo,
                                            navigate: navigate,
                                            userInfo: userInfo,
                                            onSuccess: () => {
                                              setCompanyInfo(companyInfo => ({ ...companyInfo, isAllowClearPlayerCreditBalance: false }))
                                            }
                                          })
                                        }} />
                                      </Dialog>
                                    </ClearBalancePopupContext.Provider>
                                  </BonusPopupContext.Provider>
                                </GamePopupContext.Provider>
                              </ChangePwdPopupContext.Provider>
                            </ForgotPwdPopupContext.Provider>
                          </LoginPopupContext.Provider>
                        </RegisterPopupContext.Provider>
                        <BasePopup />
                      </UserInfoContext.Provider>
                    </CompanyInfoContext.Provider>
                  )
              }
              <AlertDialog />
              <SnackBar />
            </div>
          </PopupContext.Provider>
        </SnackBarContext.Provider>
      </AlertDialogContext.Provider>
    </>
  )
}

export default App;
export const CompanyInfoContext = React.createContext({ companyInfo: null, setCompanyInfo: () => { } })
export const UserInfoContext = React.createContext({ userInfo: null, setUserInfo: () => { } })
export const LoginPopupContext = React.createContext({ openLogin: false, setOpenLogin: () => { } })
export const RegisterPopupContext = React.createContext({ openRegister: false, setOpenRegister: () => { } })
export const ForgotPwdPopupContext = React.createContext({ openForgotPwd: false, setOpenForgotPwd: () => { } })
export const ChangePwdPopupContext = React.createContext({ openChangePwd: false, setOpenChangePwd: () => { } })
export const GamePopupContext = React.createContext({ gamePopup: null, setGamePopup: () => { } })
export const BonusPopupContext = React.createContext({ bonusPopup: null, setBonusPopup: () => { } })
export const ClearBalancePopupContext = React.createContext({ openClearBalance: false, setOpenClearBalance: () => { } })
export const AlertDialogContext = React.createContext({
  alertDialog: {
    title: 'Error',
    description: '',
    translationData: null,
    closeButtonTitle: 'OK',
    isOpen: false,
    isConfirmation: false,
    onConfirmed: () => { },
    onClosed: () => { }
  }, setAlertDialog: () => { }
})
export const SnackBarContext = React.createContext({
  snackBar: {
    title: '',
    severity: 'success',
    isOpen: false
  }, setSnackBar: () => { }
})
export const PopupContext = React.createContext({
  popup: {
    children: null,
    showCloseButton: false,
    dynamicWidth: false,
    isOpen: false,
    shouldNotClosePopupOnBackClick: false,
    onClosed: () => { }
  }, setPopup: () => { }
})
