import React, { useContext } from "react";
import { CompanyInfoContext } from '../../App';

// Import Swiper React components
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './bannerSlider.css'
import Utils from "../../utils/utils";

function BannerSlider() {
    //Contexts
    const { companyInfo } = useContext(CompanyInfoContext);

    var banners = []
    var bannersAction = []

    let bannerImage1 = companyInfo?.company?.bannerImage1
    if (bannerImage1 && bannerImage1.length > 0) {
        banners.push(Utils.shared.getCompanyImagesLink(companyInfo, bannerImage1, companyInfo?.company?._id))

        let bannerAction1 = companyInfo?.company?.bannerAction1 ?? ''
        bannersAction.push(bannerAction1)
    }

    let bannerImage2 = companyInfo?.company?.bannerImage2
    if (bannerImage2 && bannerImage2.length > 0) {
        banners.push(Utils.shared.getCompanyImagesLink(companyInfo, bannerImage2, companyInfo?.company?._id))

        let bannerAction2 = companyInfo?.company?.bannerAction2 ?? ''
        bannersAction.push(bannerAction2)
    }

    let bannerImage3 = companyInfo?.company?.bannerImage3
    if (bannerImage3 && bannerImage3.length > 0) {
        banners.push(Utils.shared.getCompanyImagesLink(companyInfo, bannerImage3, companyInfo?.company?._id))

        let bannerAction3 = companyInfo?.company?.bannerAction3 ?? ''
        bannersAction.push(bannerAction3)
    }

    let bannerImage4 = companyInfo?.company?.bannerImage4
    if (bannerImage4 && bannerImage4.length > 0) {
        banners.push(Utils.shared.getCompanyImagesLink(companyInfo, bannerImage4, companyInfo?.company?._id))

        let bannerAction4 = companyInfo?.company?.bannerAction4 ?? ''
        bannersAction.push(bannerAction4)
    }

    let bannerImage5 = companyInfo?.company?.bannerImage5
    if (bannerImage5 && bannerImage5.length > 0) {
        banners.push(Utils.shared.getCompanyImagesLink(companyInfo, bannerImage5, companyInfo?.company?._id))

        let bannerAction5 = companyInfo?.company?.bannerAction5 ?? ''
        bannersAction.push(bannerAction5)
    }

    //If no banners added, default to defaultBanner
    if (banners.length === 0) {
        banners.push(`${process.env.PUBLIC_URL}/images/defaultBanner.jpeg`)
    }

    const swiperSlides = banners.map((e, index) => {
        let link = bannersAction[index] ?? ''

        return (
            <SwiperSlide key={index} style={{ width: '100%', height: 'auto' }}>
                <img src={e} alt="banner" width='100%' height='100%' loading="lazy" draggable={false} style={{ objectFit: 'cover', cursor: link.length > 0 ? 'pointer' : 'auto' }} onClick={() => {
                    if (link.length > 0) {
                        window.open(link, '_blank', 'noopener,noreferrer')
                    }
                }} />
            </SwiperSlide>
        )
    })

    return (
        <div style={{ width: '100%', height: 'auto', overflow: 'hidden' }}>
            <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                loop={true}
                pagination={{ clickable: true, bulletActiveClass: 'activeBulletStyle' }}
                autoplay={{ delay: 6000 }}
            >
                {swiperSlides}
            </Swiper>
        </div>
    )
}

export default BannerSlider;