import { InfoOutlined } from "@mui/icons-material"
import { Stack } from "@mui/material"
import { useContext } from "react"
import { AlertDialogContext, PopupContext } from "../../App"
import BaseButton from "../button/button"
import { request } from '../../feathers/feathersClient'
import Constants from '../../constants'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function ClaimBonusBeforePlayPopup({ companyId, userId, companyGameId, gameCategory, closeTabOnCancel = false, onBonusDeactivated }) {
    let navigate = useNavigate()

    //Context
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setPopup } = useContext(PopupContext)
    const client = request({
        setAlertDialog: setAlertDialog,
        navigate: navigate
    })
    const { t } = useTranslation()

    return (
        <Stack>
            <Stack sx={{ alignItems: 'center', margin: '10px' }}>
                <InfoOutlined fontSize='large' htmlColor={Constants.defaultPopupBorderColor} />
                <div style={{ fontSize: '18px', margin: '10px' }}>
                    <b>
                        {t('BONUS_CLAIM_BEFORE_PLAY_TITLE')}
                    </b>
                </div>
                <div style={{ fontSize: '14px', marginBottom: '10px', textAlign: 'center' }}>
                    {t('BONUS_CLAIM_BEFORE_PLAY_DESC')}
                </div>
            </Stack>
            <Stack sx={{ alignItems: 'center', margin: '10px' }}>
                <Stack direction='row' spacing='10px'>
                    <BaseButton title={t('BONUS_CLAIM_BEFORE_PLAY_CANCEL')} color={Constants.buttonRedColor} textColor={'black'} fullWidth={false} onTap={() => {
                        setPopup(popup => ({
                            ...popup,
                            isOpen: false
                        }))

                        if (closeTabOnCancel) {
                            window.close()
                        }
                    }} />
                    <BaseButton title={t('BONUS_CLAIM_BEFORE_PLAY_ENTER_GAME')} fullWidth={false} onTap={() => {
                        setPopup(popup => ({
                            ...popup,
                            isOpen: false
                        }))

                        client.createREST('deactivate-player-bonus-type-deposit', {
                            company: companyId,
                            user: userId,
                            companyGame: companyGameId,
                            gameCategory: gameCategory,
                        }, (response) => {
                            onBonusDeactivated()
                        })
                    }} />
                </Stack>
            </Stack>
        </Stack>
    )
}