import { useContext, useEffect, useState } from "react"
import { CompanyInfoContext } from "../App"
import Constants from '../constants'
import useWindowSize from "../hooks/useWindowSize"
import useScreenType from "../hooks/useScreenType"

export function useTemplateVariables() {
    const { companyInfo } = useContext(CompanyInfoContext)
    const [width, height] = useWindowSize()
    const screenType = useScreenType()
    const [values, setValues] = useState({
        windowHeight: height,
        headerHeight: 0,
        contentHeight: 0,
        footerHeight: 0,
        navigationFontColor: Constants.defaultNavBarFontColor,
        mainFontColor: Constants.defaultMainColor,
        otherButtonColor: Constants.defaultButtonColor,
        otherButtonFontColor: Constants.defaultButtonFontColor,
        otherButtonCSS: companyInfo?.company?.otherButtonCSS,
        depositButtonColor: companyInfo?.company?.depositButtonColor ?? Constants.defaultButtonColor,
        depositButtonFontColor: companyInfo?.company?.depositButtonFontColor ?? Constants.defaultButtonFontColor,
        withdrawalButtonColor: companyInfo?.company?.withdrawalButtonColor ?? Constants.defaultButtonColor,
        withdrawalButtonFontColor: companyInfo?.company?.withdrawalButtonFontColor ?? Constants.defaultButtonFontColor,
        design: companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic,
        navigationBackgroundType: companyInfo?.company?.navigationBackgroundType ?? Constants.backgroundTypeImage,
        nameFontColor: companyInfo?.company?.nameFontColor ?? '#f7c144',
        loginPageTheme: {
            primaryColor: companyInfo?.company?.loginPageTheme?.primaryColor,
            secondaryColor: companyInfo?.company?.loginPageTheme?.secondaryColor
        },
        profilePageTheme: {
            primaryColor: companyInfo?.company?.profilePageTheme?.primaryColor,
            secondaryColor: companyInfo?.company?.profilePageTheme?.secondaryColor
        }
    })

    useEffect(() => {
        var headerHeight = values.headerHeight
        if (values.design === Constants.templateDesignModern) {
            headerHeight = 64
        } else {
            headerHeight = 84
        }

        var footerHeight = values.footerHeight
        if (values.design === Constants.templateDesignModern) {
            if (screenType.isMobile) {
                footerHeight = 68
            } else {
                footerHeight = 0
            }
        } else {
            footerHeight = 56
        }

        //Main Font Color
        var mainFontColor = Constants.defaultMainColor
        if (companyInfo?.company?.mainFontColor && companyInfo?.company?.mainFontColor.length > 0) {
            mainFontColor = companyInfo?.company?.mainFontColor
        }

        //Nav Font Color
        var navBarFontColor = values.navigationFontColor
        if (companyInfo?.company?.navBarFontColor && companyInfo?.company?.navBarFontColor.length > 0) {
            navBarFontColor = companyInfo?.company?.navBarFontColor
        }

        //Button Color
        var otherButtonColor = Constants.defaultButtonColor
        if (companyInfo?.company?.otherButtonColor && companyInfo?.company?.otherButtonColor.length > 0) {
            otherButtonColor = companyInfo?.company?.otherButtonColor
        }

        //Button Font Color
        var otherButtonFontColor = Constants.defaultButtonFontColor
        if (companyInfo?.company?.otherButtonFontColor && companyInfo?.company?.otherButtonFontColor.length > 0) {
            otherButtonFontColor = companyInfo?.company?.otherButtonFontColor
        }

        // Deposit button theme
        var depositButtonColor = companyInfo?.company?.depositButtonColor ?? ''
        if (depositButtonColor.length === 0) {
            depositButtonColor = Constants.defaultButtonColor
        }

        var depositButtonFontColor = companyInfo?.company?.depositButtonFontColor ?? ''
        if (depositButtonFontColor.length === 0) {
            depositButtonFontColor = Constants.defaultButtonFontColor
        }

        // Withdrawal button theme
        var withdrawalButtonColor = companyInfo?.company?.withdrawalButtonColor ?? ''
        if (withdrawalButtonColor.length === 0) {
            withdrawalButtonColor = Constants.defaultButtonColor
        }

        var withdrawalButtonFontColor = companyInfo?.company?.withdrawalButtonFontColor ?? ''
        if (withdrawalButtonFontColor.length === 0) {
            withdrawalButtonFontColor = Constants.defaultButtonFontColor
        }

        setValues(values => ({
            ...values,
            windowHeight: height,
            headerHeight: headerHeight,
            contentHeight: height - headerHeight - footerHeight,
            navigationFontColor: navBarFontColor,
            mainFontColor: mainFontColor,
            otherButtonColor: otherButtonColor,
            otherButtonFontColor: otherButtonFontColor,
            otherButtonCSS: companyInfo?.company?.otherButtonCSS,
            depositButtonColor: depositButtonColor,
            depositButtonFontColor: depositButtonFontColor,
            withdrawalButtonColor: withdrawalButtonColor,
            withdrawalButtonFontColor: withdrawalButtonFontColor,
            design: companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic,
            navigationBackgroundType: companyInfo?.company?.navigationBackgroundType ?? Constants.backgroundTypeImage,
            nameFontColor: companyInfo?.company?.nameFontColor ?? '#f7c144',
            loginPageTheme: {
                primaryColor: companyInfo?.company?.loginPageTheme?.primaryColor,
                secondaryColor: companyInfo?.company?.loginPageTheme?.secondaryColor
            },
            profilePageTheme: {
                primaryColor: companyInfo?.company?.profilePageTheme?.primaryColor,
                secondaryColor: companyInfo?.company?.profilePageTheme?.secondaryColor
            }
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height])

    return values
}