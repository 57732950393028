import io from 'socket.io-client';
import feathers from "@feathersjs/client";
import Constants from '../constants';
import i18n from 'i18next';

const getWindow = () => {
    if (typeof window !== 'undefined') {
        return window
    }

    return null
}
const getCurrentLanguage = () => {
    if (i18n.language) {
        return i18n.language
    } else if (i18n.languages && i18n.languages.length > 0) {
        return i18n.languages[0]
    } else {
        return Constants.languageCodeEnglish
    }
}

// Socket.io is exposed as the `io` global.
const socket = io(`${Constants.baseURL}`, {
    transports: ['websocket'],
    upgrade: false,
    timeout: 120000,
    query: {
        lang: getCurrentLanguage()
    }
})

// @feathersjs/client is exposed as the `feathers` global.
const app = feathers();

app.configure(feathers.socketio(socket, {
    timeout: 120000,
}))
app.configure(feathers.authentication({
    storage: getWindow()?.localStorage,
    storageKey: `FEATHERSJS_ACCESS_TOKEN${getWindow()?.location?.host}`,
    jwtStrategy: 'jwt'
}))

export default app 