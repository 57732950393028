import { Box, Stack } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import Odometer from 'react-odometerjs'
import { CompanyInfoContext } from '../../App'
import FeathersClient from '../../feathers/feathersClient'
import Utils from '../../utils/utils'

export default function JackpotView() {
    const { companyInfo } = useContext(CompanyInfoContext)
    const company = companyInfo?.company

    //Enums
    const JackpotAlignment = {
        top: 'top',
        left: 'left',
        right: 'right',
        bottom: 'bottom',
        center: 'center',
    }

    //States
    const [randomNumber, setRandomNumber] = useState(company.jackpotDisplayValue ?? 0)

    //SOCKET METHODS
    const startSocket = () => {
        FeathersClient.listen('companies', 'patched', 'jackpot', (newData) => {
            if (newData?._id === company?._id) {
                let newNumber = newData?.jackpotDisplayValue ?? 0
                setRandomNumber(newNumber)
            }
        })
    }

    useEffect(() => {
        startSocket()
    }, [])

    let showJackpotDisplay = company?.showJackpotDisplay ?? false
    if (showJackpotDisplay) {
        var imageUrl = `${process.env.PUBLIC_URL}/images/defaultJackpotBackground.png`

        let jackpotDisplayBGImage = companyInfo.company.jackpotDisplayBGImage ?? ''
        if (jackpotDisplayBGImage.length > 0) {
            imageUrl = Utils.shared.getCompanyImagesLink(companyInfo, jackpotDisplayBGImage, company?._id)
        }

        var justifyContent = 'center'
        var alignItems = 'center'
        var transform = 'translate(-50%, -50%)'
        var top = '50%'
        var left = '50%'
        var right = null
        var bottom = null
        let jackpotDisplayAlignment = companyInfo.company.jackpotDisplayAlignment ?? JackpotAlignment.center
        switch (jackpotDisplayAlignment) {
            case JackpotAlignment.top:
                justifyContent = 'start'
                transform = 'translate(-50%, 0)'
                top = '5px'
                left = '50%'
                right = null
                bottom = null
                break
            case JackpotAlignment.left:
                alignItems = 'start'
                transform = 'translate(0, -50%)'
                top = '50%'
                left = '5px'
                right = null
                bottom = null
                break
            case JackpotAlignment.right:
                alignItems = 'end'
                transform = 'translate(0, -50%)'
                top = '50%'
                left = null
                right = '5px'
                bottom = null
                break
            case JackpotAlignment.bottom:
                justifyContent = 'end'
                transform = 'translate(-50%, 0)'
                top = null
                left = '50%'
                right = null
                bottom = '5px'
                break
            default:
                break
        }

        return (
            <Box sx={{ width: '100%', position: 'relative' }}>
                <img src={imageUrl} width='100%' height='auto' alt='jackpotBG' loading="lazy" />
                <Stack sx={{ justifyContent: justifyContent, alignItems: alignItems, top: top, left: left, right: right, bottom: bottom, transform: transform, position: 'absolute', width: '100%', height: '100%' }}>
                    <Odometer value={randomNumber} format="(,ddd).dd" theme='slot-machine' />
                </Stack>
            </Box>
        )
    } else {
        return null
    }
}