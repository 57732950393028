import { Close, ContentCopy, CopyAllOutlined, Info } from "@mui/icons-material"
import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import BaseButton from "../button/button"
import Constants, { templateDesignModern } from '../../constants'
import { CompanyInfoContext, GamePopupContext, SnackBarContext } from "../../App"
import { useContext } from "react"
import Utils from "../../utils/utils"
import { useTranslation } from "react-i18next"
import { useTemplateVariables } from "../../utils/templateUtils"
import InputTextField from "../textfield/inputTextField"

export default function DownloadAppPopup({ game, gamesPlayerAccount }) {
    const template = useTemplateVariables()

    const label = game?.game?.label ?? ''
    const gameLink = gamesPlayerAccount?.gameLink ?? ''
    const gameLinkIOS = gamesPlayerAccount?.gameLinkIOS ?? ''
    const gameDownloanLinkIOS = gamesPlayerAccount?.gameDownloanLinkIOS ?? gameLink
    const gameLinkAndroid = gamesPlayerAccount?.gameLinkAndroid ?? ''
    const gameDownloadLinkAndroid = gamesPlayerAccount?.gameDownloadLinkAndroid ?? gameLink
    const gamesPlayer = gamesPlayerAccount?.gamesPlayer
    const userName = gamesPlayer?.userName ?? ''
    const password = gamesPlayer?.password ?? ''

    //Context
    const { t } = useTranslation()
    const { companyInfo } = useContext(CompanyInfoContext)
    const { gamePopup, setGamePopup } = useContext(GamePopupContext)
    const { setSnackBar } = useContext(SnackBarContext)

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    if (template.design === templateDesignModern) {
        return (
            <Stack sx={{
                backgroundColor: 'white',
                padding: '16px',
                borderRadius: '32px',
                overflow: 'hidden'
            }} alignItems={'start'} justifyContent={'center'}>
                <Stack sx={{
                    padding: '16px',
                    width: 'calc(100% - 32px)'
                }} spacing='32px'>
                    <Stack>
                        <Typography sx={{
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '26px',
                            color: template.mainFontColor
                        }}>
                            {label}
                        </Typography>
                        <Divider sx={{
                            height: '2px',
                            backgroundColor: template.navigationFontColor,
                            width: '40%'
                        }} />
                        <Stack direction={'row'} alignItems={'center'} spacing='8px'>
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '22px',
                                color: '#585858'
                            }}>
                                {'Please download the app to play'}
                            </Typography>
                            <Tooltip title={
                                <Stack>
                                    <Box>
                                        {t('HOME_GAME_POPUP_INFO')}
                                    </Box>
                                    <Button sx={{ textTransform: 'none', color: 'white' }} onClick={() => {
                                        window.open('https://1.1.1.1', '_blank')?.focus()
                                    }}>
                                        https://1.1.1.1/
                                    </Button>
                                </Stack>
                            } enterTouchDelay={0} leaveTouchDelay={5000}>
                                <Info fontSize="small" htmlColor={template.mainFontColor} />
                            </Tooltip>
                        </Stack>
                    </Stack>
                    <Stack spacing='0px'>
                        <Stack spacing='8px'>
                            <Stack sx={{
                                padding: '8px 4px 8px 16px',
                                border: '1px solid var(--n-colour-n-300, #D5D5D5)',
                                borderRadius: '20px'
                            }} direction={'row'} alignItems={'center'}>
                                <Stack sx={{ flexGrow: 1 }}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        lineHeight: '16px',
                                        color: '#828282'
                                    }}>
                                        {'Username'}
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        lineHeight: '24px',
                                        color: '#585858'
                                    }}>
                                        {userName}
                                    </Typography>
                                </Stack>
                                <IconButton onClick={() => {
                                    navigator.clipboard.writeText(userName)
                                    setSnackBar(snackBar => ({
                                        ...snackBar,
                                        title: 'Copied',
                                        isOpen: true
                                    }))
                                }}>
                                    <ContentCopy htmlColor={'#828282'} />
                                </IconButton>
                            </Stack>
                            <Stack sx={{
                                padding: '8px 4px 8px 16px',
                                border: '1px solid var(--n-colour-n-300, #D5D5D5)',
                                borderRadius: '20px'
                            }} direction={'row'} alignItems={'center'}>
                                <Stack sx={{ flexGrow: 1 }}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        lineHeight: '16px',
                                        color: '#828282'
                                    }}>
                                        {'Password'}
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        lineHeight: '24px',
                                        color: '#585858'
                                    }}>
                                        {password}
                                    </Typography>
                                </Stack>
                                <IconButton onClick={() => {
                                    navigator.clipboard.writeText(password)
                                    setSnackBar(snackBar => ({
                                        ...snackBar,
                                        title: 'Copied',
                                        isOpen: true
                                    }))
                                }}>
                                    <ContentCopy htmlColor={'#828282'} />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack spacing={'8px'}>
                        <BaseButton title={t('PLAY NOW')} color={template.navigationFontColor} textColor={template.mainFontColor} borderRadius="16px" enableGradient={false} fullWidth={false} onTap={(event) => {
                            const platform = Utils.shared.getCurrentPlatform()
                            var downloadLink = gameLink
                            var fallBackLink = gameLink

                            switch (platform) {
                                case 'android':
                                    downloadLink = gameLinkAndroid
                                    fallBackLink = gameDownloadLinkAndroid
                                    break
                                case 'ios':
                                    downloadLink = gameLinkIOS
                                    fallBackLink = gameDownloanLinkIOS
                                    break
                                default:
                                    break
                            }

                            let gameWindow = window.open(downloadLink, '_self')
                            if (gameWindow) {
                                gameWindow?.focus()
                            } else {
                                window.open(fallBackLink, '_blank')?.focus()
                            }
                        }} />
                        <BaseButton title={'DOWNLOAD NOW'} color={template.mainFontColor} textColor={template.navigationFontColor} borderRadius="16px" enableGradient={false} fullWidth={false} onTap={() => {
                            const platform = Utils.shared.getCurrentPlatform()
                            var downloadLink = gameLink

                            switch (platform) {
                                case 'android':
                                    downloadLink = gameDownloadLinkAndroid
                                    break
                                case 'ios':
                                    downloadLink = gameDownloanLinkIOS
                                    break
                                default:
                                    break
                            }

                            window.open(downloadLink, '_blank')?.focus()
                        }} />
                    </Stack>
                </Stack>

                <IconButton onClick={() => {
                    setGamePopup(null)
                }} size="small" sx={{
                    backgroundColor: template.mainFontColor,
                    position: 'absolute',
                    top: 16,
                    right: 16
                }}>
                    <Close htmlColor="white" fontSize="small" />
                </IconButton>
            </Stack>
        )
    } else {
        return (
            <Stack>
                <Stack sx={{ alignItems: 'left', margin: '10px', color: color }}>
                    <Stack direction='row' sx={{ fontSize: '18px', paddingBottom: '10px', borderBottom: `1px solid ${Constants.defaultPopupBorderColor}`, alignItems: 'center' }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <b>{label}</b>
                        </Box>
                        <Tooltip title={
                            <Stack>
                                <Box>
                                    {t('HOME_GAME_POPUP_INFO')}
                                </Box>
                                <Button sx={{ textTransform: 'none', color: 'white' }} onClick={() => {
                                    window.open('https://1.1.1.1', '_blank')?.focus()
                                }}>
                                    https://1.1.1.1/
                                </Button>
                            </Stack>
                        } enterTouchDelay={0} leaveTouchDelay={5000}>
                            <Info htmlColor='orange' />
                        </Tooltip>
                    </Stack>
                    <div style={{ height: '10px' }} />
                    <div style={{ padding: '0px 0px 0px 10px', border: `1px solid ${Constants.defaultPopupBorderColor}`, borderRadius: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: '100%' }} >
                            {userName}
                        </div>
                        <IconButton onClick={() => {
                            navigator.clipboard.writeText(userName)
                            setSnackBar(snackBar => ({
                                ...snackBar,
                                title: 'Copied',
                                isOpen: true
                            }))
                        }}>
                            <CopyAllOutlined htmlColor={color} />
                        </IconButton>
                    </div>
                    <div style={{ height: '10px' }} />
                    <div style={{ padding: '0px 0px 0px 10px', border: `1px solid ${Constants.defaultPopupBorderColor}`, borderRadius: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: '100%' }} >
                            {password}
                        </div>
                        <IconButton onClick={() => {
                            navigator.clipboard.writeText(password)
                            setSnackBar(snackBar => ({
                                ...snackBar,
                                title: 'Copied',
                                isOpen: true
                            }))
                        }}>
                            <CopyAllOutlined htmlColor={color} />
                        </IconButton>
                    </div>
                    <div style={{ height: '10px' }} />
                    <div style={{ fontSize: '14px', marginBottom: '10px' }}>
                        * Please download the app to play
                    </div>
                </Stack>
                <Stack sx={{ alignItems: 'center', margin: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '10px' }}>
                            <BaseButton title={'DOWNLOAD NOW'} fullWidth={false} onTap={() => {
                                const platform = Utils.shared.getCurrentPlatform()
                                var downloadLink = gameLink

                                switch (platform) {
                                    case 'android':
                                        downloadLink = gameDownloadLinkAndroid
                                        break
                                    case 'ios':
                                        downloadLink = gameDownloanLinkIOS
                                        break
                                    default:
                                        break
                                }

                                window.open(downloadLink, '_blank')?.focus()
                            }} />
                        </div>
                        <BaseButton title={'PLAY NOW'} fullWidth={false} color={Constants.buttonGreenColor} onTap={() => {
                            const platform = Utils.shared.getCurrentPlatform()
                            var downloadLink = gameLink
                            var fallBackLink = gameLink

                            switch (platform) {
                                case 'android':
                                    downloadLink = gameLinkAndroid
                                    fallBackLink = gameDownloadLinkAndroid
                                    break
                                case 'ios':
                                    downloadLink = gameLinkIOS
                                    fallBackLink = gameDownloanLinkIOS
                                    break
                                default:
                                    break
                            }

                            let gameWindow = window.open(downloadLink, '_self')
                            if (gameWindow) {
                                gameWindow?.focus()
                            } else {
                                window.open(fallBackLink, '_blank')?.focus()
                            }
                        }} />
                    </div>
                </Stack>
            </Stack>
        )
    }
}