import { useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Utils from "../../utils/utils";
import { request } from "../../feathers/feathersClient"
import Constants from '../../constants'
import { AlertDialogContext, CompanyInfoContext, PopupContext, UserInfoContext } from "../../App";
import ClaimBonusBeforePlayPopup from "../../components/game/claimBonusBeforePlayPopup";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

export default function GameLauncher() {
    const navigate = useNavigate()
    const location = useLocation()
    const search = useLocation().search
    const companyGame = new URLSearchParams(search).get('cg') ?? ''
    const gameCategory = new URLSearchParams(search).get('gc') ?? ''
    const gameBehaviour = new URLSearchParams(search).get('gb') ?? ''
    const user = new URLSearchParams(search).get('u') ?? ''
    const company = new URLSearchParams(search).get('c') ?? ''
    const token = new URLSearchParams(search).get('t') ?? ''
    const isWebMobile = Utils.shared.checkIfIsMobile()

    const { i18n } = useTranslation()

    //For use from game listing page
    const gamesPlayerId = new URLSearchParams(search).get('gp') ?? ''
    const gameId = new URLSearchParams(search).get('gi') ?? ''

    //Context
    const { setCompanyInfo } = useContext(CompanyInfoContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setPopup } = useContext(PopupContext)
    const client = request({
        setCompanyInfo: setCompanyInfo,
        setUserInfo: setUserInfo,
        navigate: navigate
    })

    //Ref
    const gameLastLaunched = useRef(Date.now())
    const isGameLaunching = useRef(false)

    const getCurrentLanguage = () => {
        if (i18n.language) {
            return i18n.language
        } else if (i18n.languages && i18n.languages.length > 0) {
            return i18n.languages[0]
        } else {
            return Constants.languageCodeEnglish
        }
    }

    //API METHODS
    const getPlayerGameLink = () => {
        const isWebMobile = Utils.shared.checkIfIsMobile()

        //Store token first for REST to use
        Utils.shared.storeLocalStorage(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`, token)

        client.createREST('get-player-game-link', {
            gamesPlayer: gamesPlayerId,
            language: getCurrentLanguage(),
            gameCode: gameId,
            gamePlatform: isWebMobile ? 2 : 1,
        }, (response) => {
            let gameLink = response?.gameLink ?? ''
            window.open(gameLink, '_self')?.focus()
        }, (error) => {
            setAlertDialog(alertDialog => ({
                ...alertDialog,
                title: error?.name,
                translationData: error?.data,
                description: error?.message,
                isOpen: true,
                onClosed: () => {
                    window.close()
                }
            }))
        })
    }
    const getPlayerSingleGameLink = () => {
        const isWebMobile = Utils.shared.checkIfIsMobile()

        //Store token first for REST to use
        Utils.shared.storeLocalStorage(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`, token)

        client.createREST('get-player-single-game-link', {
            companyGame: companyGame,
            language: getCurrentLanguage(),
            gameCode: gameId,
            gamePlatform: isWebMobile ? 2 : 1,
            gameCategory: gameCategory
        }, (response) => {
            let gameLink = response?.gameLink ?? ''
            window.open(gameLink, '_self')?.focus()
        }, (error) => {
            if (error.code === Constants.feathers_error_code_claim_bonus_before_play) {
                //Show popup inform user if they want to claim bonus before play
                setPopup(popup => ({
                    ...popup,
                    children: <ClaimBonusBeforePlayPopup closeTabOnCancel={true} companyId={company} userId={user} companyGameId={companyGame} gameCategory={parseInt(gameCategory)} onBonusDeactivated={() => {
                        getPlayerSingleGameLink()
                    }} />,
                    showCloseButton: false,
                    shouldNotClosePopupOnBackClick: true,
                    isOpen: true
                }))
            } else {
                setAlertDialog(alertDialog => ({
                    ...alertDialog,
                    title: error?.name,
                    translationData: error?.data,
                    description: error?.message,
                    isOpen: true,
                    onClosed: () => {
                        window.close()
                    }
                }))
            }
        })
    }
    const launchGame = () => {
        //Prevent double calling
        if (gameLastLaunched.current > Date.now() || isGameLaunching.current) {
            return
        }

        //Set is game launching
        isGameLaunching.current = true

        //Store token first for REST to use
        Utils.shared.storeLocalStorage(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`, token)

        client.createREST('get-player-game-account', {
            companyGame: companyGame,
            gameCategory: parseInt(gameCategory),
            user: user,
            company: company,
            language: getCurrentLanguage(),
            gamePlatform: isWebMobile ? 2 : 1,
        }, (response) => {
            const gameBehaviour = response.gameBehaviour
            const gameLink = response?.gameLink ?? ''
            const gamesPlayer = response?.gamesPlayer

            switch (gameBehaviour) {
                case Constants.gameBehaviourCustomLayout:
                case Constants.gameBehaviourCustomLayoutFEHost:
                case Constants.gameBehaviourCustomLayoutCallGameLink:
                    navigate('/games', {
                        state: {
                            gameListing: response.gameListing,
                            gamesPlayer: gamesPlayer,
                            gameBehaviour: gameBehaviour
                        }
                    })
                    break
                case Constants.gameBehaviourOpenLink:
                    if (gameLink === 0) {
                        return
                    } else {
                        window.open(gameLink, '_self')?.focus()
                    }
                    break
                case Constants.gameBehaviourDownloadAPP:
                    //Show download app popup
                    // setPopup(popup => ({
                    //     ...popup,
                    //     children: <DownloadAppPopup game={game} gamesPlayerAccount={response} />,
                    //     showCloseButton: false,
                    //     isOpen: true
                    // }))

                    //Commented as there is no possibility that this page will show download app popup
                    break
                case Constants.gameBehaviourOpenLinkFEHost:
                    const playtechLink = `/playTech/index.html${gameLink}`

                    window.open(playtechLink, '_self')?.focus()
                    break
                default:
                    console.log('No known game behavior, not launching')
                    break
            }
        }, (error) => {
            if (error.code === Constants.feathers_error_code_claim_bonus_before_play) {
                //Show popup inform user if they want to claim bonus before play
                setPopup(popup => ({
                    ...popup,
                    children: <ClaimBonusBeforePlayPopup closeTabOnCancel={true} companyId={company} userId={user} companyGameId={companyGame} gameCategory={parseInt(gameCategory)} onBonusDeactivated={() => {
                        launchGame()
                    }} />,
                    showCloseButton: false,
                    shouldNotClosePopupOnBackClick: true,
                    isOpen: true
                }))
            } else {
                setAlertDialog(alertDialog => ({
                    ...alertDialog,
                    title: error?.name,
                    translationData: error?.data,
                    description: error?.message,
                    isOpen: true,
                    onClosed: () => {
                        window.close()
                    }
                }))
            }
        }, true, () => {
            //Set is game launching to false
            isGameLaunching.current = false

            //Set the game luanched time with additional 1 second
            gameLastLaunched.current = Date.now() + 1000
        })
    }

    useEffect(() => {
        if (gameBehaviour) {
            //Check behavior
            switch (parseInt(gameBehaviour)) {
                case Constants.gameBehaviourCustomLayout:
                case Constants.gameBehaviourCustomLayoutFEHost:
                case Constants.gameBehaviourCustomLayoutCallGameLink:
                    if (gamesPlayerId && gameId) {
                        getPlayerGameLink()
                    } else {
                        window.close()
                    }

                    break
                case Constants.gameBehaviourCustomLayoutSingleCall:
                    if (gameId) {
                        getPlayerSingleGameLink()
                    } else {
                        window.close()
                    }

                    break
                case Constants.gameBehaviourOpenLink:
                case Constants.gameBehaviourOpenLinkFEHost:
                    if (user && company && companyGame) {
                        launchGame()
                    } else {
                        window.close()
                    }

                    break
                default:
                    console.log('No known game behavior, not launching')
                    break
            }
        } else {
            window.close()
            //navigate('/', { replace: true })
        }
    }, [])

    return (
        <Stack sx={{ alignItems: 'center', width: '100%', paddingTop: 15, color: 'white' }}>
            <b>Loading...</b>
        </Stack>
    )
}