import React, { useContext, useState, useEffect } from "react";
import { AlertDialogContext, CompanyInfoContext, LoginPopupContext, PopupContext, RegisterPopupContext, UserInfoContext } from '../../App'
import Constants from '../../constants'
import InputTextField from '../../components/textfield/inputTextField'
import BaseButton from "../../components/button/button";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { request } from "../../feathers/feathersClient";
import Utils from "../../utils/utils";
import VerificationView from "../../components/auth/verificationView";
import { useTranslation } from "react-i18next"
import { ChevronRight, Close } from "@mui/icons-material";
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { Box, Button, Divider, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { useTemplateVariables } from "../../utils/templateUtils";
import { LineIcon, TelegramIcon, WhatsappIcon } from "react-share";
import RegisterSideImage from '../../images/svg/registerSideImage.svg'
import useScreenType from "../../hooks/useScreenType";

function Register() {
    const navigate = useNavigate()
    const template = useTemplateVariables()
    const screenType = useScreenType()

    // Context
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setOpenRegister } = useContext(RegisterPopupContext)
    const { setOpenLogin } = useContext(LoginPopupContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const { setPopup } = useContext(PopupContext)
    const { setAlertDialog } = useContext(AlertDialogContext);
    const { t } = useTranslation()
    const client = request({
        setAlertDialog: setAlertDialog,
        setCompanyInfo: setCompanyInfo,
        setUserInfo: setUserInfo,
        navigate: navigate
    })

    const search = useLocation().search;
    const referralCodeFromQueryString = new URLSearchParams(search).get('referralCode') ?? Utils.shared.retrieveLocalStorage(Constants.keys_referral_code)

    // States
    const [values, setValues] = useState({
        contactNumber: '',
        contactNumberPhoneCode: '',
        contactNumberError: '',
        password: '',
        passwordError: '',
        referralCode: referralCodeFromQueryString,
        catpcha: '',
        catpchaError: '',
    })
    const [bots, setBots] = useState(null)

    const [isOTPMode, setIsOTPMode] = useState(false)
    const [otpValues, setOTPValues] = useState({
        verificationCode: '',
        verificationCodeError: '',
        userId: '',
        contactNumber: '',
        smsResendRemaining: 3,
    })

    //Styles
    const styles = {
        modern: {
            desktop: {
                backgroundColor: 'white',
                width: 'calc(60% - 32px)',
                height: 'calc(100% - 32px)',
                position: 'absolute',
                right: 0,
                padding: '16px',
                borderRadius: '32px',
                overflow: 'hidden'
            },
            mobile: {
                backgroundColor: 'white',
                padding: '16px',
                overflow: 'hidden',
                height: '100%'
            }
        }
    }

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    //API methods
    const getCompanyWhatsappLink = (onRetrieved) => {
        var data = {
            company: companyInfo?.company?._id
        }

        if (referralCodeFromQueryString?.length > 0) {
            data['referralCode'] = referralCodeFromQueryString
        }

        client.create('get-company-whatsapp-link', data, null, (response) => {
            onRetrieved(response)
        }, null, false)
    }
    const performRegister = () => {
        if (values.contactNumber.length === 0) {
            setValues(values => ({ ...values, contactNumberError: `${t('AUTH_MOBILE_NUMBER')} ${t('GLOBAL_IS_MANDATORY')}` }))
            return
        }

        if (values.password.length === 0) {
            setValues(values => ({ ...values, passwordError: `${t('AUTH_PASSWORD')} ${t('GLOBAL_IS_MANDATORY')}` }))
            return
        }

        if (!validateCaptcha(values.catpcha)) {
            setValues(values => ({ ...values, catpchaError: 'Captcha does not match' }))
            return
        }

        //Get player role id
        client.find('roles', {
            query: {
                roleType: {
                    '$in': 10
                }
            }
        }, (response) => {
            let playerRoleId = response[0]._id

            //Begin register player
            client.create('create-new-player', {
                contactNumber: `${values.contactNumberPhoneCode}${values.contactNumber}`,
                password: values.password,
                role: playerRoleId,
                affiliateReferralCode: values.referralCode,
                company: companyInfo.company._id,
            }, {
                query: {
                    "$populate": [
                        "company",
                        "role",
                        "rooms",
                        'affiliateUser',
                    ]
                }
            }, (newPlayer) => {
                setOpenRegister(false)

                //Authenticated, store user object
                let user = newPlayer.user
                setUserInfo(user)

                //Store token
                window.localStorage.setItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`, newPlayer.accessToken)

                //Authenticate feathers
                client.reauthenticate(() => {
                    //Navigate to home page
                    Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                        navigate('/', { replace: true })
                    }, true)
                },
                    null,
                    null,
                    true,
                )
            }, (error, shouldKick) => {
                //Check if OTP required
                if (error.code === Constants.feathers_error_code_signup_otp_required) {
                    let data = error?.data

                    setOTPValues(otpValues => ({
                        ...otpValues,
                        verificationCode: '',
                        verificationCodeError: '',
                        userId: data?.userId,
                        contactNumber: data?.contactNumber ?? `${values.contactNumberPhoneCode}${values.contactNumber}`,
                        smsResendRemaining: data?.smsResendLimit ?? 3
                    }))
                    setIsOTPMode(true)
                } else {
                    //Hit error, clear and reload captcha
                    setValues(values => ({ ...values, catpcha: '' }))
                    setValues(values => ({ ...values, catpchaError: '' }))
                    validateCaptcha('')

                    setAlertDialog(alertDialog => ({
                        ...alertDialog,
                        title: error?.name,
                        translationData: error?.data,
                        description: error.message,
                        isOpen: true
                    }))
                }
            })
        })
    }

    //Use Effects
    useEffect(() => {
        window.scrollTo(0, 0)
        loadCaptchaEnginge(6, '#ffffff', '#000000', 'upper')
    }, [])

    if (template.design === Constants.templateDesignModern) {
        const getBotsList = () => {
            var tempBots = [{
                icon: null,
                type: null
            }]
            tempBots = []

            const whatsappIcon = <WhatsappIcon round size={32} />
            const whatsappType = 1
            const telegramIcon = <TelegramIcon round size={32} />
            const telegramType = 2
            const lineIcon = <LineIcon round size={32} />
            const lineType = 3

            const memberRegistrationMethod = companyInfo?.company?.memberRegistrationMethod
            switch (memberRegistrationMethod) {
                case Constants.memberRegistrationMethodsWhatsappOnly:
                case Constants.memberRegistrationMethodsWhatsappOnlyNoReply:
                case Constants.memberRegistrationMethodsWhatsappAndSMSRegister:
                case Constants.memberRegistrationMethodsWhatsappNoReplyAndSMSRegister:
                    tempBots.push({
                        icon: whatsappIcon,
                        type: whatsappType
                    })
                    break
                case Constants.memberRegistrationMethodsTelegramOnly:
                case Constants.memberRegistrationMethodsTelegramAndSMSRegister:
                    tempBots.push({
                        icon: telegramIcon,
                        type: telegramType
                    })
                    break
                case Constants.memberRegistrationMethodsLineOnly:
                case Constants.memberRegistrationMethodsSMSRegisterAndLine:
                    tempBots.push({
                        icon: lineIcon,
                        lineType: lineType
                    })
                    break
                case Constants.memberRegistrationMethodsWhatsAppNoReplyAndTelegram:
                    tempBots.push({
                        icon: whatsappIcon,
                        type: whatsappType
                    })
                    tempBots.push({
                        icon: telegramIcon,
                        type: telegramType
                    })
                    break
                default:
                    break
            }

            //Check if bots empty return null
            if (tempBots.length === 0) {
                return null
            }

            return (
                <Stack spacing='8px'>
                    <Stack direction={'row'} spacing='8px' alignItems={'center'} sx={{
                        height: '22px',
                        width: '100%'
                    }}>
                        <Box sx={{
                            height: '1px',
                            backgroundColor: '#585858',
                            flexGrow: 1
                        }} />
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '22px',
                            color: '#585858'
                        }}>
                            {'or connect with'}
                        </Typography>
                        <Box sx={{
                            height: '1px',
                            backgroundColor: '#585858',
                            flexGrow: 1
                        }} />
                    </Stack>
                    <Stack direction={'row'} spacing='16px' justifyContent={'center'}>
                        {
                            tempBots.map((e) => {
                                return (
                                    <IconButton onClick={() => {
                                        getCompanyWhatsappLink((response) => {
                                            const whatsappLink = response?.whatsappLink ?? ''
                                            const telegramLink = response?.telegramLink ?? ''
                                            const lineLink = response?.lineLink ?? ''

                                            switch (e.type) {
                                                case whatsappType:
                                                    window.open(whatsappLink, '_self')
                                                    break
                                                case telegramType:
                                                    window.open(telegramLink, '_self')
                                                    break
                                                case lineType:
                                                    window.open(lineLink, '_self')
                                                    break
                                                default:
                                                    break
                                            }
                                        })
                                    }}>
                                        {e.icon}
                                    </IconButton>
                                )
                            })
                        }
                    </Stack>
                </Stack>
            )
        }

        return (
            <Box sx={{
                backgroundColor: template.loginPageTheme.secondaryColor,
                height: '100%',
                width: '100%',
                position: 'relative'
            }}>
                <Stack sx={screenType.isMobile ? styles.modern.mobile : styles.modern.desktop} alignItems={'start'} justifyContent={'center'}>
                    {
                        isOTPMode ? (
                            //OTP Mode
                            <VerificationView userId={otpValues.userId} contactNumber={otpValues.contactNumber} remainingResend={otpValues.smsResendRemaining} onVerificationSucceeded={(auth) => {
                                setOpenRegister(false)

                                //Store user
                                setUserInfo(auth.user)

                                //Store token
                                window.localStorage.setItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`, auth.accessToken)

                                //Authenticate feathers
                                client.reauthenticate(
                                    () => {
                                        //Navigate to home page
                                        Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                                            navigate('/', { replace: true })
                                        }, true)
                                    }
                                )
                            }} />
                        ) : (
                            <Stack sx={{
                                padding: '32px',
                                width: 'calc(100% - 64px)'
                            }} spacing='32px'>
                                <Stack>
                                    <Typography sx={{
                                        fontSize: '18px',
                                        fontWeight: '700',
                                        lineHeight: '26px'
                                    }}>
                                        {t('AUTH_REGISTER')}
                                    </Typography>
                                    <Divider sx={{
                                        height: '2px',
                                        backgroundColor: template.loginPageTheme.secondaryColor,
                                        width: '40%'
                                    }} />
                                    <Stack direction={'row'} spacing='4px'>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '22px',
                                            color: '#585858'
                                        }}>
                                            {t('AUTH_HAVE_ACCOUNT')}
                                        </Typography>
                                        <Button sx={{
                                            padding: '0px',
                                            textTransform: 'none'
                                        }} onClick={() => {
                                            setOpenLogin(true)
                                        }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                lineHeight: '22px',
                                                color: '#585858'
                                            }}>
                                                {t('AUTH_LOGIN_HERE')}
                                            </Typography>
                                        </Button>
                                    </Stack>
                                </Stack>
                                <Stack spacing='0px'>
                                    <Stack spacing='8px'>
                                        <InputTextField
                                            value={values.contactNumber}
                                            type='tel'
                                            placeholder="eg. 126668888"
                                            showError={values.contactNumberError.length > 0}
                                            errorText={values.contactNumberError}
                                            onTextChanged={(value) => {
                                                setValues(values => ({ ...values, contactNumber: value }))
                                                setValues(values => ({ ...values, contactNumberError: '' }))
                                            }}
                                            onSubmit={() => {
                                                performRegister()
                                            }}
                                            onPhoneCodeChanged={(value) => {
                                                setValues(values => ({ ...values, contactNumberPhoneCode: value }))
                                                setValues(values => ({ ...values, contactNumberError: '' }))
                                            }} />
                                        <InputTextField
                                            value={values.password}
                                            type='password'
                                            placeholder="6-20 characters"
                                            showError={values.passwordError.length > 0}
                                            errorText={values.passwordError}
                                            onTextChanged={(value) => {
                                                setValues(values => ({ ...values, password: value }))
                                                setValues(values => ({ ...values, passwordError: '' }))
                                            }}
                                            onSubmit={() => {
                                                performRegister()
                                            }} />
                                        <InputTextField
                                            value={values.referralCode}
                                            placeholder={`(${t('AUTH_OPTIONAL')})`}
                                            onTextChanged={(value) => {
                                                setValues(values => ({ ...values, referralCode: value }))
                                            }}
                                            onSubmit={() => {
                                                performRegister()
                                            }} />
                                        <InputTextField
                                            value={values.catpcha}
                                            placeholder={'Enter Captcha As Shown'}
                                            prefix={
                                                <Stack sx={{
                                                    paddingRight: '10px',
                                                    paddingTop: '5px',
                                                    height: '100%'
                                                }} alignItems={'center'} justifyContent={'center'} direction={'row'}>
                                                    <LoadCanvasTemplateNoReload />
                                                </Stack>
                                            }
                                            showError={values.catpchaError.length > 0}
                                            errorText={values.catpchaError}
                                            onTextChanged={(value) => {
                                                setValues(values => ({ ...values, catpcha: value }))
                                                setValues(values => ({ ...values, catpchaError: '' }))
                                            }}
                                            onSubmit={() => {
                                                performRegister()
                                            }} />
                                    </Stack>
                                </Stack>
                                <BaseButton title={t('AUTH_REGISTER')} color={template.loginPageTheme.primaryColor} textColor={template.loginPageTheme.secondaryColor} borderRadius="16px" fullWidth={true} className="" customCSS={''} enableGradient={false} onTap={(event) => {
                                    //Perform login
                                    performRegister()
                                }} />
                                {getBotsList()}
                            </Stack>
                        )
                    }

                    <IconButton onClick={() => {
                        setOpenRegister(false)
                    }} size="small" sx={{
                        backgroundColor: template.loginPageTheme.primaryColor,
                        position: 'absolute',
                        top: 16,
                        right: 16
                    }}>
                        <Close htmlColor={template.loginPageTheme.secondaryColor} fontSize="small" />
                    </IconButton>
                </Stack>
                {
                    screenType.isMobile ? null : (
                        <Stack direction={'row'} sx={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            alignItems: 'end'
                        }}>
                            <img style={{ bottom: 0 }} src={RegisterSideImage} height={'100%'} width={'90%'} />
                        </Stack>
                    )
                }
            </Box>
        )
    } else {
        return isOTPMode ? (
            //OTP Mode
            <div style={{ margin: '20px' }}>
                <VerificationView userId={otpValues.userId} contactNumber={otpValues.contactNumber} remainingResend={otpValues.smsResendRemaining} onVerificationSucceeded={(auth) => {
                    setOpenRegister(false)

                    //Store user
                    setUserInfo(auth.user)

                    //Store token
                    window.localStorage.setItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`, auth.accessToken)

                    //Authenticate feathers
                    client.reauthenticate(
                        () => {
                            //Navigate to home page
                            Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                                navigate('/', { replace: true })
                            }, true)
                        }
                    )
                }} />
            </div>
        ) : (
            //Register Mode
            <div style={{ margin: '20px' }}>
                <InputTextField
                    title={t('AUTH_MOBILE_NUMBER')}
                    value={values.contactNumber}
                    type='tel'
                    placeholder="eg. 126668888"
                    helperText={t('AUTH_MOBILE_NUMBER_HELPER')}
                    titleColor={color}
                    showError={values.contactNumberError.length > 0}
                    errorText={values.contactNumberError}
                    onTextChanged={(value) => {
                        setValues(values => ({ ...values, contactNumber: value }))
                        setValues(values => ({ ...values, contactNumberError: '' }))
                    }}
                    onSubmit={() => {
                        performRegister()
                    }}
                    onPhoneCodeChanged={(value) => {
                        setValues(values => ({ ...values, contactNumberPhoneCode: value }))
                        setValues(values => ({ ...values, contactNumberError: '' }))
                    }} />
                <br />
                <InputTextField
                    title={t('AUTH_PASSWORD')}
                    value={values.password}
                    type='password'
                    placeholder="6-20 characters"
                    helperText={t('AUTH_PASSWORD_HELPER')}
                    titleColor={color}
                    showError={values.passwordError.length > 0}
                    errorText={values.passwordError}
                    onTextChanged={(value) => {
                        setValues(values => ({ ...values, password: value }))
                        setValues(values => ({ ...values, passwordError: '' }))
                    }}
                    onSubmit={() => {
                        performRegister()
                    }} />
                <br />
                <InputTextField
                    title={t('AUTH_REFERRAL_CODE')}
                    value={values.referralCode}
                    placeholder={`(${t('AUTH_OPTIONAL')})`}
                    helperText={t('AUTH_REFERRAL_CODE_HELPER')}
                    titleColor={color}
                    onTextChanged={(value) => {
                        setValues(values => ({ ...values, referralCode: value }))
                    }}
                    onSubmit={() => {
                        performRegister()
                    }} />
                <br />
                <InputTextField
                    title={'Captcha'}
                    value={values.catpcha}
                    placeholder={'Enter Captcha As Shown'}
                    prefix={
                        <Stack sx={{
                            paddingRight: '10px',
                            paddingTop: '5px',
                            height: '100%'
                        }} alignItems={'center'} justifyContent={'center'} direction={'row'}>
                            <LoadCanvasTemplateNoReload />
                        </Stack>
                    }
                    titleColor={color}
                    showError={values.catpchaError.length > 0}
                    errorText={values.catpchaError}
                    onTextChanged={(value) => {
                        setValues(values => ({ ...values, catpcha: value }))
                        setValues(values => ({ ...values, catpchaError: '' }))
                    }}
                    onSubmit={() => {
                        performRegister()
                    }} />
                <br />
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <BaseButton title={t('AUTH_REGISTER')} color='#3c77a3' textColor='#ffffff' fullWidth={false} onTap={(event) => {
                        //Perform register
                        performRegister()
                    }} />
                </div>
                <br />
                <br />
                <h4 style={{ textAlign: 'center', color: color }}>
                    {t('AUTH_HAVE_ACCOUNT')}
                </h4>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Button sx={{ textTransform: 'none', color: color }} endIcon={<ChevronRight htmlColor={color} />} onClick={() => {
                        navigate('/login')
                    }}>
                        {t('AUTH_LOGIN_HERE')}
                    </Button>
                </div>
                <br />
                <br />
            </div>
        )
    }
}

export default Register;