import React, { useContext, useEffect, useState } from "react"
import Constants from '../constants'
import { CompanyInfoContext, SnackBarContext, UserInfoContext, AlertDialogContext, PopupContext } from "../App";
import { Box, Stack, Button, Dialog, AppBar, Toolbar, Typography, IconButton, Divider, Grid } from '@mui/material'
import { AttachMoney, People, MoreHoriz, IosShare, Close, ContentCopy, Download } from '@mui/icons-material'
import BaseButton from "../components/button/button";
import { useNavigate } from "react-router-dom";
import Utils from "../utils/utils";
import { request } from "../feathers/feathersClient";
import { useTranslation } from "react-i18next"
import QRCode from 'qrcode'
import parse from 'html-react-parser'
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import { useTemplateVariables } from "../utils/templateUtils";
import BaseToggleButton from "../components/toggleButton/baseToggleButton";
import LabelDisplayView from "../components/views/labelDisplayView";
import DownlineCommission from "./profile/downlineCommission";
import CommissionList from "./profile/commission/commissionList";
import useScreenType from "../hooks/useScreenType";

function Share() {
    const navigate = useNavigate()
    const template = useTemplateVariables()
    const screenType = useScreenType()

    //Contexts
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const { setSnackBar } = useContext(SnackBarContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setPopup } = useContext(PopupContext)
    const { t } = useTranslation()
    const client = request({
        setAlertDialog: setAlertDialog, 
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })

    // State
    const [playerCommission, setPlayerCommission] = useState(null)
    const [qrCode, setQRCode] = useState(null)
    const [shareObject, setShareObject] = useState(null)
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)

    //Styles
    const styles = {
        shareButton: {
            backgroundColor: '#d67474',
            ':hover': {
                backgroundColor: '#d67474'
            },
            borderRadius: '5px',
            alignItems: 'center',
            justifyItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingY: '15px'
        },
        downlineButton: {
            backgroundColor: '#74bed6',
            ':hover': {
                backgroundColor: '#74bed6'
            },
            borderRadius: '5px',
            alignItems: 'center',
            justifyItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingY: '15px'
        },
        moreButton: {
            backgroundColor: '#59a898',
            ':hover': {
                backgroundColor: '#59a898'
            },
            borderRadius: '5px',
            alignItems: 'center',
            justifyItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingY: '15px'
        },
        gridLayout: {
            padding: '10px',
            display: 'grid',
            gridTemplateColumns: `repeat(3, 1fr)`,
            gridAutoRows: 'auto',
            gridColumnGap: '10px',
            gridRowGap: '10px',
            width: 'calc(100% - 20px)',
            overflowX: 'scroll'
        }
    }

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    var buttonColor = Constants.defaultMainColor
    let otherButtonColor = companyInfo.company.otherButtonColor
    if (otherButtonColor && otherButtonColor.length > 0) {
        buttonColor = otherButtonColor
    }

    var buttonFontColor = Constants.defaultMainColor
    let otherButtonFontColor = companyInfo.company.otherButtonFontColor
    if (otherButtonFontColor && otherButtonFontColor.length > 0) {
        buttonFontColor = otherButtonFontColor
    }

    let bottomButtons = [
        {
            title: t('SHARE_SHARE'),
            icon: (
                <IosShare htmlColor="white" />
            ),
            style: styles.shareButton,
        },
        {
            title: t('SHARE_DOWNLINE'),
            icon: (
                <People htmlColor="white" />
            ),
            style: styles.downlineButton,
        },
        {
            title: t('SHARE_MORE'),
            icon: (
                <MoreHoriz htmlColor="white" />
            ),
            style: styles.moreButton,
        },
    ]

    const link = `https://${window.location.host}/affiliate?referralCode=${userInfo?.referralCode ?? ''}`
    const getQRCode = () => {
        QRCode.toDataURL(link).then(url => {
            setQRCode(url)
        }).catch(err => {
            console.error(err)
        })
    }

    //Component Methods
    const getSocialButtonView = ({ icon, title }) => {
        return (
            <Box sx={{
                padding: '16px 8px',
                borderRadius: '16px',
                backgroundColor: '#F4F6F8',
                minWidth: '84px',
                textTransform: 'none'
            }}>
                <Stack spacing='8px' alignItems={'center'}>
                    {icon}
                    <Typography sx={{
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '16px',
                        color: '#828282'
                    }}>
                        {title}
                    </Typography>
                </Stack>
            </Box>
        )
    }
    const getSelectedTabContent = () => {
        switch (selectedTabIndex) {
            case 0:
                //Referral
                return (
                    <Stack spacing='24px'>
                        <LabelDisplayView title={t('AUTH_REFERRAL_CODE')} value={userInfo?.referralCode ?? '-'} showCopyButton={true} />
                        <LabelDisplayView title={t('AUTH_REFERRAL_LINK')} value={link} showCopyButton={true} />
                        <Stack direction={'row'} alignItems={'center'}>
                            <Stack sx={{ flexGrow: 1 }} alignItems={'start'}>
                                <Stack alignItems={'center'}>
                                    {
                                        qrCode ? (
                                            <img src={qrCode} alt='qrCode' height='80px' width='80px' />
                                        ) : null
                                    }
                                    <div style={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        color: '#828282',
                                        lineHeight: '16px'
                                    }}>{'QR Code'}</div>
                                </Stack>
                            </Stack>
                            <IconButton onClick={() => {
                                var element = document.createElement("a");
                                element.href = qrCode;
                                element.download = "qrCode.jpg";
                                element.click();
                            }}>
                                <Download htmlColor="#828282" />
                            </IconButton>
                        </Stack>
                        <Divider />
                        <Stack spacing='16px'>
                            <Typography sx={{
                                color: '#585858',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '22px'
                            }}>
                                Or Share your favourite offers to your friends!
                            </Typography>
                        </Stack>
                        <Grid container columnGap={'8px'} rowGap={'8px'}>
                            <Grid item>
                                <FacebookShareButton url={link}>
                                    {getSocialButtonView({ icon: <FacebookIcon round size={'32px'} />, title: 'Facebook' })}
                                </FacebookShareButton>
                            </Grid>
                            <Grid item>
                                <WhatsappShareButton url={link}>
                                    {getSocialButtonView({ icon: <WhatsappIcon round size={'32px'} />, title: 'Whatsapp' })}
                                </WhatsappShareButton>
                            </Grid>
                            <Grid item>
                                <TelegramShareButton url={link}>
                                    {getSocialButtonView({ icon: <TelegramIcon round size={'32px'} />, title: 'Telegram' })}
                                </TelegramShareButton>
                            </Grid>
                        </Grid>
                    </Stack>
                )
            case 1:
                //Downline
                return (
                    <DownlineCommission />
                )
            case 2:
                //Commission
                return (
                    <CommissionList />
                )
            default:
                return null
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        const getPlayerCommissionClaim = async () => {
            //Get Player Commission
            if (userInfo) {
                client.create('get-player-commission-claim', {
                    user: userInfo._id,
                    company: companyInfo.company._id
                }, null, (response) => {
                    setPlayerCommission(response)
                    getQRCode()
                })
            }
        }

        getPlayerCommissionClaim()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (playerCommission) {
        const currency = playerCommission.currency ?? ''
        const commissionClaimAmount = playerCommission.commissionClaimAmount ?? 0

        //Check template design
        if (template.design === Constants.templateDesignModern) {
            const tabs = [
                t('MY_REFERRAL'),
                t('MY_DOWNLINE'),
                t('MY_COMMISSION')
            ]

            return (
                <Stack sx={{
                    padding: screenType.isMobile ? '40px 16px' : '40px 24px',
                    borderRadius: screenType.isMobile ? '0px' : '16px',
                    backgroundColor: 'white',
                    boxShadow: '0px 4px 4px 0px rgba(23, 53, 89, 0.16)'
                }} spacing='24px'>
                    <BaseToggleButton items={tabs} defaultValue={selectedTabIndex} onValueChanged={(event, selectedIndex) => {
                        setSelectedTabIndex(selectedIndex)
                    }} />
                    <Divider />
                    {getSelectedTabContent()}
                </Stack>
            )
        } else {
            return (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center' }}>
                    <Box sx={{ margin: '20px', width: '230px' }}>
                        <Box sx={{ marginTop: '10px', height: '230px', backgroundColor: 'white' }}>
                            {
                                qrCode ? (
                                    <img src={qrCode} alt='qrCode' height='230px' width='230px' />
                                ) : null
                            }
                        </Box>
                        <br />
                        <br />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <AttachMoney htmlColor={color} fontSize='large' />
                            <Box sx={{ borderRadius: '5px', backgroundColor: 'rgba(255, 255, 255, 0.7)', marginLeft: '10px', width: '100%', height: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ color: 'black', marginLeft: '10px', marginRight: '10px', width: '100%', fontSize: 18 }}>
                                    <h4>
                                        <b>
                                            {Utils.shared.getAmountString(commissionClaimAmount, currency, companyInfo?.company)}
                                        </b>
                                    </h4>
                                </div>
                            </Box>
                        </div>
                        <br />
                        <BaseButton title={t('SHARE_CLAIM')} color={buttonColor} textColor={buttonFontColor} onTap={() => {
                            if (commissionClaimAmount > 0) {
                                client.create('claim-player-commission', {
                                    company: companyInfo.company._id,
                                    user: userInfo._id
                                }, null, (response) => {
                                    if (response) {
                                        setPlayerCommission(response)

                                        setSnackBar(snackBar => ({
                                            ...snackBar,
                                            title: 'Commission claimed successfully',
                                            isOpen: true
                                        }))
                                    }
                                })
                            }
                        }} />
                        <br />
                        <br />
                        <Stack direction={'row'} spacing={'10px'} style={{ width: '230px' }} >
                            {bottomButtons.map((e, index) => {
                                return (
                                    <div key={`e.title${index}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                        <Button fullWidth={true} sx={e.style} onClick={() => {
                                            switch (index) {
                                                case 0:
                                                    if (navigator.share) {
                                                        let SEOTitle = companyInfo?.company?.SEOTitle ?? ''
                                                        let metaDescription = companyInfo?.company?.metaDescription ?? ''

                                                        navigator.share({
                                                            title: SEOTitle,
                                                            text: metaDescription,
                                                            url: link
                                                        }).catch((error) => {
                                                            console.log(`Failed to share ${link} with ${error}`)
                                                        })
                                                    } else {
                                                        //Fallback for unsupported share api flow
                                                        setShareObject(link)
                                                    }

                                                    break
                                                case 1:
                                                    navigate('/profile/downline')
                                                    break
                                                case 2:
                                                    let shareMoreContent = companyInfo?.company?.shareMoreContent ?? ''

                                                    if (shareMoreContent.length > 0) {
                                                        setPopup(popup => ({
                                                            ...popup,
                                                            children: <Box>
                                                                {parse(shareMoreContent)}
                                                            </Box>,
                                                            showCloseButton: false,
                                                            isOpen: true
                                                        }))
                                                    }

                                                    break
                                                default:
                                                    break
                                            }

                                        }}>
                                            {e.icon}
                                        </Button>
                                        <div style={{ color: color, marginTop: '5px' }} >
                                            {e.title}
                                        </div>
                                    </div>
                                )
                            })}
                        </Stack>
                    </Box>

                    {/* Desktop Share Popup */}
                    <Dialog PaperProps={{
                        style: {
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            boxShadow: 'none',
                            border: `2px solid ${Constants.defaultPopupBorderColor}`,
                        },
                    }} open={shareObject} onClose={() => {
                        setShareObject(null)
                    }}>
                        <AppBar sx={{ position: 'sticky', backgroundColor: 'transparent' }}>
                            <Toolbar variant="dense" disableGutters={true}>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h7" component="div">
                                    <b>{t('SHARE_SHARE')}</b>
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => {
                                        setShareObject(null)
                                    }}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Box style={styles.gridLayout}>
                            <FacebookShareButton url={link}>
                                <FacebookIcon />
                            </FacebookShareButton>
                            <TelegramShareButton url={link}>
                                <TelegramIcon />
                            </TelegramShareButton>
                            <WhatsappShareButton url={link}>
                                <WhatsappIcon />
                            </WhatsappShareButton>
                        </Box>
                        <Box sx={{ padding: '10px' }}>
                            <BaseButton title={t('SHARE_COPY')} onTap={() => {
                                navigator.clipboard.writeText(link)
                                setSnackBar(snackBar => ({
                                    ...snackBar,
                                    title: t('GLOBAL_COPIED'),
                                    isOpen: true
                                }))
                            }} />
                        </Box>
                    </Dialog>
                </div>
            )
        }
    } else {
        return null
    }
}

export default Share