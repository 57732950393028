import client from './feathers'
import axios from "axios"
import Constants from '../constants'
import Utils from '../utils/utils'
import i18n from 'i18next';

class FeathersClient {
    constructor() {
        if (!FeathersClient.instance) {
            this.listenerKeys = {}
            FeathersClient.instance = this
        }

        return FeathersClient.instance
    }

    //LOGOUT
    static logout(onLoggedOut = null) {
        client.logout().finally(() => {
            window.localStorage.removeItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`)

            if (onLoggedOut) {
                onLoggedOut()
            }
        })
    }

    //LISTENERS
    listen(service, event, listenerId = '', listener) {
        //Store the listener callback
        this.listenerKeys[`${service}${event}${listenerId}`] = listener

        //Check if socket has listener of this service
        let eventNames = client.service(service).listeners(event)
        if (eventNames.length === 0) {
            //No listener added yet, begin adding listener
            client.service(service).on(event, (data) => {
                for (const [key, value] of Object.entries(this.listenerKeys)) {
                    if (key.indexOf(`${service}${event}`) > -1) {
                        this.listenerKeys[key](data)
                    }
                }
            })
        }
    }

    monitorConnection(onReconnected, onDisconnected) {
        client.io.on('disconnect', () => {
            onDisconnected()
        })

        client.io.on('reconnect', () => {
            onReconnected()
        })
    }

    //ERROR CHECKING
    static checkErrorCode(error) {
        var tempError = error
        tempError.shouldKick = false

        const code = error.code
        const message = error.message

        switch (code) {
            case 408:
                //Connection timed out
                tempError.message = 'Connection timed out. Please try again.'
                break
            case 403:
            case 405:
            case 445:
                //Need to kick user to home page
                tempError.shouldKick = true
                break
            default:
                break
        }

        return tempError
    }

    static showErrorDialog({ error, setAlertDialog, shouldKick = false, setCompanyInfo = null, setUserInfo = null, navigate = null }) {
        if (shouldKick) {
            FeathersClient.logout(() => {
                window.location.replace(`${window.location.protocol}`)
            })
        } else {
            if (setAlertDialog) {
                setAlertDialog(alertDialog => ({
                    ...alertDialog,
                    title: error?.name,
                    description: error?.message,
                    translationData: error?.data,
                    isOpen: true,
                }))
            }
        }
    }
}

const instance = new FeathersClient()
Object.freeze(instance)

export default instance
export const request = ({ setAlertDialog = null, setCompanyInfo = null, setUserInfo = null, navigate = null }) => {
    const generateHeader = () => {
        let token = Utils.shared.retrieveLocalStorage(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`) ?? ''

        if (token.length > 0) {
            return {
                authorization: token,
                lang: i18n.language
            }
        } else {
            return {
                lang: i18n.language
            }
        }
    }

    return {
        authenticate: function (contactNumber, password, company, setUserInfo, onSuccess, onError, onCompletion = null) {
            const spinner = document.getElementById('spinner')
            spinner.style.display = 'block'

            client.authenticate({
                strategy: 'local-player',
                contactNumber: contactNumber,
                password: password,
                company: company
            }, {
                headers: generateHeader()
            }).then((response) => {
                spinner.style.display = 'none'

                setUserInfo(response.user)

                onSuccess()
            }).catch(
                function (error) {
                    spinner.style.display = 'none'

                    const processedError = FeathersClient.checkErrorCode(error)

                    onError(processedError)
                }
            ).finally(() => {
                if (onCompletion) {
                    onCompletion()
                }
            })
        },

        reauthenticate: function (onSuccess, onError = null, onCompletion = null, showLoading = false) {
            const spinner = document.getElementById('spinner')

            if (showLoading) {
                spinner.style.display = 'block'
            }

            client.authenticate().then((response) => {
                if (spinner && showLoading) {
                    spinner.style.display = 'none'
                }

                if (setUserInfo) {
                    setUserInfo(response.user)
                }

                if (onSuccess) {
                    onSuccess()
                }
            }).catch(
                function (error) {
                    if (spinner && showLoading) {
                        spinner.style.display = 'none'
                    }

                    const processedError = FeathersClient.checkErrorCode(error)
                    const shouldKick = processedError.shouldKick

                    if (processedError.shouldKick) {
                        window.localStorage.removeItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`)
                    }

                    if (setAlertDialog) {
                        FeathersClient.showErrorDialog({
                            error: error,
                            setAlertDialog: setAlertDialog,
                            shouldKick: shouldKick
                        })
                    }

                    if (onError) {
                        onError(processedError)
                    }
                }
            ).finally(() => {
                if (onCompletion) {
                    onCompletion()
                }
            })
        },

        create: function (service, data, params = null, onSuccess = null, onError = null, showLoading = true, onCompletion = null) {
            const spinner = document.getElementById('spinner')

            if (showLoading && spinner) {
                spinner.style.display = 'block'
            }

            // console.log('----------')
            // console.log(`Service - (${service})`)
            // console.log(`Data - (${JSON.stringify(data)})`)
            // console.log(`Param - (${JSON.stringify(params)})`)
            // console.log('----------')

            client.service(service).create(data, {
                headers: generateHeader(),
                ...params
            }).then(
                function (response) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    if (onSuccess) {
                        onSuccess(response)
                    }
                }
            ).catch(
                function (error) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    const processedError = FeathersClient.checkErrorCode(error)
                    const shouldKick = processedError.shouldKick

                    if (setAlertDialog && !onError) {
                        FeathersClient.showErrorDialog({
                            error: error, 
                            setAlertDialog: setAlertDialog, 
                            shouldKick: shouldKick, 
                            setCompanyInfo: setCompanyInfo, 
                            setUserInfo: setUserInfo, 
                            navigate: navigate
                        })
                    }

                    if (onError) {
                        onError(processedError, shouldKick)
                    }
                }
            ).finally(() => {
                if (onCompletion) {
                    onCompletion()
                }
            })
        },
        createREST: function (service, data, onSuccess = null, onError = null, showLoading = true, onCompletion = null) {
            const spinner = document.getElementById('spinner')

            if (showLoading && spinner) {
                spinner.style.display = 'block'
            }

            let url = `${Constants.baseURL}/${service}`
            axios.post(url, data, {
                headers: generateHeader(),
            }).then(
                function (response) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    if (onSuccess && response?.data) {
                        onSuccess(response?.data)
                    }
                }
            ).catch(
                function (axiosError) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    const error = axiosError?.response?.data ?? axiosError
                    const processedError = FeathersClient.checkErrorCode(error)
                    const shouldKick = processedError.shouldKick

                    if (setAlertDialog && !onError) {
                        FeathersClient.showErrorDialog({
                            error: error,
                            setAlertDialog: setAlertDialog,
                            shouldKick: shouldKick
                        })
                    }

                    if (onError) {
                        onError(processedError, shouldKick)
                    }
                }
            ).finally(() => {
                if (onCompletion) {
                    onCompletion()
                }
            })
        },
        patch: function (service, objectId, data, params = null, onSuccess, onError = null, onCompletion = null, showLoading = true) {
            const spinner = document.getElementById('spinner')

            if (showLoading && spinner) {
                spinner.style.display = 'block'
            }

            client.service(service).patch(objectId, data, {
                headers: generateHeader(),
                ...params
            }).then(
                function (response) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    onSuccess(response)
                }
            ).catch(
                function (error) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    const processedError = FeathersClient.checkErrorCode(error)
                    const shouldKick = processedError.shouldKick

                    if (setAlertDialog) {
                        FeathersClient.showErrorDialog({
                            error: error, 
                            setAlertDialog: setAlertDialog, 
                            shouldKick: shouldKick, 
                            setCompanyInfo: setCompanyInfo, 
                            setUserInfo: setUserInfo, 
                            navigate: navigate
                        })
                    }

                    if (onError) {
                        onError(processedError, shouldKick)
                    }
                }
            ).finally(() => {
                if (onCompletion) {
                    onCompletion()
                }
            })
        },
        find: function (service, params, onSuccess, onError = null, onCompletion = null, showLoading = true) {
            const spinner = document.getElementById('spinner')

            if (showLoading && spinner) {
                spinner.style.display = 'block'
            }
            client.service(service).find({
                headers: generateHeader(),
                ...params
            }).then(
                function (response) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    onSuccess(response)
                }
            ).catch(
                function (error) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    const processedError = FeathersClient.checkErrorCode(error)
                    const shouldKick = processedError.shouldKick

                    if (setAlertDialog) {
                        FeathersClient.showErrorDialog({
                            error: error,
                            setAlertDialog: setAlertDialog,
                            shouldKick: shouldKick
                        })
                    }

                    if (onError) {
                        onError(processedError, shouldKick)
                    }
                }
            ).finally(() => {
                if (onCompletion) {
                    onCompletion()
                }
            })
        },
        get: function (service, objectId, params = null, onSuccess, onError = null, onCompletion = null, showLoading = true) {
            const spinner = document.getElementById('spinner')

            if (showLoading && spinner) {
                spinner.style.display = 'block'
            }

            client.service(service).get(objectId, {
                headers: generateHeader(),
                ...params
            }).then(
                function (response) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    onSuccess(response)
                }
            ).catch(
                function (error) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    const processedError = FeathersClient.checkErrorCode(error)
                    const shouldKick = processedError.shouldKick

                    if (setAlertDialog) {
                        FeathersClient.showErrorDialog({
                            error: error,
                            setAlertDialog: setAlertDialog,
                            shouldKick: shouldKick
                        })
                    }

                    if (onError) {
                        onError(processedError, shouldKick)
                    }
                }
            ).finally(() => {
                if (onCompletion) {
                    onCompletion()
                }
            })
        },
        getREST: function (service, objectId, params = null, onSuccess, onError = null, onCompletion = null, showLoading = true) {
            const spinner = document.getElementById('spinner')

            if (showLoading && spinner) {
                spinner.style.display = 'block'
            }

            var url = `${Constants.baseURL}/${service}/${objectId}`

            if (params) {
                let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
                url = `${url}/${queryString}`
            }

            axios.get(url, {
                headers: generateHeader()
            }).then(
                function (response) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    if (onSuccess) {
                        onSuccess(response)
                    }
                }
            ).catch(
                function (axiosError) {
                    if (spinner) {
                        spinner.style.display = 'none'
                    }

                    const error = axiosError?.response?.data ?? axiosError
                    const processedError = FeathersClient.checkErrorCode(error)
                    const shouldKick = processedError.shouldKick

                    if (setAlertDialog && !onError) {
                        FeathersClient.showErrorDialog({
                            error: error,
                            setAlertDialog: setAlertDialog,
                            shouldKick: shouldKick
                        })
                    }

                    if (onError) {
                        onError(processedError, shouldKick)
                    }
                }
            ).finally(() => {
                if (onCompletion) {
                    onCompletion()
                }
            })
        },
        performLogout: function (onLoggedOut) {
            FeathersClient.logout(onLoggedOut)
        }
    }
}
