// API
const appVersion = 4

//Live - gcsys.co:3310
//Staging - gcsys.link:3310
const baseURL = 'https://gcsys.co:3310'
const testingDomain = 'ali88play.com'
const timeoutSeconds = 60

const api_method_get = 'GET'
const api_method_post = 'POST'
const api_authentication = "/authentication"
const api_companies = "/companies"
const api_games = "/games"
const api_upload_games_image = "/upload-games-image"
const api_upload_companies_image = "/upload-companies-image"
const api_upload_company_games_image = "/upload-company-games-image"
const api_upload_company_banks_image = "/upload-company-banks-image"
const api_upload_company_games_sequence_image = "/upload-company-games-sequence-image"
const api_upload_messages_image = "/upload-messages-image"
const api_upload_company_contacts_image = "/upload-company-contacts-image"
const api_upload_bonus_image = '/upload-bonus-image'

const templateDesignClassic = 1
const templateDesignModern = 2

const bucket_path_messages = '/upload/messages/images/'
const bucket_path_bonus = '/upload/bonus/images/'
const bucket_path_games = '/upload/games/images/'
const bucket_path_companies = '/upload/companies/images/'
const bucket_path_company_games_sequence = '/upload/company-games-sequence/images/'
const bucket_path_company_custom_games = '/upload/company-custom-games/images/'

const file_upload_size_limit = 2000000 //2mb

// Feathers Error Code
const feathers_error_code_claim_bonus_before_play = 451
const feathers_error_code_turnover_rollover_error = 453
const feathers_error_code_signup_otp_required = 450
const feathers_error_code_room_not_found = 449
const feathers_error_code_otp_required = 448

// Shared Preference Keys
const keys_app_version = "KEYS_APP_VERSION"
const keys_user_object = "KEYS_USER_OBJECT"
const keys_company_id = "KEYS_COMPANY_ID"
const keys_device_id = "KEYS_DEVICE_ID"
const keys_room_id = "KEYS_ROOM_ID"
const keys_welcome_notice = "KEYS_WELCOME_NOTICE_STOP_SHOWING_TODAY"
const keys_referral_code = "KEYS_REFERRAL_CODE"

const defaultMainColor = '#ffffff'
const defaultNavBarFontColor = '#ffffff'

// Button Color
const defaultButtonColor = '#de8130'
const defaultCancelButtonColor = '#BDBDBD'
const defaultButtonFontColor = '#ffffff'
const buttonRedColor = '#cc4336'
const buttonGreenColor = '#4ba614'
const buttonBlueColor = '#0088CC'

// Popup Color
const defaultPopupBorderColor = '#eb7d00'

// Status Color
const successColor = "#36B37E"
const errorColor = "#FF5630"
const warningColor = "#FFAB00"
const informationColor = "#167FFC"

// DATE FORMAT
const date_format_1 = 'dd-MM-yyyy'
const date_format_2 = 'dd-MM-yyyy hh:mmaa'

// LANGUAGE CODES
const languageCodeEnglish = 'en'
const languageCodeMalay = 'ms'
const languageCodeChinese = 'zh'
const languageCodeBengali = 'bn'
const languageCodeThai = 'th'
const languageCodeKhmer = 'kh'
const languageCodeIndo = 'id'
const languageCodeHindi = 'hi'

// GAME BEHAVIOR
const gameBehaviourCustomLayout = 1
const gameBehaviourOpenLink = 2
const gameBehaviourDownloadAPP = 3
const gameBehaviourCustomLayoutFEHost = 4
const gameBehaviourOpenLinkFEHost = 5
const gameBehaviourCustomLayoutCallGameLink = 6
const gameBehaviourCustomLayoutSingleCall = 7

// PaymentGateway Methods
const paymentGatewayMethodOnlineBanking = 1
const paymentGatewayMethodQRPayment = 2
const paymentGatewayMethodTelco = 3
const paymentGatewayMethodUSDTEtherium = 4
const paymentGatewayMethodUSDTTron = 5

//Transfer Methods
const transferMethodOnlineTransfer = 1;
const transferMethodATM = 2;
const transferMethodCDM = 3;
//Payment gateway transfer method always start after 4 (due to report-deposit-withdrawal) listing
//M3Pay
const transferMethodPaymentGatewayM3Pay = 4;
//VanderPay
const transferMethodPaymentGatewayVanderPay = 5;
//Gtelco (2023/08/14 - Not Longer Support and Available)
//const transferMethodPaymentGatewayGtelco = 6;
//MiiiPay
const transferMethodPaymentGatewayMiiiPay = 7;
//i1Bayar
const transferMethodPaymentGatewayI1Bayar = 8;
//OK2Pay
const transferMethodPaymentGatewayOK2Pay = 9;
//BossCuci (Telco V3)
const transferMethodPaymentGatewayBossCuci = 10;
//FPay
const transferMethodPaymentGatewayFPay = 11;
//AllPay
const transferMethodPaymentGatewayAllPay = 12;
//WinPay
const transferMethodPaymentGatewayWinPay = 13;
//Surepay
const transferMethodPaymentGatewaySurepay = 14;
//SpeedPay
const transferMethodPaymentGatewaySpeedPay = 15;
//Pay2Win
const transferMethodPaymentGatewayPay2Win = 16;
//Capital Pay
const transferMethodPaymentGatewayCapitalPay = 17;
//PNOW
const transferMethodPaymentGatewayPNOW = 18;
//AUDPAY
const transferMethodPaymentGatewayAUDPAY = 19;
//MYPAY88
const transferMethodPaymentGatewayMYPAY88 = 20;
//RAPIDPAY
const transferMethodPaymentGatewayRAPIDPAY = 21;
//PowerPay88
const transferMethodPaymentGatewayPowerPay88 = 22;
//DGPay
const transferMethodPaymentGatewayDGPay = 27;
//Fast2Pay
const transferMethodPaymentGatewayFast2Pay = 28;
//TMTPay
const transferMethodPaymentGatewayTMTPay = 29;
//Capital Pay 2
const transferMethodPaymentGatewayCapitalPay2 = 30;
//UUPay
const transferMethodPaymentGatewayUUPay = 31;
//AMOPay
const transferMethodPaymentGatewayAMOPay = 32;
//GOPay
const transferMethodPaymentGatewayGOPay = 33;
//WZPay
const transferMethodPaymentGatewayWZPay = 34;
//SwiftPay
const transferMethodPaymentGatewaySwiftPay = 35;
//TNG Pin
const transferMethodPaymentGatewayTNGPin = 36;
//VPAY
const transferMethodPaymentGatewayVPAY = 37;
//QRIS
const transferMethodPaymentGatewayQRIS = 38;
//ARANIPAY
const transferMethodPaymentGatewayARANIPAY = 39;
//Vinpay88
const transferMethodPaymentGatewayVinpay88 = 40;

//Transaction status
const transactionStatusPending = 1
const transactionStatusProcessing = 2
const transactionStatusApproved = 3
const transactionStatusRejected = 4
const transactionStatusCancelled = 5

//Transaction type
const transactionTypeDeposit = 1;
const transactionTypeWithdrawal = 2; //Withdrawal display in negative
const transactionTypeBonus = 3;
const transactionTypeManual = 4;
const transactionTypeRebate = 5;
const transactionTypeForfeited = 6; //Forfeited display in negative
const transactionTypeCommission = 7;
const transactionTypeLossCredit = 8;
const transactionTypeBankTransfer = 9;
const transactionTypeDepositFee = 10; //Deposit Fee display in negative

//Commission Payout Status
const commissionPayoutStatusPending = 1
const commissionPayoutStatusPaid = 2
const commissionPayoutStatusClaim = 3

//Bonus type
const bonusTypeDeposit = 1;
const bonusTypeFree = 2;
const bonusTypeCustom = 3;
const bonusTypeShare = 4;

//Bonus Input type
const bonusInputTypePercentage = 1;
const bonusInputTypeFixedAmount = 2;
const bonusInputTypeRandom = 3;

//Bonus Platform
const bonusPlatformWeb = 1;
const bonusPlatformApp = 2;
const bonusPlatformAll = 3; //Default

//Bonus Angpow Display Type
const bonusImageTypeNormal = 1;
const bonusImageTypeLong = 2;

//Member Registration Methods
//Bot On
const memberRegistrationMethodsWhatsappOnly = 1;
//Bot Off
const memberRegistrationMethodsWhatsappOnlyNoReply = 2;
//Bot On
const memberRegistrationMethodsWhatsappAndSMSRegister = 3; //Default
//Bot Off
const memberRegistrationMethodsWebRegisterOnlyNoVerify = 4;
//Telegram Bot
const memberRegistrationMethodsTelegramOnly = 5;
//Line Bot
const memberRegistrationMethodsLineOnly = 6;
//WhatsApp No Reply and Telegram
const memberRegistrationMethodsWhatsAppNoReplyAndTelegram = 7;
// Telegram and SMS
const memberRegistrationMethodsTelegramAndSMSRegister = 8;
const memberRegistrationMethodsSMSRegisterAndLine = 9;
//Whatsapp No Reply + SMS Register
const memberRegistrationMethodsWhatsappNoReplyAndSMSRegister = 10;

//Deposit Fast Keys
const defaultFastKeys = [
    { amount: 30 },
    { amount: 50 },
    { amount: 100 },
    { amount: 200 },
    { amount: 300 },
    { amount: 500 }
]

//Country Code
const defaultCountryCode = 'MY'; //Malaysia
const countryCodeTH = 'TH'; //Thailand
const countryCodeVN = 'VN'; //Vietnam
const countryCodeSG = 'SG'; //Singapore
const countryCodeID = 'ID'; //Indonesia
const countryCodeUS = 'US'; //United States
const countryCodeKH = 'KH'; //Cambodia
const countryCodeBD = 'BD'; //Bangladesh
const countryCodeIN = 'IN'; //India
const countryCodeMM = 'MM'; //Myanmar
const countryCodeAU = 'AU'; //Australia
const countryCodeBN = 'BN'; //Brunei

//Background Image Type
const backgroundTypeImage = 1;
const backgroundTypeColor = 2;

const languages = [
    {
        code: languageCodeEnglish,
        title: 'English',
        icon: 'flag_uk.png',
    },
    {
        code: languageCodeMalay,
        title: 'Bahasa Melayu',
        icon: 'flag_my.png',
    },
    {
        code: languageCodeChinese,
        title: '中文',
        icon: 'flag_china.png',
    },
    {
        code: languageCodeBengali,
        title: 'Bengali',
        icon: 'flag_bn.png',
    },
    {
        code: languageCodeThai,
        title: 'Thai',
        icon: 'flag_th.png',
    },
    {
        code: languageCodeKhmer,
        title: 'Khmer',
        icon: 'flag_kh.png',
    },
    {
        code: languageCodeIndo,
        title: 'Indonesia',
        icon: 'flag_id.png',
    },
    {
        code: languageCodeHindi,
        title: 'Hindi',
        icon: 'flag_hi.png',
    }
]

module.exports = {
    appVersion,

    baseURL,
    testingDomain,
    timeoutSeconds,

    api_method_get,
    api_method_post,

    api_authentication,
    api_companies,
    api_games,
    api_upload_games_image,
    api_upload_companies_image,
    api_upload_company_games_image,
    api_upload_company_banks_image,
    api_upload_company_games_sequence_image,
    api_upload_messages_image,
    api_upload_company_contacts_image,
    api_upload_bonus_image,

    templateDesignClassic,
    templateDesignModern,

    bucket_path_messages,
    bucket_path_bonus,
    bucket_path_games,
    bucket_path_companies,
    bucket_path_company_games_sequence,
    bucket_path_company_custom_games,

    file_upload_size_limit,

    feathers_error_code_claim_bonus_before_play,
    feathers_error_code_turnover_rollover_error,
    feathers_error_code_signup_otp_required,
    feathers_error_code_room_not_found,
    feathers_error_code_otp_required,

    keys_app_version,
    keys_user_object,
    keys_company_id,
    keys_device_id,
    keys_room_id,
    keys_welcome_notice,
    keys_referral_code,

    defaultMainColor,
    defaultNavBarFontColor,
    defaultButtonColor,
    defaultCancelButtonColor,
    defaultButtonFontColor,
    buttonRedColor,
    buttonGreenColor,
    buttonBlueColor,
    defaultPopupBorderColor,

    successColor,
    errorColor,
    warningColor,
    informationColor,

    date_format_1,
    date_format_2,

    languageCodeEnglish,
    languageCodeMalay,
    languageCodeChinese,
    languageCodeBengali,
    languageCodeThai,
    languageCodeKhmer,
    languageCodeIndo,

    gameBehaviourCustomLayout,
    gameBehaviourOpenLink,
    gameBehaviourDownloadAPP,
    gameBehaviourCustomLayoutFEHost,
    gameBehaviourOpenLinkFEHost,
    gameBehaviourCustomLayoutCallGameLink,
    gameBehaviourCustomLayoutSingleCall,

    paymentGatewayMethodOnlineBanking,
    paymentGatewayMethodQRPayment,
    paymentGatewayMethodTelco,
    paymentGatewayMethodUSDTEtherium,
    paymentGatewayMethodUSDTTron,

    transferMethodOnlineTransfer, transferMethodATM, transferMethodCDM, transferMethodPaymentGatewayM3Pay, transferMethodPaymentGatewayVanderPay,
    transferMethodPaymentGatewayMiiiPay, transferMethodPaymentGatewayI1Bayar, transferMethodPaymentGatewayOK2Pay,
    transferMethodPaymentGatewayBossCuci, transferMethodPaymentGatewayFPay, transferMethodPaymentGatewayAllPay, transferMethodPaymentGatewayWinPay,
    transferMethodPaymentGatewaySurepay, transferMethodPaymentGatewaySpeedPay, transferMethodPaymentGatewayPay2Win, transferMethodPaymentGatewayCapitalPay,
    transferMethodPaymentGatewayPNOW, transferMethodPaymentGatewayAUDPAY, transferMethodPaymentGatewayMYPAY88, transferMethodPaymentGatewayRAPIDPAY,
    transferMethodPaymentGatewayPowerPay88, transferMethodPaymentGatewayDGPay, transferMethodPaymentGatewayFast2Pay, transferMethodPaymentGatewayTMTPay,
    transferMethodPaymentGatewayCapitalPay2, transferMethodPaymentGatewayUUPay, transferMethodPaymentGatewayAMOPay, transferMethodPaymentGatewayGOPay,
    transferMethodPaymentGatewayWZPay, transferMethodPaymentGatewaySwiftPay, transferMethodPaymentGatewayTNGPin, transferMethodPaymentGatewayVPAY,
    transferMethodPaymentGatewayQRIS, transferMethodPaymentGatewayARANIPAY, transferMethodPaymentGatewayVinpay88,

    transactionStatusPending,
    transactionStatusProcessing,
    transactionStatusApproved,
    transactionStatusRejected,
    transactionStatusCancelled,

    transactionTypeDeposit, transactionTypeWithdrawal, transactionTypeBonus, transactionTypeManual, transactionTypeRebate, transactionTypeForfeited,
    transactionTypeCommission, transactionTypeLossCredit, transactionTypeBankTransfer, transactionTypeDepositFee,

    commissionPayoutStatusPending, commissionPayoutStatusPaid, commissionPayoutStatusClaim,

    bonusTypeDeposit, bonusTypeFree, bonusTypeCustom, bonusTypeShare,

    bonusInputTypePercentage, bonusInputTypeFixedAmount, bonusInputTypeRandom,

    bonusPlatformWeb, bonusPlatformApp, bonusPlatformAll,

    bonusImageTypeNormal, bonusImageTypeLong,

    memberRegistrationMethodsWhatsappOnly, memberRegistrationMethodsWhatsappOnlyNoReply, memberRegistrationMethodsWhatsappAndSMSRegister,
    memberRegistrationMethodsWebRegisterOnlyNoVerify, memberRegistrationMethodsTelegramOnly, memberRegistrationMethodsLineOnly, memberRegistrationMethodsWhatsAppNoReplyAndTelegram,
    memberRegistrationMethodsTelegramAndSMSRegister, memberRegistrationMethodsSMSRegisterAndLine, memberRegistrationMethodsWhatsappNoReplyAndSMSRegister,

    defaultFastKeys,

    defaultCountryCode, countryCodeTH, countryCodeVN, countryCodeSG, countryCodeID, countryCodeUS, countryCodeKH, countryCodeBD, countryCodeIN, countryCodeMM,
    countryCodeAU, countryCodeBN,

    backgroundTypeImage, backgroundTypeColor,

    languages
}