import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n'

import Home from './pages/home/home';
import Share from './pages/share';
import Settings from './pages/profile/settings'
import Login from './pages/auth/login';
import RequireAuth from './components/auth/requireAuth';
import AutoLogin from './pages/auth/autoLogin';
import WhatsAppRegister from './pages/auth/whatsAppRegister';
import RegisterWithAffiliate from './pages/auth/registerWithAffiliate';
import GameLauncher from './pages/game/gameLauncher';

const ContactUs = lazy(() => import('./pages/profile/contactUs'));
const BankAccount = lazy(() => import('./pages/profile/bankAccount/bankAccount'));
const Games = lazy(() => import('./pages/game/games'));
const DownlineCommission = lazy(() => import('./pages/profile/downlineCommission'));
const CommissionDetails = lazy(() => import('./pages/profile/commissionDetails'));
const Deposit = lazy(() => import('./pages/deposit/deposit'))
const Withdrawal = lazy(() => import('./pages/withdrawal/withdrawal'))
const History = lazy(() => import('./pages/history/history'))
const Profile = lazy(() => import('./pages/profile/profile'))
const Chat = lazy(() => import('./pages/chat/chat'))
const Promotions = lazy(() => import('./pages/promotion/promotions'))
const ChangePassword = lazy(() => import('./pages/profile/changePassword'))
const ForgotPassword = lazy(() => import('./pages/auth/forgotPassword'))
const Register = lazy(() => import('./pages/auth/register'))

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="deposit" element={
            <RequireAuth>
              <Deposit />
            </RequireAuth>
          } />
          <Route path="withdrawal" element={
            <RequireAuth>
              <Withdrawal />
            </RequireAuth>
          } />
          <Route path="share" element={
            <RequireAuth>
              <Share />
            </RequireAuth>
          } />
          <Route path="history" element={
            <RequireAuth>
              <History />
            </RequireAuth>
          } />
          <Route path="profile" element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          } />
          <Route path="games" element={
            <RequireAuth>
              <Games />
            </RequireAuth>
          } />
          <Route path='profile/bankAccount' element={
            <RequireAuth>
              <BankAccount />
            </RequireAuth>
          } />
          <Route path='profile/settings' element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          } />
          <Route path='profile/contactUs' element={
            <RequireAuth>
              <ContactUs />
            </RequireAuth>
          } />
          <Route path='profile/settings/changePassword' element={
            <RequireAuth>
              <ChangePassword />
            </RequireAuth>
          } />
          <Route path='profile/downline' element={
            <RequireAuth>
              <DownlineCommission />
            </RequireAuth>
          } />
          <Route path='profile/downline/commissionDetails' element={
            <RequireAuth>
              <CommissionDetails />
            </RequireAuth>
          } />
          <Route path="playerlogin/:uniqueId" element={
            <AutoLogin isAdminLogin={false} />
          } />
          <Route path="adminlogin/:uniqueId" element={
            <AutoLogin isAdminLogin={true} />
          } />
          <Route path="chat" element={<Chat />} />
          <Route path='login' element={<Login />} />
          <Route path='login/forgotPassword' element={<ForgotPassword />} />
          <Route path='register' element={<Register />} />
          <Route path='affiliate' element={<RegisterWithAffiliate />} />
          <Route path='whatsAppRegister' element={<WhatsAppRegister />} />
          <Route path='gameLauncher' element={<GameLauncher />} />
          <Route path='promotions' element={<Promotions />} />
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
