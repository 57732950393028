import { useNavigate } from "react-router-dom"
import { useTemplateVariables } from "../../../utils/templateUtils"
import { AlertDialogContext, CompanyInfoContext, SnackBarContext, UserInfoContext } from "../../../App"
import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { request } from "../../../feathers/feathersClient"
import { commissionPayoutStatusPaid, date_format_2, templateDesignModern } from "../../../constants"
import Utils from "../../../utils/utils"
import { Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled, tableCellClasses } from "@mui/material"
import BaseButton from "../../../components/button/button"
import useScreenType from "../../../hooks/useScreenType"

export default function CommissionList() {
    const navigate = useNavigate()
    const template = useTemplateVariables()
    const screenType = useScreenType()

    //Pagination Constants
    const rowsPerPage = 10

    // Context
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setSnackBar } = useContext(SnackBarContext)
    const { t } = useTranslation()
    const client = request({
        setAlertDialog: setAlertDialog, 
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })

    //States
    const [result, setResult] = useState(null)
    const [playerProfile, setPlayerProfile] = useState(null)

    //Commission
    const [commissionList, setCommissionList] = useState([])
    const [commissionPagination, setCommissionPagination] = useState(null)
    const [commissionPage, setCommissionPage] = useState(0)
    const commissionPaginationRef = useRef(commissionPagination)
    const commissionListRef = useRef(commissionList)

    const isPlayerSiteDisplayCurrency = companyInfo?.company?.isPlayerSiteDisplayCurrency ?? true
    const currencyCode = companyInfo?.company?.currencyCode ?? ''
    const [playerCommission, setPlayerCommission] = useState(null)

    const rebateClaimAmount = playerProfile?.rebateClaimAmount ?? 0

    //API METHODS
    const getPlayerProfile = () => {
        //Get Player Profile
        if (userInfo) {
            client.create('get-player-profile', {
                user: userInfo._id
            }, null, (response) => {
                setPlayerProfile(response)
            })
        }
    }
    const getPlayerDownlineCommission = () => {
        client.create('get-player-downline-commission', {
            company: companyInfo.company._id,
            user: userInfo._id
        }, null, (response) => {
            setResult(response)
            setCommissionList(response?.commissionPayouts?.data ?? [])
            setCommissionPagination(response?.commissionPayouts)
        })
    }
    const getPlayerCommissionClaim = async () => {
        //Get Player Commission
        if (userInfo) {
            client.create('get-player-commission-claim', {
                user: userInfo._id,
                company: companyInfo.company._id
            }, null, (response) => {
                setPlayerCommission(response)
            })
        }
    }
    const getCommissionList = (index, isRefresh = false) => {
        if ((index * rowsPerPage) >= commissionList.length || isRefresh) {
            client.find('commission-payout', {
                query: {
                    '$skip': (index * rowsPerPage),
                    '$limit': rowsPerPage,
                    'company': companyInfo.company?._id,
                    'user': userInfo?._id,
                    'status': {
                        '$in': [
                            commissionPayoutStatusPaid
                        ]
                    },
                    '$populate': [
                        'user',
                        'company',
                        'commission',
                    ]
                }
            }, (response) => {
                let list = response?.data ?? []
                if (isRefresh) {
                    setCommissionList([...[], ...list])
                    commissionListRef.current = [...[], ...list]
                } else {
                    setCommissionList(commissionList => [...commissionList, ...list])
                    commissionListRef.current = [...commissionList, ...list]
                }

                setCommissionPagination(response)
                commissionPaginationRef.current = response

                setCommissionPage(index)
            })
        } else {
            setCommissionPage(index)
        }
    }

    //DATAGRID METHODS
    const getRows = () => {
        return commissionList?.slice(commissionPage * rowsPerPage, commissionPage * rowsPerPage + rowsPerPage)?.map((e, index) => {
            let commissionPaidDateTime = e.commissionPaidDateTime ? Utils.shared.convertDateToString(Date.parse(e.commissionPaidDateTime), date_format_2) : '-'
            let commissionPayoutId = e?.commissionPayoutId ?? '-'
            let currencyCode = e?.company?.currencyCode
            let commissionPaid = Utils.shared.getAmountString(e?.commissionPaid, currencyCode, companyInfo?.company)

            return (
                <StyledTableRow key={`row${index}`} hover={true} onClick={() => {
                    navigate(`/profile/downline/commissionDetails?id=${e?._id}`)
                }}>
                    <StyledTableCell>{commissionPaidDateTime}</StyledTableCell>
                    <StyledTableCell>{commissionPayoutId}</StyledTableCell>
                    <StyledTableCell>{commissionPaid}</StyledTableCell>
                </StyledTableRow>
            )
        }) ?? []
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            color: template.design === templateDesignModern ? '#828282' : template.mainFontColor,
            fontSize: template.design === templateDesignModern ? 12 : 14,
            fontWeight: 'bold',
            backgroundColor: template.design === templateDesignModern ? '#E8E8E8' : null
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            color: template.design === templateDesignModern ? '#2E2E2E' : template.mainFontColor,
        },
        [`&.${tableCellClasses.footer}`]: {
            color: template.mainFontColor,
            fontSize: 14,
            fontWeight: 'bold'
        },
    }))
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)'
        }
    }))

    //Component Methods
    const getDetailsView = ({ title, value }) => {
        return (
            <Stack sx={{
                backgroundColor: template.mainFontColor,
                borderRadius: '16px',
                padding: '16px',
                width: '100%'
            }}>
                <Typography sx={{
                    fontSize: '10px',
                    fontWeight: '600',
                    lineHeight: '120%',
                    textTransform: 'uppercase',
                    color: 'white'
                }}>
                    {title}
                </Typography>
                <Typography sx={{
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: '26px',
                    color: template.navigationFontColor
                }}>
                    {value}
                </Typography>
            </Stack>
        )
    }
    const getTotalCommissionView = () => {
        const commissionClaimAmount = playerCommission?.commissionClaimAmount ?? 0

        return (
            <Stack sx={{
                backgroundColor: template.mainFontColor,
                borderRadius: '16px',
                padding: '16px',
                width: 'calc(100% - 32px)'
            }}>
                <Stack direction={'row'} alignItems={'start'} spacing={'2px'} sx={{
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: '26px',
                    color: template.navigationFontColor,
                }}>
                    {
                        (isPlayerSiteDisplayCurrency) ? (
                            <div style={{
                                fontSize: '12px',
                                fontWeight: '600',
                                lineHeight: '18px',
                                color: template.navigationFontColor,
                                textTransform: 'uppercase'
                            }}>{currencyCode}</div>
                        ) : null
                    }
                    <div>{Utils.shared.getAmountString(commissionClaimAmount)}</div>
                </Stack>
                <Stack direction={'row'} spacing='12px'>
                    <Typography sx={{
                        fontSize: '10px',
                        fontWeight: '600',
                        lineHeight: '120%',
                        textTransform: 'uppercase',
                        color: 'white',
                        flexGrow: 1
                    }}>
                        {'Total Commission'}
                    </Typography>
                    <BaseButton title={t('SHARE_CLAIM')} fontSize="6px" borderRadius="8px" padding='4px 8px' lineHeight={'120%'} fullWidth={false} enableGradient={false} onTap={() => {
                        //Only allow claim if amount more than zero
                        if (commissionClaimAmount > 0) {
                            client.create('claim-player-commission', {
                                company: companyInfo.company._id,
                                user: userInfo._id
                            }, null, (response) => {
                                if (response) {
                                    setPlayerCommission(response)

                                    setSnackBar(snackBar => ({
                                        ...snackBar,
                                        title: 'Commission claimed successfully',
                                        isOpen: true
                                    }))
                                }
                            })
                        }
                    }} />
                </Stack>
            </Stack>
        )
    }
    const getListView = () => {
        let backgroundColor = template.design === templateDesignModern ? 'white' : 'black'
        let border = template.design === templateDesignModern ? null : '1px solid white'
        let borderRadius = template.design === templateDesignModern ? '0px' : null
        let elevation = template.design === templateDesignModern ? 0 : 1

        return (
            <Paper elevation={elevation} sx={{ width: '100%', overflow: 'hidden', backgroundColor: backgroundColor, border: border, borderRadius: borderRadius }}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{'COMMISSION DATE & TIME'}</StyledTableCell>
                                <StyledTableCell>TRANSACTION ID</StyledTableCell>
                                <StyledTableCell>COMMISSION</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(commissionList?.length ?? 0) > 0 ? getRows() : <StyledTableRow key='norecordrow'>
                                <StyledTableCell component="th" scope="row" align="center" colSpan={3}>
                                    No Record Found
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    count={commissionPagination?.total ?? 0}
                    rowsPerPage={rowsPerPage}
                    component="div"
                    sx={{ color: template.mainFontColor }}
                    backIconButtonProps={{
                        sx: {
                            svg: {
                                color: template.mainFontColor
                            }
                        }
                    }}
                    nextIconButtonProps={{
                        sx: {
                            svg: {
                                color: template.mainFontColor
                            }
                        }
                    }}
                    page={commissionPage}
                    onPageChange={(event, newPage) => {
                        getCommissionList(newPage, false)
                    }}
                />
            </Paper>
        )
    }

    useEffect(() => {
        if (userInfo) {
            getPlayerDownlineCommission()
            getPlayerProfile()
            getPlayerCommissionClaim()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Stack spacing={'24px'}>
            {
                screenType.isMobile ? (
                    <Stack sx={{
                        borderRadius: '16px',
                        overflow: 'hidden',
                        backgroundColor: template.mainFontColor
                    }}>
                        {getTotalCommissionView()}
                        <Divider sx={{ backgroundColor: '#D5D5D5', margin: '0px 16px' }} />
                        <Stack direction={'row'} spacing='8px'>
                            {getDetailsView({ title: t('DOWNLINE_DIRECT_DOWNLINE'), value: result?.directDownline ?? 0 })}
                            {getDetailsView({ title: t('DOWNLINE_TOTAL_TEAM'), value: result?.totalTeam ?? 0 })}
                        </Stack>
                    </Stack>
                ) : (
                    <Stack direction={'row'} spacing='8px'>
                        {getTotalCommissionView()}
                        {getDetailsView({ title: t('DOWNLINE_DIRECT_DOWNLINE'), value: result?.directDownline ?? 0 })}
                        {getDetailsView({ title: t('DOWNLINE_TOTAL_TEAM'), value: result?.totalTeam ?? 0 })}
                    </Stack>
                )
            }
            {getListView()}
        </Stack >
    )
}