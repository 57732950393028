import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CompanyInfoContext, UserInfoContext } from "../../../App";
import { request } from "../../../feathers/feathersClient";
import GameCategoryView from "../gameCategoryView/gameCategoryView";
import GameListView from "../gameListView/gameListView";
import Constants from '../../../constants'
import { useTemplateVariables } from "../../../utils/templateUtils";
import { Box, Stack } from "@mui/material";
import useScreenType from "../../../hooks/useScreenType";

export default function GameContainerView() {
    let navigate = useNavigate()

    //Contexts
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const client = request({
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })
    const template = useTemplateVariables()
    const screenType = useScreenType()

    //States
    const [selectedGameCategoryKey, setSelectedGameCategoryKey] = useState(null)
    const [result, setResult] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [categoryWithGames, setCategoryWithGames] = useState([])

    const gameCategories = result?.gameCategories ?? []
    const companyGames = result?.companyGames ?? []
    const companyGamesSequence = result?.companyGamesSequence ?? []
    const gamesBehaviourListing = result?.gamesBehaviourListing ?? []

    //Styles
    const styles = {
        title: {
            fontSize: screenType.isMobile ? '18px' : '28px',
            fontWeight: '700',
            lineHeight: '36px',
            color: template.mainFontColor
        }
    }
    const getTopPadding = () => {
        if (template.design === Constants.templateDesignModern) {
            return '32px'
        }

        return null
    }

    //API Method
    const getPlayerSiteCompanyGame = () => {
        setIsLoading(true)

        client.createREST('get-player-site-company-game', {
            secretKey: 'thebestsecretkey',
            company: companyInfo?.company?._id
        }, (response) => {
            setResult(response)

            let customCategories = response?.companyCustomGames?.map((e) => {
                return {
                    key: e?._id,
                    value: e?.name,
                    companyGames: e?.companyGames,
                    isCustom: true,
                    image: e?.image
                }
            }) ?? []
            let gameCategories = response?.gameCategories ?? []
            let tempGameCategories = customCategories.concat(...gameCategories)

            //Filter out and store only categories with games
            let tempCategoryWithGames = tempGameCategories?.filter((e) => {
                let key = e?.key
                let companyGames = e?.companyGames ?? []
                let isCustom = e?.isCustom ?? false

                //First check if is custom category
                if (isCustom) {
                    //Check if has game
                    if (companyGames.length > 0) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    //Check if has game
                    let gameCategoryIndex = response?.companyGames?.findIndex((game) => {
                        let hasGame = (game?.game?.gameCategory?.indexOf(key) > -1)
                        let gameHidden = response?.companyGamesSequence?.find((e) => {
                            return e.companyGame?.id === game?.id && e.gameCategory === key
                        })?.isHidden ?? false

                        return hasGame && (!gameHidden)
                    }) ?? -1

                    if (gameCategoryIndex > -1) {
                        return true
                    } else {
                        //No game in this category, so hidden
                        return false
                    }
                }
            }) ?? []

            setCategoryWithGames(tempCategoryWithGames)

            //Set default as first if null
            if (!selectedGameCategoryKey) {
                if (tempCategoryWithGames.length > 0) {
                    setSelectedGameCategoryKey(tempCategoryWithGames[0]?.key)
                }
            }
        }, null, false, () => {
            setIsLoading(false)
        })
    }

    //Use Effect
    useEffect(() => {
        getPlayerSiteCompanyGame()
    }, [])

    return (
        <Stack sx={{
            paddingTop: getTopPadding()
        }}>
            <GameCategoryView key={'gameCategoryView'} templateDesign={template.design} gameCategories={categoryWithGames} companyGames={companyGames} companyGameSequence={companyGamesSequence} isLoading={isLoading} selectedKey={selectedGameCategoryKey} onSelected={(key) => {
                setSelectedGameCategoryKey(key)
            }} />
            <GameListView key={'gameListView'} templateDesign={template.design} gameCategories={categoryWithGames} selectedGameCategoryKey={selectedGameCategoryKey} companyGames={companyGames} companyGamesSequence={companyGamesSequence} gamesBehaviourListing={gamesBehaviourListing} isLoading={isLoading} />
        </Stack>
    )
}