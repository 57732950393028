import React, { useContext } from "react"
import BannerSlider from '../../components/bannerSlider/bannerSlider'
import BonusListView from '../../components/bonusListView/bonusListView'
import { CompanyInfoContext, UserInfoContext } from '../../App'
import Constants from '../../constants'
import BalanceView from "../../components/balanceView/balanceView"
import BannerStaticView from "../../components/bannerStaticView/bannerStaticView"
import LiveTransactionsView from "../../components/liveTransactionsView/liveTransactionsView"
import BannerVerticalStack from "../../components/bannerVerticalStack/bannerVerticalStack"
import { Box, Divider, Stack } from "@mui/material"
import JackpotView from "../../components/jackpotView/jackpotView"
import GameContainerView from "../../components/game/gameContainerView/gameContainerView"
import LoginButton from "../../components/button/loginButton"
import RegisterButton from "../../components/button/registerButton"
import RunningTextBar from "../../components/runningText/runningTextBar"
import FooterView from "../../components/footerView/footerView"
import DepositButton from "../../components/button/depositButton"
import WithdrawalButton from "../../components/button/withdrawalButton"
import useScreenType from "../../hooks/useScreenType"
import CustomFooterView from "../../components/footerView/customFooterView"

function HomeV1({ gameCategories, contentHeight, onDepositTapped, onWithdrawalTapped }) {
    //Contexts
    const { companyInfo } = useContext(CompanyInfoContext)
    const { userInfo } = useContext(UserInfoContext)
    const screenType = useScreenType()
    const templateDesign = companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic

    // Login button theme
    var loginButtonColor = companyInfo.company?.loginButtonColor ?? ''
    if (loginButtonColor.length === 0) {
        loginButtonColor = Constants.defaultButtonColor
    }

    var loginButtonFontColor = companyInfo.company?.loginButtonFontColor ?? ''
    if (loginButtonFontColor.length === 0) {
        loginButtonFontColor = Constants.defaultButtonFontColor
    }

    // Register button theme
    var registerButtonColor = companyInfo.company?.registerButtonColor ?? ''
    if (registerButtonColor.length === 0) {
        registerButtonColor = Constants.defaultButtonColor
    }

    var registerButtonFontColor = companyInfo.company?.registerButtonFontColor ?? ''
    if (registerButtonFontColor.length === 0) {
        registerButtonFontColor = Constants.defaultButtonFontColor
    }

    // Deposit button theme
    var depositButtonColor = companyInfo.company?.depositButtonColor ?? ''
    if (depositButtonColor.length === 0) {
        depositButtonColor = Constants.defaultButtonColor
    }

    var depositButtonFontColor = companyInfo.company?.depositButtonFontColor ?? ''
    if (depositButtonFontColor.length === 0) {
        depositButtonFontColor = Constants.defaultButtonFontColor
    }

    // Withdrawal button theme
    var withdrawalButtonColor = companyInfo.company?.withdrawalButtonColor ?? ''
    if (withdrawalButtonColor.length === 0) {
        withdrawalButtonColor = Constants.defaultButtonColor
    }

    var withdrawalButtonFontColor = companyInfo.company?.withdrawalButtonFontColor ?? ''
    if (withdrawalButtonFontColor.length === 0) {
        withdrawalButtonFontColor = Constants.defaultButtonFontColor
    }

    const getLeftButton = () => {
        if (userInfo) {
            return (
                <DepositButton />
            )
        } else {
            return (
                <LoginButton />
            )
        }
    }

    const getRightButton = () => {
        if (userInfo) {
            return (
                <WithdrawalButton />
            )
        } else {
            return (
                <RegisterButton />
            )
        }
    }

    //Display based on platform
    if (templateDesign === Constants.templateDesignModern) {
        return (
            <Stack sx={{ minHeight: contentHeight, width: '100%' }}>
                <BannerSlider />
                <RunningTextBar />
                {
                    (screenType.isMobile || screenType.isTablet) ? (
                        <Box sx={{
                            borderRadius: '16px',
                            margin: '0px 16px',
                            paddingTop: '4px',
                            boxShadow: '0px 4px 4px 0px rgba(23, 53, 89, 0.16)',
                            overflow: 'hidden'
                        }}>
                            {
                                (userInfo) ? (
                                    <BalanceView />
                                ) : (
                                    <Stack direction={'row'} spacing={'12px'} sx={{ padding: '12px' }} >
                                        <LoginButton />
                                        <RegisterButton />
                                    </Stack>
                                )
                            }
                        </Box>
                    ) : null
                }
                <BonusListView />
                <GameContainerView gameCategories={gameCategories} />
                <LiveTransactionsView />
                <BannerVerticalStack />
                <CustomFooterView />
                <FooterView />
            </Stack>
        )
    } else {
        if (screenType.isMobile || screenType.isTablet) {
            return (
                <div>
                    <BannerSlider />
                    <BannerStaticView />
                    <JackpotView key='jackpotView' />
                    <LiveTransactionsView />
                    <div style={{ marginTop: '9px', marginBottom: '11px', display: 'flex', width: '100%' }}>
                        <div style={{
                            width: '50%',
                            marginLeft: '10px',
                            marginRight: '5px'
                        }}>
                            {getLeftButton()}
                        </div>
                        <div style={{
                            width: '50%',
                            marginLeft: '5px',
                            marginRight: '10px'
                        }}>
                            {getRightButton()}
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid white' }}></div>
                    <div style={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px' }}>
                        <BalanceView />
                    </div>
                    <div style={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px' }}>
                        <BonusListView />
                        <div style={{ height: '10px' }} />
                    </div>
                    <BannerVerticalStack />
                    <GameContainerView gameCategories={gameCategories} />
                    <CustomFooterView />
                </div>
            )
        } else {
            return (
                <Stack direction='row' sx={{ alignItems: 'stretch', minHeight: contentHeight }}>
                    <Stack spacing={0} sx={{ width: '28%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                        <BannerSlider />
                        <BannerStaticView />
                        <JackpotView key='jackpotView' />
                        <LiveTransactionsView />
                        <BannerVerticalStack />
                        <CustomFooterView />
                    </Stack>
                    <Divider orientation='vertical' flexItem={true} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} />
                    <Stack sx={{ width: '44%', flexGrow: 1 }}>
                        <GameContainerView gameCategories={gameCategories} />
                    </Stack>
                    <Divider orientation='vertical' flexItem={true} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} />
                    <Stack sx={{ width: '28%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                        <div style={{ marginTop: '9px', marginBottom: '11px', display: 'flex', width: '100%' }}>
                            <div style={{
                                width: '50%',
                                marginLeft: '10px',
                                marginRight: '5px'
                            }}>
                                {getLeftButton()}
                            </div>
                            <div style={{
                                width: '50%',
                                marginLeft: '5px',
                                marginRight: '10px'
                            }}>
                                {getRightButton()}
                            </div>
                        </div>
                        <div style={{ borderBottom: '1px solid white' }}></div>
                        <div style={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px' }}>
                            <BalanceView />
                        </div>
                        <div style={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px' }}>
                            <BonusListView />
                            <div style={{ height: '10px' }} />
                        </div>
                    </Stack>
                </Stack>
            )
        }
    }
}

export default HomeV1
