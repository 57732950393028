import { Stack } from "@mui/material"
import React, { useContext } from "react"
import { CompanyInfoContext } from '../../App'
import Constants from '../../constants'
import Utils from "../../utils/utils"
import useScreenType from "../../hooks/useScreenType"

export default function BannerVerticalStack() {
    //Contexts
    const { companyInfo } = useContext(CompanyInfoContext)
    const templateDesign = companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic
    const screenType = useScreenType()

    var banners = []
    var bannersAction = []

    let bannerVerticalStack1 = companyInfo.company.bannerVerticalStack1
    if (bannerVerticalStack1 && bannerVerticalStack1.length > 0) {
        banners.push(Utils.shared.getCompanyImagesLink(companyInfo, bannerVerticalStack1, companyInfo.company._id))

        let bannerVerticalStackAction1 = companyInfo.company.bannerVerticalStackAction1 ?? ''
        bannersAction.push(bannerVerticalStackAction1)
    }

    let bannerVerticalStack2 = companyInfo.company.bannerVerticalStack2
    if (bannerVerticalStack2 && bannerVerticalStack2.length > 0) {
        banners.push(Utils.shared.getCompanyImagesLink(companyInfo, bannerVerticalStack2, companyInfo.company._id))

        let bannerVerticalStackAction2 = companyInfo.company.bannerVerticalStackAction2 ?? ''
        bannersAction.push(bannerVerticalStackAction2)
    }

    let bannerVerticalStack3 = companyInfo.company.bannerVerticalStack3
    if (bannerVerticalStack3 && bannerVerticalStack3.length > 0) {
        banners.push(Utils.shared.getCompanyImagesLink(companyInfo, bannerVerticalStack3, companyInfo.company._id))

        let bannerVerticalStackAction3 = companyInfo.company.bannerVerticalStackAction3 ?? ''
        bannersAction.push(bannerVerticalStackAction3)
    }

    let bannerVerticalStack4 = companyInfo.company.bannerVerticalStack4
    if (bannerVerticalStack4 && bannerVerticalStack4.length > 0) {
        banners.push(Utils.shared.getCompanyImagesLink(companyInfo, bannerVerticalStack4, companyInfo.company._id))

        let bannerVerticalStackAction4 = companyInfo.company.bannerVerticalStackAction4 ?? ''
        bannersAction.push(bannerVerticalStackAction4)
    }

    let bannerVerticalStack5 = companyInfo.company.bannerVerticalStack5
    if (bannerVerticalStack5 && bannerVerticalStack5.length > 0) {
        banners.push(Utils.shared.getCompanyImagesLink(companyInfo, bannerVerticalStack5, companyInfo.company._id))

        let bannerVerticalStackAction5 = companyInfo.company.bannerVerticalStackAction5 ?? ''
        bannersAction.push(bannerVerticalStackAction5)
    }

    //Template Methods
    const getSpacing = () => {
        if (templateDesign === Constants.templateDesignModern) {
            if (screenType.isMobile || screenType.isTablet) {
                return '0px'
            } else {
                return '16px'
            }
        }

        return '10px'
    }
    const getPadding = () => {
        if (templateDesign === Constants.templateDesignModern) {
            if (screenType.isMobile || screenType.isTablet) {
                return '24px 0px'
            } else {
                return '33px 153px'
            }
        }

        return '10px'
    }
    const getBorderRadius = () => {
        if (templateDesign === Constants.templateDesignModern) {
            if (screenType.isMobile || screenType.isTablet) {
                return '0px'
            } else {
                return '32px'
            }
        }

        return '0px'
    }
    const getBannersImages = () => {
        return (
            banners.map((e, index) => {
                let actionLink = bannersAction[index]

                return (
                    <img src={e} key={`bannerVertical${index}`} alt="bannerVertical" width={'100%'} height='auto' loading="lazy" draggable={false} style={{
                        cursor: actionLink.length > 0 ? 'pointer' : 'auto',
                        borderRadius: getBorderRadius(),
                    }} onClick={() => {
                        if (actionLink.length > 0) {
                            window.open(actionLink, '_blank', 'noopener,noreferrer')
                        }
                    }} />
                )
            })
        )
    }

    if (banners.length > 0) {
        //Check template
        if (templateDesign === Constants.templateDesignModern) {
            return (
                <Stack spacing={getSpacing()} sx={{ padding: getPadding() }}>
                    {
                        getBannersImages()
                    }
                </Stack>
            )
        } else {
            return (
                <Stack spacing={getSpacing()} sx={{ padding: getPadding() }}>
                    {
                        getBannersImages()
                    }
                </Stack>
            )
        }
    } else {
        return null
    }
}