import { Box, Chip, Grid, Stack, Tooltip } from "@mui/material"
import { AlertDialogContext, CompanyInfoContext, PopupContext, UserInfoContext } from "../../App"
import { useTranslation } from "react-i18next"
import { useContext } from "react"
import { buttonGreenColor, buttonRedColor, defaultPopupBorderColor } from '../../constants'
import BaseButton from "../button/button"
import './popupStyles.css'
import { useNavigate } from "react-router-dom"
import { request } from "../../feathers/feathersClient"
import { useTemplateVariables } from "../../utils/templateUtils"
import Utils from "../../utils/utils"

export default function ClaimRebatePopup({ result, onClaimed }) {
    //Contexts
    const navigate = useNavigate()
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setPopup } = useContext(PopupContext)
    const { t } = useTranslation()
    const template = useTemplateVariables()
    const client = request({
        setAlertDialog: setAlertDialog,
        setCompanyInfo: setCompanyInfo,
        setUserInfo: setUserInfo,
        navigate: navigate
    })
    const rebateDisabledCategoryCompanyGames = companyInfo?.company?.rebateDisabledCategoryCompanyGames ?? []
    const gameCategories = result?.gameCategories ?? []
    const companyGames = result?.companyGames ?? []
    const companyGamesSequence = result?.companyGamesSequence ?? []
    const gamesByCategory = gameCategories.map((e) => {
        let games = companyGames.filter((g) => {
            //Filter out disabled rebate games
            let index = rebateDisabledCategoryCompanyGames.findIndex((r) => {
                if ((r.gameCategory === e?.key) && (r.companyGame === g?.id)) {
                    return true
                }

                return false
            }) ?? -1

            return (g?.game?.gameCategory?.indexOf(e.key) > -1) && (index === -1)
        }).map((g, gameIndex) => {
            const index = companyGamesSequence.findIndex((s) => {
                if (s.gameCategory === e?.key &&
                    s.company === companyInfo?.company?._id &&
                    s.companyGame?._id === g?._id) {
                    return true
                }
    
                return false
            })
    
            var tempCompanyGame = g
            var sequence
            if (index > -1) {
                sequence = companyGamesSequence[index]
            }
    
            tempCompanyGame.sequence = sequence?.sequence ?? (gameIndex + 1)
            tempCompanyGame.image = sequence?.image
            tempCompanyGame.affiliateCommissionRate = sequence?.affiliateCommissionRate
            tempCompanyGame.rebateRate = sequence?.rebateRate
            tempCompanyGame.isHidden = sequence?.isHidden ?? false
            tempCompanyGame.category = e
    
            return tempCompanyGame
        })

        return {
            category: e,
            games: games
        }
    })
    console.log(gamesByCategory)

    return (
        <Stack spacing='10px' sx={{ padding: '20px' }} >
            <Box sx={{ fontSize: '14px', textTransform: 'uppercase' }} >
                <b>{t('GLOBAL_CLAIM_REBATE')}</b>
            </Box>
            <Box sx={{ fontSize: '12px' }}>
                {t('REBATES_CAN_BE_USED_FOR')}
            </Box>
            <Box>
                <Grid container spacing={'10px'}>
                    {
                        gamesByCategory.map((e) => {
                            let category = e?.category
                            let title = category?.value ?? ''
                            let games = e?.games ?? []

                            const getGameList = () => {
                                return (
                                    <Box sx={{ maxHeight: '30vh', overflowY: 'scroll' }}>
                                        <Grid container spacing={'5px'}>
                                            {
                                                games?.map((g) => {
                                                    var gameImageUrl = ''
                                                    if (g?.image) {
                                                        gameImageUrl = Utils.shared.getCompanyGamesSequenceImagesLink(companyInfo, g?.image)
                                                    } else {
                                                        gameImageUrl = Utils.shared.getGamesImagesLink(companyInfo, g?.game?.image)
                                                    }

                                                    return (
                                                        <Grid item xs={'auto'}>
                                                            <Chip label={(
                                                                <Stack direction={'row'} spacing={'2px'} alignItems={'center'}>
                                                                    <img src={gameImageUrl} width={'20px'} height={'20px'} />
                                                                    <Box sx={{ fontSize: '12px' }}>
                                                                        {g?.game?.label ?? ''}
                                                                    </Box>
                                                                </Stack>
                                                            )} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white' }} />
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                )
                            }

                            if (games.length > 0) {
                                return (
                                    <Grid item xs={'auto'}>
                                        <Tooltip title={getGameList()}>
                                            <Chip label={title} sx={{ backgroundColor: defaultPopupBorderColor, color: 'white' }} />
                                        </Tooltip>
                                    </Grid>
                                )
                            } else {
                                return null
                            }
                        })
                    }
                </Grid>
            </Box>
            <Box sx={{ fontSize: '12px' }}>
                {t('VIOLATION_WILL_RESULT')}
            </Box>
            <Stack direction='row' spacing='10px' justifyContent={'center'}>
                <BaseButton title={t('GLOBAL_NO')} color={buttonRedColor} textColor={'white'} customCSS={''} fullWidth={false} enableGradient={false} enableCustomCSS={false} onTap={() => {
                    setPopup(popup => ({
                        ...popup,
                        isOpen: false,
                        children: null,
                        shouldNotClosePopupOnBackClick: false,
                        onClosed: () => { }
                    }))
                }} />
                <BaseButton title={t('GLOBAL_YES')} color={buttonGreenColor} textColor={'white'} customCSS={''} fullWidth={false} enableGradient={false} enableCustomCSS={false} onTap={() => {
                    setPopup(popup => ({
                        ...popup,
                        isOpen: false,
                        children: null,
                        shouldNotClosePopupOnBackClick: false,
                        onClosed: () => { }
                    }))

                    client.create('claim-player-rebate', {
                        company: companyInfo?.company?._id,
                        user: userInfo?._id
                    }, null, (response) => {
                        if (response) {
                            onClaimed(response)

                            setAlertDialog(alertDialog => ({
                                ...alertDialog,
                                title: '',
                                description: t('PROFILE_REBATE_CLAIMED'),
                                isOpen: true
                            }))
                        }
                    })
                }} />
            </Stack>
        </Stack>
    )
}