import parse from 'html-react-parser'
import { useCompanyInfo } from '../../hooks/useCompanyInfo'
import { Stack } from '@mui/material'
import { useTemplateVariables } from '../../utils/templateUtils'
import useScreenType from '../../hooks/useScreenType'
import { templateDesignModern } from '../../constants'

export default function CustomFooterView() {
    const company = useCompanyInfo()
    const template = useTemplateVariables()
    const screenType = useScreenType()
    const customFooter = company?.customFooter ?? ''

    const getPadding = () => {
        if (template.design === templateDesignModern) {
            if (screenType.isMobile || screenType.isTablet) {
                return '24px 0px'
            } else {
                return '33px 153px'
            }
        }

        return '10px'
    }

    if (customFooter.length > 0) {
        return (
            <Stack sx={{ padding: getPadding() }}>
                {parse(customFooter)}
            </Stack>
        )
    } else {
        return null
    }
}