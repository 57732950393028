import { LoginOutlined } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { CompanyInfoContext, LoginPopupContext, PopupContext } from "../../App"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Constants, { templateDesignModern } from '../../constants'
import BaseButton from "./button"
import { useTemplateVariables } from "../../utils/templateUtils"
import Utils from "../../utils/utils"
import { Dialog } from "@mui/material"
import Login from "../../pages/auth/login"

export default function LoginButton({ fullWidth = true }) {
    const navigate = useNavigate()
    const template = useTemplateVariables()

    //Contexts
    const { companyInfo } = useContext(CompanyInfoContext)
    const { setOpenLogin } = useContext(LoginPopupContext)
    const { t } = useTranslation()

    // Login button theme
    var loginButtonColor = companyInfo.company?.loginButtonColor ?? ''
    if (loginButtonColor.length === 0) {
        loginButtonColor = Constants.defaultButtonColor
    }

    var loginButtonFontColor = companyInfo.company?.loginButtonFontColor ?? ''
    if (loginButtonFontColor.length === 0) {
        loginButtonFontColor = Constants.defaultButtonFontColor
    }

    const loginButtonCSS = companyInfo?.company?.loginButtonCSS

    const getBorderRadius = () => {
        if (template.design === templateDesignModern) {
            return '16px'
        } else {
            return null
        }
    }
    const getIcon = () => {
        if (template.design === templateDesignModern || loginButtonCSS) {
            return null
        } else {
            return (
                <LoginOutlined />
            )
        }
    }
    const getPadding = () => {
        if (template.design === templateDesignModern) {
            return '8px 36px'
        } else {
            return null
        }
    }
    const getEnableGradient = () => {
        if (template.design === templateDesignModern) {
            return false
        } else {
            return null
        }
    }

    return (
        <>
            <BaseButton
                title={t('HOME_LOGIN')}
                icon={getIcon()}
                fullWidth={fullWidth}
                color={loginButtonColor}
                textColor={loginButtonFontColor}
                className={'loginButton'}
                customCSS={loginButtonCSS}
                borderRadius={getBorderRadius()}
                padding={getPadding()}
                enableGradient={getEnableGradient()}
                onTap={() => {
                    if (template.design === Constants.templateDesignModern) {
                        setOpenLogin(true)
                    } else {
                        navigate('login')
                    }
                }} />
        </>
    )
}