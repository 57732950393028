import React, { useContext, useEffect, useRef, useState } from "react"
import { AlertDialogContext, CompanyInfoContext, GamePopupContext, LoginPopupContext, PopupContext, UserInfoContext } from '../../../App'
import Constants, { templateDesignModern } from '../../../constants'
import { useNavigate } from "react-router-dom"
import { request } from "../../../feathers/feathersClient"
import Utils from "../../../utils/utils"
import DownloadAppPopup from "../downloadAppPopup"
import ClaimBonusBeforePlayPopup from "../claimBonusBeforePlayPopup"
import { Box, Button, Grid, Skeleton } from "@mui/material"
import { useCompanyInfo } from "../../../hooks/useCompanyInfo"
import { useTemplateVariables } from "../../../utils/templateUtils"
import Games from "../../../pages/game/games"
import useScreenType from "../../../hooks/useScreenType"

export default function GameListView({ templateDesign = 1, gameCategories, companyGames = [], companyGamesSequence = [], gamesBehaviourListing = [], isLoading = false, selectedGameCategoryKey }) {
    let navigate = useNavigate()

    //Contexts
    const screenType = useScreenType()
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { gamePopup, setGamePopup } = useContext(GamePopupContext)
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setOpenLogin } = useContext(LoginPopupContext)
    const { setPopup } = useContext(PopupContext)
    const isWebMobile = Utils.shared.checkIfIsMobile()
    const client = request({
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })
    const company = useCompanyInfo()
    const template = useTemplateVariables()

    //State
    const [isGameLoading, setIsGameLoading] = useState(false)
    const [singleGame, setSingleGame] = useState(null)
    const [getPlayerGameAccount, setGetPlayerGameAccount] = useState(null) // For single game link use only

    //Ref
    const gameLastLaunched = useRef(Date.now())
    const isGameLaunching = useRef(false)

    let selectedGameCategory = gameCategories.find((e) => e.key === selectedGameCategoryKey) ?? gameCategories[0]

    const getItemCount = () => {
        if (screenType.isMobile) {
            return 4
        } else if (screenType.isTablet) {
            return 3
        } else {
            return 2
        }
    }
    const getSkeletonItemCount = () => {
        if (screenType.isMobile) {
            return 3
        } else if (screenType.isTablet) {
            return 4
        } else {
            return 6
        }
    }
    const getSpacing = () => {
        if (template.design === Constants.templateDesignModern) {
            if (screenType.isMobile || screenType.isTablet) {
                return '16px'
            } else {
                return '24px'
            }
        }

        return '10px'
    }
    const styles = {
        gridLayout: {
            padding: getSpacing(),
            display: 'grid',
            gridTemplateColumns: `repeat(${getSkeletonItemCount()}, 1fr)`,
            gridAutoRows: 'auto',
            gridColumnGap: getSpacing(),
            gridRowGap: getSpacing(),
            width: `calc(100% - ${getSpacing()} - ${getSpacing()})`,
        }
    }

    //API Method
    const getPlayerSingleGameListing = (game) => {
        setIsGameLoading(true)

        var data = {
            companyGame: game._id,
            gameCategory: selectedGameCategory?.key,
            company: company?._id,
            secretKey: 'thebestsecretkey',
            language: 'en',
            gamePlatform: isWebMobile ? 2 : 1,
        }

        client.create('get-player-single-game-listing', data, null, (response) => {
            setIsGameLoading(false)
            setGetPlayerGameAccount(response)
        }, (error) => {
            if (error.code === Constants.feathers_error_code_claim_bonus_before_play) {
                //Show popup inform user if they want to claim bonus before play
                setPopup(popup => ({
                    ...popup,
                    children: <ClaimBonusBeforePlayPopup companyId={company?._id} userId={userInfo?._id} companyGameId={game._id} gameCategory={selectedGameCategory?.key} onBonusDeactivated={() => {
                        launchGame(game)
                    }} />,
                    showCloseButton: false,
                    isOpen: true
                }))
            } else {
                setAlertDialog(alertDialog => ({
                    ...alertDialog,
                    translationData: error?.data,
                    description: error?.message,
                    isOpen: true
                }))
            }
        }, false, () => {
            setIsGameLoading(false)
        })
    }
    const launchGame = (game) => {
        //Prevent double calling
        if (gameLastLaunched.current > Date.now() || isGameLaunching.current) {
            return
        }

        //Set is game launching
        isGameLaunching.current = true

        var data = {
            companyGame: game._id,
            gameCategory: selectedGameCategory?.key,
            company: company?._id,
            user: userInfo?._id,
            language: 'en',
            gamePlatform: isWebMobile ? 2 : 1,
        }

        client.create('get-player-game-account', data, null, (response) => {
            const gameBehaviour = response?.gameBehaviour
            const gamesPlayer = response?.gamesPlayer

            switch (gameBehaviour) {
                case Constants.gameBehaviourCustomLayout:
                case Constants.gameBehaviourCustomLayoutFEHost:
                case Constants.gameBehaviourCustomLayoutCallGameLink:
                case Constants.gameBehaviourCustomLayoutSingleCall:
                    const gameListing = response?.gameListing ?? []
                    navigate('/games', {
                        state: {
                            gameListing: gameListing,
                            gamesPlayer: gamesPlayer,
                            gameBehaviour: gameBehaviour,
                            companyGameId: game?._id,
                            gameCategory: selectedGameCategory?.key
                        }
                    },)
                    break
                case Constants.gameBehaviourDownloadAPP:
                    //Show download app popup
                    if (template.design === templateDesignModern) {
                        setGamePopup(gamePopup => ({
                            ...gamePopup,
                            game: game,
                            gamesPlayerAccount: response
                        }))
                    } else {
                        setPopup(popup => ({
                            ...popup,
                            children: <DownloadAppPopup game={game} gamesPlayerAccount={response} />,
                            showCloseButton: false,
                            isOpen: true
                        }))
                    }
                    break
                default:
                    console.log('No known game behavior, not launching')
                    break
            }
        }, (error) => {
            if (error.code === Constants.feathers_error_code_claim_bonus_before_play) {
                //Show popup inform user if they want to claim bonus before play
                setPopup(popup => ({
                    ...popup,
                    children: <ClaimBonusBeforePlayPopup companyId={company?._id} userId={userInfo?._id} companyGameId={game._id} gameCategory={selectedGameCategory?.key} onBonusDeactivated={() => {
                        launchGame(game)
                    }} />,
                    showCloseButton: false,
                    isOpen: true
                }))
            } else {
                setAlertDialog(alertDialog => ({
                    ...alertDialog,
                    translationData: error?.data,
                    description: error.message,
                    isOpen: true
                }))
            }
        }, true, () => {
            //Set is game launching to false
            isGameLaunching.current = false

            //Set the game luanched time with additional 1 second
            gameLastLaunched.current = Date.now() + 1000
        })
    }

    //Component Methods
    const getSkeletonView = () => {
        let shimmerColor = 'rgba(255, 255, 255, 0.2)'
        let skeletonArray = Array(getItemCount() * 2).fill('')

        return (
            <div style={styles.gridLayout}>
                {
                    skeletonArray.map((e, index) => {
                        return (
                            <Skeleton key={index} animation="wave" variant='rounded' sx={{
                                bgcolor: shimmerColor,
                                width: '100%',
                                height: '0',
                                paddingBottom: '100%'
                            }} />
                        )
                    })
                }
            </div>
        )
    }

    //For single game link only
    useEffect(() => {
        if (singleGame) {
            getPlayerSingleGameListing(singleGame)
        }
    }, [singleGame])

    if (isLoading) {
        //Show skeleton
        return getSkeletonView()
    } else {
        //Check for single game link
        if (companyGames.length > 0) {
            var gamesByCategory = []
            var isCustom = selectedGameCategory?.isCustom ?? false

            if (isCustom) {
                gamesByCategory = selectedGameCategory?.companyGames?.map((e) => {
                    return {
                        companyGame: companyGames.find((game) => { return game._id === e.companyGame }),
                        companyGameCategory: e?.companyGameCategory
                    }
                })
                //Added this filter to filter out companyGame that is removed
                gamesByCategory = gamesByCategory.filter((e) => {
                    return e?.companyGame
                })
            } else {
                let matchedCompanyGames = companyGames.filter((e) => {
                    return (e.game.gameCategory.indexOf(selectedGameCategory?.key) > -1)
                }) ?? []
                gamesByCategory = matchedCompanyGames.map((e) => {
                    return {
                        companyGame: e,
                        companyGameCategory: selectedGameCategory?.key
                    }
                })
            }

            let isSingleGameLink = (gamesByCategory.length === 1 && gamesByCategory[0]?.companyGame?.game?.isSingleGameLink)
            if (isSingleGameLink && !singleGame) {
                setSingleGame(gamesByCategory[0]?.companyGame)
                return getSkeletonView()
            } else if (gamesByCategory.length > 0) {
                const sortedCompanyGames = gamesByCategory.map((e, gameIndex) => {
                    const index = companyGamesSequence.findIndex((s) => {
                        //Check if is custom
                        if (isCustom) {
                            if (s.gameCategory === e.companyGameCategory &&
                                s.company === company?._id &&
                                s.companyGame?._id === e?.companyGame?._id) {
                                return true
                            }
                        } else {
                            if (s.gameCategory === selectedGameCategory?.key &&
                                s.company === company?._id &&
                                s.companyGame?._id === e?.companyGame?._id) {
                                return true
                            }
                        }

                        return false
                    })

                    var tempCompanyGame = e?.companyGame
                    var sequence
                    if (index > -1) {
                        sequence = companyGamesSequence[index]
                    }

                    tempCompanyGame.sequence = sequence?.sequence ?? (gameIndex + 1)
                    tempCompanyGame.image = sequence?.image
                    tempCompanyGame.affiliateCommissionRate = sequence?.affiliateCommissionRate
                    tempCompanyGame.rebateRate = sequence?.rebateRate
                    tempCompanyGame.isHidden = sequence?.isHidden ?? false
                    tempCompanyGame.category = e.companyGameCategory

                    return tempCompanyGame
                })

                sortedCompanyGames.sort((a, b) => a.sequence > (b.sequence ?? 0) ? 1 : -1)

                const gameList = () => {
                    return sortedCompanyGames.map((e, index) => {
                        var gameImageUrl = ''
                        if (e?.image) {
                            gameImageUrl = Utils.shared.getCompanyGamesSequenceImagesLink(companyInfo, e?.image)
                        } else {
                            gameImageUrl = Utils.shared.getGamesImagesLink(companyInfo, e?.game?.image)
                        }

                        //Visibility
                        let isHidden = e?.isHidden ?? false

                        if (isHidden) {
                            return null
                        } else {
                            return (
                                <Grid item xs={getItemCount()}>
                                    <Button key={`game${index}`} sx={{
                                        backgroundImage: `url(${gameImageUrl})`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        paddingBottom: '100%',
                                        width: '100%'
                                    }} onClick={(event) => {
                                        //Prevent from control/shift click to open multiple tab
                                        if (event.ctrlKey || event.shiftKey || event.metaKey) {
                                            event.preventDefault()
                                        } else {
                                            let gameCode = e?.game?.gameCode

                                            //First check if player is logged in
                                            if (userInfo && gameCode) {
                                                //Logged in, begin launch game process
                                                //First check game behavior
                                                let matchedGameBehaviorObject = gamesBehaviourListing.find((list) => {
                                                    return list.gameCode === gameCode
                                                })
                                                let matchedGameCategory = matchedGameBehaviorObject?.gameCategories?.find((gc) => {
                                                    return (gc.gameCategory === e.category)
                                                })
                                                let gameBehaviour = matchedGameCategory?.gameBehaviour
                                                switch (gameBehaviour) {
                                                    case Constants.gameBehaviourCustomLayout:
                                                    case Constants.gameBehaviourCustomLayoutFEHost:
                                                    case Constants.gameBehaviourCustomLayoutCallGameLink:
                                                    case Constants.gameBehaviourCustomLayoutSingleCall:
                                                    case Constants.gameBehaviourDownloadAPP:
                                                        launchGame(e)
                                                        break
                                                    case Constants.gameBehaviourOpenLink:
                                                    case Constants.gameBehaviourOpenLinkFEHost:
                                                        let companyGameId = e?._id
                                                        let token = Utils.shared.retrieveLocalStorage(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`)
                                                        window.open(`/gameLauncher?cg=${companyGameId}&gc=${matchedGameCategory?.gameCategory}&u=${userInfo?._id}&c=${company?._id}&gb=${gameBehaviour}&t=${token}`, '_blank')?.focus()
                                                        break
                                                    default:
                                                        console.log('No known game behavior, not launching')
                                                        break
                                                }
                                            } else {
                                                //Not logged in, redirect to login page
                                                Utils.shared.navigateLogin({
                                                    companyInfo: companyInfo,
                                                    navigate: navigate,
                                                    setOpenLogin: setOpenLogin
                                                })
                                            }
                                        }
                                    }} />
                                </Grid>
                            )
                        }
                    })
                }

                //Check if is single game link
                if (isSingleGameLink) {
                    return <Games isSingleGameLink={true} gameList={getPlayerGameAccount?.gameListing ?? []} category={gamesByCategory?.companyGameCategory} gamesPlayerObject={getPlayerGameAccount?.gamesPlayer} />
                }

                if (template.design === Constants.templateDesignModern) {
                    if (!screenType.isMobile && !screenType.isTablet) {
                        return (
                            <Box sx={{
                                padding: '0px 64px'
                            }}>
                                <Box sx={{
                                    boxShadow: '0px 4px 4px 0px rgba(23, 53, 89, 0.16)',
                                    padding: '60px 96px',
                                    borderRadius: '32px',
                                    marginTop: '-30px'
                                }} >
                                    <Grid container justifyContent={'center'} rowSpacing={getSpacing()} columnSpacing={getSpacing()} sx={{ paddingLeft: getSpacing(), paddingRight: getSpacing() }}>
                                        {gameList()}
                                    </Grid>
                                </Box>
                            </Box>
                        )
                    }
                }

                return (
                    <Grid container rowSpacing={getSpacing()} columnSpacing={getSpacing()} sx={{ paddingLeft: getSpacing(), paddingRight: getSpacing() }}>
                        {gameList()}
                    </Grid>
                )
            }
        }

        return null
    }
}