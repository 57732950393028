import { HelpOutline } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import Utils from "../../utils/utils";
import { useTemplateVariables } from "../../utils/templateUtils";
import { useTranslation } from "react-i18next";
import BaseButton from "../button/button";
import { buttonGreenColor, buttonRedColor } from "../../constants";
import { useContext } from "react";
import { ClearBalancePopupContext, CompanyInfoContext } from "../../App";

export default function ClearPlayerCreditBalancePopup({ onConfirm }) {
    const { companyInfo } = useContext(CompanyInfoContext)
    const template = useTemplateVariables()
    const { t } = useTranslation()
    const { setOpenClearBalance } = useContext(ClearBalancePopupContext)

    const currencyCode = companyInfo?.company?.currencyCode ?? ''

    return (
        <Stack spacing='20px' alignItems={'center'} sx={{
            padding: '20px'
        }}>
            <Box sx={{ fontSize: '60px' }}>
                <HelpOutline htmlColor={template.mainFontColor} fontSize='inherit' />
            </Box>
            <Typography>
                {t('GLOBAL_SURE_TO_CLEAR_BALANCE')}
            </Typography>
            <Stack alignItems={'center'}>
                <Typography>
                    {t('GLOBAL_YOUR_BALANCE_WILL_RESET_TO')}
                </Typography>
                <Typography>
                    {Utils.shared.getAmountString(0, currencyCode, companyInfo?.company)}
                </Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                <BaseButton title={t('GLOBAL_YES')} color={buttonGreenColor} onTap={() => {
                    onConfirm()
                    setOpenClearBalance(false)
                }} />
                <Box sx={{ width: '20px' }} />
                <BaseButton title={t('GLOBAL_NO')} color={buttonRedColor} onTap={() => {
                    setOpenClearBalance(false)
                }} />
            </Stack>
        </Stack>
    )
}