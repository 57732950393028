import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import { ChevronLeft, Close } from '@mui/icons-material'
import { CompanyInfoContext, UserInfoContext, SnackBarContext, AlertDialogContext, PopupContext, ChangePwdPopupContext } from '../../App';
import { useContext, useState } from 'react';
import Constants, { templateDesignModern } from '../../constants'
import InputTextField from '../../components/textfield/inputTextField';
import BaseButton from '../../components/button/button';
import { request } from '../../feathers/feathersClient';
import Utils from '../../utils/utils';
import { useTranslation } from "react-i18next"
import { useTemplateVariables } from '../../utils/templateUtils';

export default function ChangePassword() {
    const navigate = useNavigate()
    const template = useTemplateVariables()

    // Context
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);
    const { userInfo, setUserInfo } = useContext(UserInfoContext);
    const { setOpenChangePwd } = useContext(ChangePwdPopupContext);
    const { setSnackBar } = useContext(SnackBarContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { t } = useTranslation()
    const client = request({
        setAlertDialog: setAlertDialog, 
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })

    // States
    const [values, setValues] = useState({
        currentPassword: '',
        currentPasswordError: '',
        newPassword: '',
        newPasswordError: '',
        confirmPassword: '',
        confirmPasswordError: ''
    })
    const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(userInfo.isFirstTimeLogin ?? false)

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    //API Method
    const performChangePassword = () => {
        //Perform change password
        if (values.currentPassword.length === 0) {
            setValues(values => ({ ...values, currentPasswordError: 'Current password is mandatory' }))
            return
        }

        if (values.newPassword.length === 0) {
            setValues(values => ({ ...values, newPasswordError: `${t('AUTH_NEW_PWD')} ${t('GLOBAL_IS_MANDATORY')}` }))
            return
        }

        //Only check confirm password if is not first time login
        if (!isFirstTimeLogin) {
            if (values.newPassword !== values.confirmPassword) {
                setValues(values => ({ ...values, confirmPasswordError: t('AUTH_PASSWORD_NOT_MATCH') }))
                return
            }
        }

        var data = {
            user: userInfo._id,
            newPassword: values.newPassword,
        }

        //By pass current password for first time login
        if (isFirstTimeLogin) {
            data.currentPassword = values.currentPassword
        }

        client.create('change-user-password', data, null, (response) => {
            setAlertDialog(alertDialog => ({
                ...alertDialog,
                isOpen: false
            }))

            setSnackBar(snackBar => ({
                ...snackBar,
                title: 'Password changed successfully',
                isOpen: true
            }))

            Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                navigate('/', { replace: true })
            }, true)
        })
    }

    if (template.design === templateDesignModern) {
        return (
            <Stack sx={{
                backgroundColor: template.mainFontColor,
                padding: '16px',
                borderRadius: '32px',
                height: '100%',
                overflow: 'hidden'
            }} alignItems={'start'} justifyContent={'start'}>
                <Stack sx={{
                    padding: '16px',
                    width: 'calc(100% - 32px)'
                }} spacing='16px'>
                    <Stack>
                        <Typography sx={{
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '26px',
                            color: template.navigationFontColor
                        }}>
                            {t('GLOBAL_CHANGE_PASSWORD')}
                        </Typography>
                        <Divider sx={{
                            height: '2px',
                            backgroundColor: 'white',
                            width: '40%'
                        }} />
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '22px',
                            color: 'white'
                        }}>
                            {'Enter your current password and new password to change your password'}
                        </Typography>
                    </Stack>
                    <InputTextField
                        value={values.currentPassword}
                        type='password'
                        placeholder={`${t('AUTH_CURRENT_PWD')}`}
                        titleColor={color}
                        showError={values.currentPasswordError.length > 0}
                        errorText={values.currentPasswordError}
                        onTextChanged={(value) => {
                            setValues(values => ({ ...values, currentPassword: value }))
                            setValues(values => ({ ...values, currentPasswordError: '' }))
                        }} />
                    <InputTextField
                        value={values.newPassword}
                        type='password'
                        placeholder={`${t('AUTH_NEW_PWD')}`}
                        titleColor={color}
                        showError={values.newPasswordError.length > 0}
                        errorText={values.newPasswordError}
                        onTextChanged={(value) => {
                            setValues(values => ({ ...values, newPassword: value }))
                            setValues(values => ({ ...values, newPasswordError: '' }))
                        }} />
                    {
                        isFirstTimeLogin ? null : (
                            <InputTextField
                                value={values.confirmPassword}
                                type='password'
                                placeholder={`${t('AUTH_CONFIRM_PWD')}`}
                                titleColor={color}
                                showError={values.confirmPasswordError.length > 0}
                                errorText={values.confirmPasswordError}
                                onTextChanged={(value) => {
                                    setValues(values => ({ ...values, confirmPassword: value }))
                                    setValues(values => ({ ...values, confirmPasswordError: '' }))
                                }} />
                        )
                    }
                    <BaseButton title={t('AUTH_SUBMIT')} color={template.navigationFontColor} textColor={template.mainFontColor} enableGradient={false} borderRadius="16px" fullWidth={true} onTap={(event) => {
                        performChangePassword()
                    }} />
                    <Stack direction={'row'} spacing='4px'>
                        <Button sx={{
                            padding: '0px',
                            textTransform: 'none'
                        }} onClick={() => {
                            setOpenChangePwd(false)
                            navigate('/chat')
                        }}>
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '22px',
                                color: 'white'
                            }}>
                                {t('AUTH_NEED_HELP_CLICK_HERE')}
                            </Typography>
                        </Button>
                    </Stack>
                </Stack>
                {
                    isFirstTimeLogin ? null : (
                        <IconButton onClick={() => {
                            setOpenChangePwd(false)
                        }} size="small" sx={{
                            backgroundColor: template.mainFontColor,
                            position: 'absolute',
                            top: 16,
                            right: 16
                        }}>
                            <Close htmlColor="white" fontSize="small" />
                        </IconButton>
                    )
                }
            </Stack>
        )
    } else {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', color: color }}>
                    <IconButton
                        size="large"
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <ChevronLeft htmlColor={color} />
                    </IconButton>
                    <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                        {t('GLOBAL_CHANGE_PASSWORD')}
                    </Typography>
                </Box>
                <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white' }}></div>
                <Stack spacing='10px' sx={{ margin: '20px' }}>
                    <InputTextField
                        title={t('AUTH_CURRENT_PWD')}
                        value={values.currentPassword}
                        type='password'
                        placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_CURRENT_PWD')}`}
                        helperText={t('AUTH_CURRENT_PWD_HELPER')}
                        titleColor={color}
                        showError={values.currentPasswordError.length > 0}
                        errorText={values.currentPasswordError}
                        onTextChanged={(value) => {
                            setValues(values => ({ ...values, currentPassword: value }))
                            setValues(values => ({ ...values, currentPasswordError: '' }))
                        }} />
                    <InputTextField
                        title={t('AUTH_NEW_PWD')}
                        value={values.newPassword}
                        type='password'
                        placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_NEW_PWD')}`}
                        helperText={t('AUTH_NEW_PWD_HELPER')}
                        titleColor={color}
                        showError={values.newPasswordError.length > 0}
                        errorText={values.newPasswordError}
                        onTextChanged={(value) => {
                            setValues(values => ({ ...values, newPassword: value }))
                            setValues(values => ({ ...values, newPasswordError: '' }))
                        }} />
                    {
                        isFirstTimeLogin ? null : (
                            <InputTextField
                                title={t('AUTH_CONFIRM_PWD')}
                                value={values.confirmPassword}
                                type='password'
                                placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_CONFIRM_PWD')}`}
                                helperText={t('AUTH_CONFIRM_PWD_HELPER')}
                                titleColor={color}
                                showError={values.confirmPasswordError.length > 0}
                                errorText={values.confirmPasswordError}
                                onTextChanged={(value) => {
                                    setValues(values => ({ ...values, confirmPassword: value }))
                                    setValues(values => ({ ...values, confirmPasswordError: '' }))
                                }} />
                        )
                    }
                    <BaseButton title={t('AUTH_SUBMIT')} onTap={(event) => {
                        performChangePassword()
                    }} />
                    <br />
                </Stack>
            </div>
        )
    }
}