import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material'
import { ChevronLeft, Lock } from '@mui/icons-material'
import { CompanyInfoContext } from '../../App';
import { useContext } from 'react';
import Constants from '../../constants'
import { useTranslation } from "react-i18next"

function Settings() {
    // Context
    const { companyInfo } = useContext(CompanyInfoContext)
    const { t } = useTranslation()

    let navigate = useNavigate()

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', color: color }}>
                <IconButton
                    size="large"
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <ChevronLeft htmlColor={color} />
                </IconButton>
                <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                    {t('GLOBAL_SETTINGS')}
                </Typography>
            </Box>
            <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white' }}></div>
            <button style={{ backgroundColor: 'transparent', border: 0 }} onClick={() => {
                navigate('changePassword')
            }}>
                <div style={{ height: '100%', marginLeft: '20px', marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', color: color }}>
                        <Lock htmlColor={color} fontSize="medium" />
                        <Typography variant="subtitle1" textAlign='left' component="div" sx={{ flexGrow: 1, marginLeft: '10px' }}>
                            {t('GLOBAL_CHANGE_PASSWORD')}
                        </Typography>
                    </Box>
                    <div style={{ height: '10px' }} />
                    <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white' }} />
                </div>
            </button>
        </div>

    )
}

export default Settings