import { useContext, useEffect, useState } from "react"
import { useGet } from "figbird"
import { CompanyInfoContext } from "../App"

export function useCompanyInfo() {
    //Contexts
    const { companyInfo } = useContext(CompanyInfoContext)
    const { data } = useGet('companies', companyInfo?.company?._id)

    //State
    const [company, setCompany] = useState(companyInfo?.company)

    useEffect(() => {
        if (data) {
            setCompany(data)
        } else {
            setCompany(companyInfo?.company)
        }
        
    }, [data])

    return company
}