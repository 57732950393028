import { ChevronLeft, People, Money } from "@mui/icons-material";
import { Box, IconButton, Paper, Stack, Tab, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow, Tabs, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AlertDialogContext, CompanyInfoContext, UserInfoContext } from "../../App"
import Constants from '../../constants'
import { styled } from "@mui/material/styles"
import Utils from "../../utils/utils";
import { request } from '../../feathers/feathersClient'
import { useTranslation } from "react-i18next"
import { useTemplateVariables } from "../../utils/templateUtils";
import useScreenType from "../../hooks/useScreenType";

function DownlineCommission() {
    const navigate = useNavigate()
    const template = useTemplateVariables()
    const screenType = useScreenType()

    //Pagination Constants
    const rowsPerPage = 10

    // Context
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { t } = useTranslation()
    const client = request({
        setAlertDialog: setAlertDialog, 
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })

    //States
    const [values, setValues] = useState({
        tabIndex: 0,
    })
    const [result, setResult] = useState(null)

    //Downline
    const [downlineList, setDownlineList] = useState([])
    const [downlinePagination, setDownlinePagination] = useState(null)
    const [downlinePage, setDownlinePage] = useState(0)
    const downlinePaginationRef = useRef(downlinePagination)
    const downlineListRef = useRef(downlineList)

    //Commission
    const [commissionList, setCommissionList] = useState([])
    const [commissionPagination, setCommissionPagination] = useState(null)
    const [commissionPage, setCommissionPage] = useState(0)
    const commissionPaginationRef = useRef(commissionPagination)
    const commissionListRef = useRef(commissionList)

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    //API METHODS
    const getPlayerDownlineCommission = () => {
        client.create('get-player-downline-commission', {
            company: companyInfo.company._id,
            user: userInfo._id
        }, null, (response) => {
            setResult(response)

            const downlineList = response?.users?.data ?? []
            setDownlineList(downlineList)
            downlineListRef.current = downlineList

            const usersPagination = response?.users
            setDownlinePagination(usersPagination)
            downlinePaginationRef.current = usersPagination

            const commissionPayouts = response?.commissionPayouts?.data ?? []
            setCommissionList(commissionPayouts)
            commissionListRef.current = commissionPayouts

            const commissionPayoutsPagination = response?.commissionPayouts
            setCommissionPagination(commissionPayoutsPagination)
            commissionPaginationRef.current = commissionPayoutsPagination
        })
    }
    const getUserList = (index, isRefresh = false) => {
        if ((index * rowsPerPage) >= downlineList.length || isRefresh) {
            client.find('users', {
                query: {
                    '$skip': (index * rowsPerPage),
                    '$limit': rowsPerPage,
                    'company': companyInfo.company?._id,
                    'affiliateUser': userInfo?._id,
                    '$populate': [
                        'role',
                        'company',
                        'createdBy',
                        'roms',
                        'whatsappBot',
                        'affiliateUser',
                    ]
                }
            }, (response) => {
                let users = response?.data ?? []
                if (isRefresh) {
                    setDownlineList([...[], ...users])
                    downlineListRef.current = [...[], ...users]
                } else {
                    setDownlineList(downlineList => [...downlineList, ...users])
                    downlineListRef.current = [...downlineList, ...users]
                }

                setDownlinePagination(response)
                downlinePaginationRef.current = response

                setDownlinePage(index)
            })
        } else {
            setDownlinePage(index)
        }
    }
    const getCommissionList = (index, isRefresh = false) => {
        if ((index * rowsPerPage) >= commissionList.length || isRefresh) {
            client.find('commission-payout', {
                query: {
                    '$skip': (index * rowsPerPage),
                    '$limit': rowsPerPage,
                    'company': companyInfo?.company?._id,
                    'user': userInfo?._id,
                    'status': {
                        '$in': [
                            Constants.commissionPayoutStatusClaim
                        ]
                    },
                    '$populate': [
                        'user',
                        'company',
                        'commission',
                    ]
                }
            }, (response) => {
                let list = response?.data ?? []
                if (isRefresh) {
                    setCommissionList([...[], ...list])
                    commissionListRef.current = [...[], ...list]
                } else {
                    setCommissionList(commissionList => [...commissionList, ...list])
                    commissionListRef.current = [...commissionList, ...list]
                }

                setCommissionPagination(response)
                commissionPaginationRef.current = response

                setCommissionPage(index)
            })
        } else {
            setCommissionPage(index)
        }
    }

    //Tabs
    const tabs = [
        {
            title: t('GLOBAL_DOWNLINE'),
            icon: <People />
        },
        {
            title: t('GLOBAL_COMMISSION'),
            icon: <Money />
        },
    ]
    const StyledTab = styled(Tab)(() => ({
        color: color
    }))

    //DATAGRID METHODS
    const getTotal = () => {
        switch (values.tabIndex) {
            case 0:
                //Downline
                return downlinePagination?.total ?? 0
            default:
                //Commission
                return commissionPagination?.total ?? 0
        }
    }
    const getRows = () => {
        switch (values.tabIndex) {
            case 0:
                return downlineList?.slice(downlinePage * rowsPerPage, downlinePage * rowsPerPage + rowsPerPage)?.map((e, index) => {
                    let createdAt = Utils.shared.convertDateToString(Date.parse(e.createdAt), Constants.date_format_2)
                    let userId = e?.userId ?? '-'

                    return (
                        <StyledTableRow key={`row${index}`} hover={true}>
                            <StyledTableCell>{createdAt}</StyledTableCell>
                            <StyledTableCell>{userId}</StyledTableCell>
                        </StyledTableRow>
                    )
                }) ?? []
            default:
                return commissionList?.slice(commissionPage * rowsPerPage, commissionPage * rowsPerPage + rowsPerPage)?.map((e, index) => {
                    let commissionPaidDateTime = e.commissionPaidDateTime ? Utils.shared.convertDateToString(Date.parse(e.commissionPaidDateTime), Constants.date_format_2) : '-'
                    let commissionPayoutId = e?.commissionPayoutId ?? '-'
                    let currencyCode = e?.company?.currencyCode
                    let commissionPaid = Utils.shared.getAmountString(e?.commissionPaid, currencyCode, companyInfo?.company)

                    return (
                        <StyledTableRow key={`row${index}`} hover={true} onClick={() => {
                            navigate(`/profile/downline/commissionDetails?id=${e?._id}`)
                        }}>
                            <StyledTableCell>{commissionPaidDateTime}</StyledTableCell>
                            <StyledTableCell>{commissionPayoutId}</StyledTableCell>
                            <StyledTableCell>{commissionPaid}</StyledTableCell>
                        </StyledTableRow>
                    )
                }) ?? []
        }
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            color: template.design === Constants.templateDesignModern ? '#828282' : 'white',
            fontSize: template.design === Constants.templateDesignModern ? 12 : 14,
            fontWeight: 'bold',
            backgroundColor: template.design === Constants.templateDesignModern ? '#E8E8E8' : 'black'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            color: template.design === Constants.templateDesignModern ? '#2E2E2E' : 'white',
        },
        [`&.${tableCellClasses.footer}`]: {
            color: color,
            fontSize: 14,
            fontWeight: 'bold'
        },
    }))
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)'
        }
    }))

    //Component Methods
    const getDetailsView = ({ title, value, borderRadius = '16px' }) => {
        return (
            <Stack sx={{
                backgroundColor: template.mainFontColor,
                borderRadius: borderRadius,
                padding: '16px',
                width: '100%'
            }}>
                <Typography sx={{
                    fontSize: '10px',
                    fontWeight: '600',
                    lineHeight: '120%',
                    textTransform: 'uppercase',
                    color: 'white'
                }}>
                    {title}
                </Typography>
                <Typography sx={{
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: '26px',
                    color: template.navigationFontColor
                }}>
                    {value}
                </Typography>
            </Stack>
        )
    }
    const getListView = () => {
        let backgroundColor = template.design === Constants.templateDesignModern ? 'white' : 'black'
        let border = template.design === Constants.templateDesignModern ? null : '1px solid white'
        let borderRadius = template.design === Constants.templateDesignModern ? '0px' : null
        let elevation = template.design === Constants.templateDesignModern ? 0 : 1

        return (
            <Paper elevation={elevation} sx={{ width: '100%', overflow: 'hidden', backgroundColor: backgroundColor, border: border, borderRadius: borderRadius }}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            {
                                values.tabIndex === 1 ? (
                                    <TableRow>
                                        <StyledTableCell>{'COMMISSION DATE & TIME'}</StyledTableCell>
                                        <StyledTableCell>TRANSACTION ID</StyledTableCell>
                                        <StyledTableCell>COMMISSION</StyledTableCell>
                                    </TableRow>
                                ) : (
                                    <TableRow>
                                        <StyledTableCell>DATE OF REGISTER</StyledTableCell>
                                        <StyledTableCell>PLAYER ID</StyledTableCell>
                                    </TableRow>
                                )
                            }
                        </TableHead>
                        <TableBody>
                            {((values.tabIndex === 1 ? commissionList : downlineList)?.length ?? 0) > 0 ? getRows() : <StyledTableRow key='norecordrow'>
                                <StyledTableCell component="th" scope="row" align="center" colSpan={values.tabIndex === 1 ? 3 : 2}>
                                    No Record Found
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    count={getTotal()}
                    rowsPerPage={rowsPerPage}
                    component="div"
                    sx={{ color: 'white' }}
                    backIconButtonProps={{
                        sx: {
                            svg: {
                                color: 'white'
                            }
                        }
                    }}
                    nextIconButtonProps={{
                        sx: {
                            svg: {
                                color: 'white'
                            }
                        }
                    }}
                    page={values.tabIndex === 1 ? commissionPage : downlinePage}
                    onPageChange={(event, newPage) => {
                        switch (values.tabIndex) {
                            case 1:
                                //Commission
                                getCommissionList(newPage, false)
                                break
                            default:
                                //Downline
                                getUserList(newPage, false)
                                break
                        }
                    }}
                />
            </Paper>
        )
    }

    useEffect(() => {
        if (userInfo) {
            getPlayerDownlineCommission()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (template.design === Constants.templateDesignModern) {
        if (screenType.isMobile) {
            return (
                <Stack spacing={'16px'}>
                    <Stack direction={'row'} spacing='0px' sx={{
                        borderRadius: '16px',
                        overflow: 'hidden'
                    }}>
                        {getDetailsView({ title: t('DOWNLINE_DIRECT_DOWNLINE'), value: result?.directDownline ?? 0, borderRadius: '0px' })}
                        {getDetailsView({ title: t('DOWNLINE_TOTAL_TEAM'), value: result?.totalTeam ?? 0, borderRadius: '0px'})}
                    </Stack>
                    {getListView()}
                </Stack>
            )
        } else {
            return (
                <Stack spacing={'24px'}>
                    <Stack direction={'row'} spacing='8px'>
                        {getDetailsView({ title: t('DOWNLINE_DIRECT_DOWNLINE'), value: result?.directDownline ?? 0 })}
                        {getDetailsView({ title: t('DOWNLINE_TOTAL_TEAM'), value: result?.totalTeam ?? 0 })}
                    </Stack>
                    {getListView()}
                </Stack>
            )
        }
    } else {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', color: color }}>
                    <IconButton
                        size="large"
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <ChevronLeft htmlColor={color} />
                    </IconButton>
                    <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                        {`${t('GLOBAL_DOWNLINE')} / ${t('GLOBAL_COMMISSION')}`}
                    </Typography>
                </Box>
                <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white' }}></div>
                <Box sx={{ borderBottom: 1, borderColor: 'white', color: color, marginTop: '-10px' }}>
                    <Tabs value={values.tabIndex} textColor='inherit' TabIndicatorProps={{ style: { background: color, height: 4 } }} sx={{ color: color, height: 60 }} onChange={(event, index) => {
                        setValues(values => ({ ...values, tabIndex: index }))
                    }}>
                        {tabs.map((e, index) => {
                            return <StyledTab key={`tabItem${index}`} label={e.title} icon={e.icon} wrapped={false} sx={{ color: color }} iconPosition='start' />
                        })}
                    </Tabs>
                </Box>
                <Stack direction='column' spacing='10px' style={{ margin: '10px' }} >
                    <div style={{ color: color }} >
                        {`${t('DOWNLINE_DIRECT_DOWNLINE')}: ${result?.directDownline ?? 0}`}
                    </div>
                    <div style={{ color: color }} >
                        {`${t('DOWNLINE_TOTAL_TEAM')}: ${result?.totalTeam ?? 0}`}
                    </div>
                </Stack>
                <div style={{ margin: '0px 10px 10px 10px', color: color }}>
                    {getListView()}
                </div>
            </div>
        )
    }
}

export default DownlineCommission