import { Stack } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { AlertDialogContext, CompanyInfoContext, UserInfoContext } from "../../App"
import BaseButton from "../../components/button/button"
import Constants from '../../constants'
import FeathersClient, { request } from "../../feathers/feathersClient"

function WhatsAppRegister() {
    let navigate = useNavigate()
    const search = useLocation().search;
    const referralCodeFromQueryString = new URLSearchParams(search).get('referralCode') ?? '';

    //Context
    const { setUserInfo } = useContext(UserInfoContext)
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { t } = useTranslation()
    const client = request({
        setAlertDialog: setAlertDialog, 
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    return (
        <div style={{ margin: '20px' }}>
            <div style={{ fontSize: '18px', color: color }} >
                <b>{t('WHATSAPP_REGISTRATION')}</b>
            </div>
            <div style={{ height: '20px' }} />
            <Stack direction='column' spacing='10px' style={{ fontSize: '14px', color: color }} >
                <div>{t('WHATSAPP_INFO_STEP1')}</div>
                <div>{t('WHATSAPP_INFO_STEP2')}</div>
                <div>{t('WHATSAPP_INFO_STEP3')}</div>
                <div>{t('WHATSAPP_INFO_STEP4')}</div>
            </Stack>
            <div style={{ height: '20px' }} />
            <div style={{ justifyContent: 'center', display: 'flex' }}>
                <BaseButton title={t('WHATSAPP_REGISTER_WITH_WHATSAPP')} color='#458f29' textColor='#ffffff' fullWidth={false} onTap={() => {
                    var data = {
                        company: companyInfo.company._id
                    }

                    if (referralCodeFromQueryString.length > 0) {
                        data['referralCode'] = referralCodeFromQueryString
                    }

                    client.create('get-company-whatsapp-link', data, null, (response) => {
                        let whatsappLink = response.whatsappLink ?? ''
                        window.open(whatsappLink, '_self')?.focus()
                    })
                }} />
            </div>
            <br />
            <br />
            <h4 style={{ textAlign: 'center', color: color }}>
                {t('AUTH_HAVE_ACCOUNT')}
            </h4>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
                <NavLink to="/login" style={{ color: color }}>
                    {t('AUTH_LOGIN_HERE')}
                </NavLink>
            </div>
            <br />
            <br />
        </div>
    )
}

export default WhatsAppRegister