import React, { useRef } from "react"
import Button from '@mui/material/Button'
import { styled } from "@mui/material/styles"
import Utils from "../../utils/utils"
import Constants from '../../constants'
import { Typography } from "@mui/material"
import { useTemplateVariables } from "../../utils/templateUtils"

export default function BaseButton({
  title,
  icon = null,
  color,
  textColor,
  type = 'button',
  disabled = false,
  borderRadius = '4px',
  padding = null,
  fullWidth = true,
  enableGradient = true,
  fontSize = '14px',
  fontWeight = null,
  lineHeight = null,
  onTap,
  customCSS = null,
  enableCustomCSS = true,
  className = 'otherButton',
}) {
  const template = useTemplateVariables()

  //Ref
  const lastTapped = useRef(Date.now())

  const buttonColor = color ?? template.otherButtonColor
  const buttonFontColor = textColor ?? template.otherButtonFontColor
  const gradientColor = Utils.shared.brighten(buttonColor, 0.5)
  const hoverColor = Utils.shared.brighten(buttonColor, 0.8)
  const disabledColor = Utils.shared.brighten(buttonColor, 0.6)
  const finalFontWeight = (template.design === Constants.templateDesignModern) ? (fontWeight ?? '600') : fontWeight

  const StyledButton = styled(Button)(() => ({
    ':hover': {
      backgroundColor: hoverColor,
      color: buttonFontColor,
    },
    ':disabled': {
      backgroundColor: disabledColor,
      color: buttonFontColor,
    },
    color: buttonFontColor,
    background: enableGradient ? `linear-gradient(${gradientColor}, ${buttonColor})` : buttonColor,
    whiteSpace: 'nowrap',
    minWidth: 'auto',
    borderRadius: borderRadius,
    padding: padding
  }))

  const tempCustomCSS = customCSS ?? template.otherButtonCSS

  return (
    <>
      {
        enableCustomCSS ? (tempCustomCSS ? (
          <style>
            {tempCustomCSS}
          </style>
        ) : null) : null
      }
      <StyledButton className={enableCustomCSS ? className : null} variant='contained' type={type} startIcon={icon} fullWidth={fullWidth} disabled={disabled} onClick={(e) => {
        if ((lastTapped.current > Date.now())) {
          return
        }

        onTap(e)

        //Set the last submitted time with additional 1 second
        lastTapped.current = Date.now() + 1000
      }}>
        <Typography sx={{
          fontSize: fontSize,
          fontWeight: finalFontWeight,
          lineHeight: lineHeight
        }}>
          {title}
        </Typography>
      </StyledButton>
    </>
  )
}