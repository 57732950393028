import { Box, Button } from "@mui/material"
import { useTemplateVariables } from "../../utils/templateUtils"
import Utils from "../../utils/utils"

export default function ButtonSelectable({ title, value, isSelected = false, onSelected }) {
    const template = useTemplateVariables()
    let bgColor = template.otherButtonColor
    let selectedEffectBgColor = Utils.shared.shadeColor(bgColor, 100)
    let hoverColor = Utils.shared.brighten(bgColor, 1.1)

    return (
        <Box sx={{
            borderRadius: '18px',
            border: isSelected ? `2px solid var(--db-500, ${template.otherButtonFontColor})` : null
        }}>
            <Button variant="contained" onClick={() => onSelected(value)} sx={{
                borderRadius: '16px',
                padding: '8px 16px',
                width: '100%',
                height: '100%',
                color: template.otherButtonFontColor,
                background: isSelected ? (`var(--diamond, linear-gradient(to bottom right, ${selectedEffectBgColor} 0%, ${bgColor} 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, ${selectedEffectBgColor} 0%, ${bgColor} 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, ${selectedEffectBgColor} 0%, ${bgColor} 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, ${selectedEffectBgColor} 0%, ${bgColor} 50%) top right / 50% 50% no-repeat)`) : bgColor,
                opacity: isSelected ? 1.0 : 0.7,
                textTransform: 'none',
                fontWeight: isSelected ? '700' : '400',
                whiteSpace: 'nowrap',
                boxShadow: '0px 4px 4px 0px rgba(23, 53, 89, 0.16)',
                ":hover": {
                    backgroundColor: hoverColor
                }
            }}>
                {title}
            </Button>
        </Box>
    )
}