import { Badge, Box, Button, Stack } from "@mui/material";
import Utils from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { AlertDialogContext, CompanyInfoContext, LoginPopupContext, UserInfoContext } from "../../App";
import { useTranslation } from "react-i18next";
import { Campaign, Person, ShareOutlined } from "@mui/icons-material";
import { useTemplateVariables } from "../../utils/templateUtils";
import Constants from '../../constants'
import { ChatIcon, HomeIcon } from "../customSVG";
import FeathersClient from '../../feathers/feathersClient'

export default function FloatingNavigationView() {
    const navigate = useNavigate()

    //CONTEXT
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const { setOpenLogin } = useContext(LoginPopupContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { t } = useTranslation()
    const template = useTemplateVariables()

    //STATE
    const [unreadMessages, setUnreadMessages] = useState(0)

    //REF
    const userInfoRef = useRef(userInfo)

    const getNavButton = (icon, title, isSelected, onTap) => {
        let unselectedColor = '#828282'

        return (
            <Button sx={{ padding: 0 }} onClick={() => {
                onTap()
            }}>
                <Stack alignItems={'center'} sx={{
                    color: isSelected ? template.navigationFontColor : unselectedColor
                }}>
                    {icon}
                    <Box sx={{
                        fontSize: '10px',
                        fontWeight: '600',
                        lineHeight: '12px',
                        letterSpacing: '0.8px',
                        textTransform: 'uppercase'
                    }}>
                        {title}
                    </Box>
                </Stack>
            </Button>
        )
    }
    const getBackgroundColor = () => {
        if (template.navigationBackgroundType === Constants.backgroundTypeColor) {
            let navigationBackgroundColor = companyInfo?.company?.navigationBackgroundColor
            if (navigationBackgroundColor) {
                return navigationBackgroundColor
            }
        }

        return 'black'
    }

    const homeSelected = window.location.pathname === '/'
    const shareSelected = template.design === Constants.templateDesignModern ? window.location.hash.startsWith('#referral') : window.location.pathname.startsWith('/share')
    const chatSelected = window.location.pathname.startsWith('/chat')
    const profileSelected = template.design === Constants.templateDesignModern ? window.location.hash.startsWith('#details') : window.location.pathname.startsWith('/profile')

    //SOCKET METHOD
    const newMessageListener = () => {
        FeathersClient.listen('messages', 'created', 'bottomBar', (data) => {
            var roomId = window.localStorage.getItem(`${Constants.keys_room_id}${window.location.host}`) ?? ''
            if (userInfoRef.current) {
                roomId = userInfoRef.current.rooms[0]?._id ?? ''
            }

            //Check if user is logged in or public user      
            if (data.room._id === roomId) {
                //Check if message is send by myself
                if (data.user?._id === userInfoRef.current?._id ||
                    data.deviceId === Utils.shared.getDeviceID()) {
                    return
                }

                new Audio('../../resources/sound/newMessageTone.mp3').play()

                //Only add red dot if user is not in live chat page
                if (window.location.pathname.indexOf('/chat') < 0) {
                    setUnreadMessages(unreadMessages + 1)

                    //Show notification
                    let message = data?.text ?? ''
                    let image = data?.image ?? ''
                    let hasAttachment = image.length > 0

                    Utils.shared.showNotification('New Message', hasAttachment ? 'Attachment' : message, companyInfo, () => {
                        navigate('/chat')
                    })
                }
            }
        })

        FeathersClient.listen('messages', 'patched', 'bottomBar', (data) => {
            var roomId = window.localStorage.getItem(`${Constants.keys_room_id}${window.location.host}`) ?? ''
            if (userInfoRef.current) {
                roomId = userInfoRef.current.rooms[0]?._id ?? ''
            }

            //Check if user is logged in or public user
            if (data.room._id === roomId && data.isRead) {
                setUnreadMessages(unreadMessages - 1)
            }
        })
    }

    useEffect(() => {
        newMessageListener()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        userInfoRef.current = userInfo
    }, [userInfo])

    return (
        <Box sx={{
            borderRadius: '24px',
            boxShadow: '4px 4px 4px 0px rgba(23, 53, 89, 0.16), 1px 0px 0px 0px rgba(23, 53, 89, 0.50)',
            backdropFilter: 'blur(2.5px)',
            backgroundColor: 'white'
        }}>
            <Stack sx={{
                backgroundColor: 'transparent',
                padding: '16px 0px'
            }} alignItems={'center'} spacing={'16px'}>
                {getNavButton(<HomeIcon fill={homeSelected ? template.navigationFontColor : '#828282'} />, t('BOTTOM_BAR_HOME'), homeSelected, () => {
                    Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                        navigate('/', { replace: true })
                    }, true)
                })}
                {getNavButton(<ShareOutlined />, t('BOTTOM_BAR_SHARE'), shareSelected, () => {
                    //Check if user is logged in
                    if (userInfo) {
                        navigate('/profile#referral')
                    } else {
                        Utils.shared.navigateLogin({
                            companyInfo: companyInfo,
                            navigate: navigate,
                            setOpenLogin: setOpenLogin
                        })
                    }
                })}
                <Box sx={{ height: '72px' }} />
                <Box sx={{
                    width: '72px',
                    height: '72px',
                    border: `4px solid var(--n-colour-n-0, ${getBackgroundColor()})`,
                    backgroundColor: template.navigationFontColor,
                    borderRadius: '40px',
                    boxShadow: '1.2px 0px 0px 0.1px rgba(23, 53, 89, 0.50)',
                    zIndex: 102,
                    position: 'fixed',
                    left: '8px',
                    top: 'calc(50% - 55px)'
                }}>
                    <Button sx={{ width: '100%', height: '100%' }} onClick={() => {
                        navigate('/promotions')
                    }}>
                        <Stack sx={{ height: '100%' }} alignItems={'center'} spacing='0px'>
                            <Campaign htmlColor={getBackgroundColor()} fontSize='large' viewBox="0 0 24 24" sx={{
                                rotate: '-20deg'
                            }} />
                            <div style={{ color: getBackgroundColor(), fontSize: '10px', lineHeight: '12px', fontWeight: '600', letterSpacing: '0.8px' }}>{'PROMO'}</div>
                        </Stack>
                    </Button>
                </Box>
                {getNavButton((
                    <Badge color='error' variant="dot" badgeContent={unreadMessages} invisible={unreadMessages <= 0}>
                        <ChatIcon fill={chatSelected ? template.navigationFontColor : '#828282'} />
                    </Badge>
                ), t('BOTTOM_BAR_LIVE_CHAT'), chatSelected, () => {
                    setUnreadMessages(0)
                    navigate('/chat')
                })}
                {getNavButton(<Person />, t('BOTTOM_BAR_PROFILE'), profileSelected, () => {
                    //Check if user is logged in
                    if (userInfo) {
                        navigate('/profile#details')
                    } else {
                        Utils.shared.navigateLogin({
                            companyInfo: companyInfo,
                            navigate: navigate,
                            setOpenLogin: setOpenLogin
                        })
                    }
                })}
            </Stack>
        </Box>
    )
}