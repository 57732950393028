import { useState, useContext, useCallback, useEffect } from "react"
import { AlertDialogContext, CompanyInfoContext, SnackBarContext, UserInfoContext } from "../../App"
import InputTextField from "../textfield/inputTextField"
import Constants from '../../constants'
import BaseButton from "../button/button"
import { request } from "../../feathers/feathersClient"
import { useNavigate } from "react-router-dom"
import { useTemplateVariables } from "../../utils/templateUtils"
import { Stack, Typography } from "@mui/material"

const defaultCount = 120;
const intervalGap = 1000;
const defaultSMSResendLimit = 3

// type - (normal/forgotPwd)
function VerificationView({ userId, contactNumber, remainingResend = defaultSMSResendLimit, type = 'normal', onVerificationCodeChanged = null, onVerificationSucceeded }) {
    const navigate = useNavigate()
    const template = useTemplateVariables()

    //Contexts
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setSnackBar } = useContext(SnackBarContext)
    const client = request({
        setAlertDialog: setAlertDialog, 
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })

    //States
    const [verificationCode, setVerificationCode] = useState('')
    const [user, setUser] = useState(null)
    const [verificationCodeError, setVerificationCodeError] = useState('')
    const [timerCount, setTimerCount] = useState(defaultCount)
    const [resendTimes, setResendTimes] = useState(remainingResend)

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo?.company?.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    var buttonColor = Constants.defaultMainColor
    let otherButtonColor = companyInfo?.company?.otherButtonColor
    if (otherButtonColor && otherButtonColor.length > 0) {
        buttonColor = otherButtonColor
    }

    var buttonFontColor = Constants.defaultMainColor
    let otherButtonFontColor = companyInfo?.company?.otherButtonFontColor
    if (otherButtonFontColor && otherButtonFontColor.length > 0) {
        buttonFontColor = otherButtonFontColor
    }

    //For Timer
    const startTimerWrapper = useCallback((func) => {
        let timeInterval;
        return () => {
            if (timeInterval) {
                clearInterval(timeInterval)
            }
            setTimerCount(defaultCount)
            timeInterval = setInterval(() => {
                func(timeInterval)
            }, intervalGap)
        }
    }, [setTimerCount])

    const timer = useCallback(
        startTimerWrapper((intervalfn) => {
            setTimerCount((val) => {
                if (val === 0) {
                    clearInterval(intervalfn);
                    return val
                }
                return val - 1
            })
        }), [setTimerCount, startTimerWrapper]
    )

    const getResendButton = () => {
        var counterDisplay = ''
        if (timerCount > 0) {
            counterDisplay = ` (${timerCount})`
        }

        const getButtonColor = () => {
            if (template.design === Constants.templateDesignModern) {
                return template.navigationFontColor
            }

            return template.otherButtonColor
        }
        const getButtonTextColor = () => {
            if (template.design === Constants.templateDesignModern) {
                return template.mainFontColor
            }

            return template.otherButtonFontColor
        }
        const getEnableGradient = () => {
            if (template.design === Constants.templateDesignModern) {
                return false
            }

            return true
        }
        const getBorderRadius = () => {
            if (template.design === Constants.templateDesignModern) {
                return '16px'
            }

            return null
        }

        return (
            <BaseButton title={`Resend${counterDisplay}`} color={getButtonColor()} textColor={getButtonTextColor()} enableGradient={getEnableGradient()} borderRadius={getBorderRadius()} disabled={timerCount > 0 || resendTimes === 0} onTap={() => {
                //Perform resend OTP
                switch (type) {
                    case 'forgotPwd':
                        client.create('authManagement', {
                            action: 'sendResetPwd',
                            value: {
                                contactNumber: contactNumber,
                                company: companyInfo?.company?._id
                            }
                        }, null, (response) => {
                            setSnackBar(snackBar => ({
                                ...snackBar,
                                title: 'Code resent successfully',
                                isOpen: true
                            }))

                            //Store user object
                            setUser(response)

                            //Check if should restart timer for resend
                            if (resendTimes > 0) {
                                setResendTimes(resendTimes => (resendTimes - 1))
                                timer()
                            }
                        })
                        break
                    default:
                        //Normal
                        client.create('authManagement', {
                            action: 'resendVerifySignup',
                            value: {
                                user: userId,
                                contactNumber: contactNumber,
                                company: companyInfo?.company?._id
                            }
                        }, null, () => {
                            setSnackBar(snackBar => ({
                                ...snackBar,
                                title: 'Code resent successfully',
                                isOpen: true
                            }))

                            //Check if should restart timer for resend
                            if (resendTimes > 0) {
                                setResendTimes(resendTimes => (resendTimes - 1))
                                timer()
                            }
                        })
                        break
                }

            }} />
        )
    }

    useEffect(() => {
        //Start timer for first resend
        if (resendTimes > 0) {
            timer()
        }
    }, [timer, setTimerCount])

    const performVerification = () => {
        if (verificationCode.length === 0) {
            setVerificationCodeError('Verification Code is mandatory')
            return
        }

        //Perform verification
        switch (type) {
            case 'forgotPwd':
                client.create('player-check-reset-password-otp', {
                    user: userId ?? user._id,
                    resetShortToken: verificationCode
                }, null, (response) => {
                    onVerificationSucceeded(response)
                })
                break
            default:
                client.create('authManagement', {
                    action: 'verifySignupShort',
                    value: {
                        user: {
                            _id: userId,
                            contactNumber: contactNumber,
                            company: companyInfo?.company?._id
                        },
                        token: verificationCode
                    },
                }, null, (response) => {
                    onVerificationSucceeded(response)
                })
                break
        }
    }

    if (template.design === Constants.templateDesignModern) {
        return (
            <Stack>
                <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '22px',
                    color: 'white'
                }}>
                    {`We've sent an SMS to your Mobile No. for verification`}
                </Typography>
                <div style={{ height: '16px' }} />
                <InputTextField
                    value={verificationCode}
                    placeholder="Enter the verification code from SMS"
                    showError={verificationCodeError.length > 0}
                    errorText={verificationCodeError}
                    titleColor={color}
                    suffix={getResendButton()}
                    onTextChanged={(value) => {
                        setVerificationCode(value)

                        //Callback for code changed
                        if (onVerificationCodeChanged) {
                            onVerificationCodeChanged(value)
                        }
                    }}
                    onSubmit={() => {
                        performVerification()
                    }} />
                <div style={{ height: '16px' }} />
                <BaseButton title={'VERIFY'} color={template.navigationFontColor} textColor={template.mainFontColor} borderRadius="16px" enableGradient={false} onTap={() => {
                    performVerification()
                }} />
            </Stack>
        )
    } else {
        return (
            <div style={{ borderRadius: '5px' }}>
                <div style={{ color: color }}>
                    <b>We've sent an SMS to your Mobile No. for verification</b>
                </div>
                <div style={{ height: '20px' }} />
                <InputTextField
                    title='Verification Code'
                    value={verificationCode}
                    placeholder="Enter the verification code from SMS"
                    helperText='Please enter the verification code from SMS'
                    showError={verificationCodeError.length > 0}
                    errorText={verificationCodeError}
                    titleColor={color}
                    suffix={getResendButton()}
                    onTextChanged={(value) => {
                        setVerificationCode(value)

                        //Callback for code changed
                        if (onVerificationCodeChanged) {
                            onVerificationCodeChanged(value)
                        }
                    }}
                    onSubmit={() => {
                        performVerification()
                    }} />
                <div style={{ height: '20px' }} />
                <BaseButton title={'VERIFY'} color={buttonColor} textColor={buttonFontColor} onTap={() => {
                    performVerification()
                }} />
            </div>
        )
    }
}

export default VerificationView