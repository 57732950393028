import React, { useContext, useEffect, useState } from "react";
import { BonusPopupContext, CompanyInfoContext, UserInfoContext } from '../../App';
import Constants, { templateDesignModern } from '../../constants'
import { Box, Button, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { useNavigate } from "react-router-dom";
import { request } from '../../feathers/feathersClient'
import Utils from "../../utils/utils";
import parse from 'html-react-parser'
import { KeyboardArrowRightRounded } from "@mui/icons-material";
import { useTemplateVariables } from "../../utils/templateUtils";
import '../../styles/hideScrollbarStyle.css'
import useScreenType from "../../hooks/useScreenType";

export const bonusGroupsWithBonus = (bonusGroups, bonus) => {
    if (bonusGroups && bonusGroups.length > 0) {
        return bonusGroups.filter(
            function getGroupWithBonusOnly(group) {
                if (bonus && bonus.length > 0) {
                    const matched = bonus.find(element => {
                        let isHidden = element?.isHidden ?? false

                        if (element.bonusGroup === group._id &&
                            !isHidden) {
                            return true
                        } else {
                            return false
                        }
                    });

                    return matched
                } else {
                    return false
                }
            }
        );
    } else {
        return []
    }
}
export const bonusByGroup = (group = null, bonus, companyInfo, templateDesign, screenType, bonusPopupContext, setBonusPopup) => {
    //Font
    const fontSize = screenType.isMobile ? '12px' : templateDesign === Constants.templateDesignModern ? '16px' : '12px'
    const amountFontSize = screenType.isMobile ? '22px' : '22px'
    let fontFamily = 'Arial, Helvetica'

    //Colors
    var angpaoFontColor = Constants.defaultMainColor
    const angpaoTextColor = companyInfo?.company?.angpaoTextColor
    if (angpaoTextColor && angpaoTextColor.length > 0) {
        angpaoFontColor = angpaoTextColor
    }

    let bonusImageType = group?.bonusImageType ?? Constants.bonusImageTypeNormal

    //Angpow Image Url
    var angpaoImageUrl = (bonusImageType === Constants.bonusImageTypeLong) ? `${process.env.PUBLIC_URL}/images/defaultAngpowLong.png` : `${process.env.PUBLIC_URL}/images/defaultAngpow.jpeg`
    let companyAngpaoImage = companyInfo.company.angpaoImage
    let companyAngpowImageLong = companyInfo.company.angpaoImageLong

    if (bonusImageType === Constants.bonusImageTypeLong) {
        if (companyAngpowImageLong && companyAngpowImageLong.length > 0) {
            angpaoImageUrl = Utils.shared.getCompanyImagesLink(companyInfo, companyAngpowImageLong, companyInfo.company._id)
        }
    } else {
        if (companyAngpaoImage && companyAngpaoImage.length > 0) {
            angpaoImageUrl = Utils.shared.getCompanyImagesLink(companyInfo, companyAngpaoImage, companyInfo.company._id)
        }
    }

    const bonuses = group ? bonus.filter(element => {
        return element.bonusGroup === group._id
    }) : bonus.slice(0, 5)

    return bonuses.map((e, index) => {
        const getHideLabel = () => {
            //check template
            let isHideLabel = e?.isHideLabel ?? false
            if (templateDesign === templateDesignModern) {
                if (e?.bonusGroupObject?.bonusImageType === Constants.bonusImageTypeLong) {
                    //Check if being displayed in promotions
                    let path = window.location.pathname ?? ''
                    if (path === '/') {
                        return false
                    }
                }
            }

            return isHideLabel
        }

        const bonusType = e.type
        const inputType = e.inputType
        const inputPercentage = e?.inputPercentage ?? ''
        const inputFixedAmount = e?.inputFixedAmount
        const bonusPayoutAmount = e?.bonusPayoutAmount ?? inputFixedAmount
        const currency = e.company.currencyCode ?? ''
        const isEligible = e.isEligible ?? false
        const angpowDisplay = (bonusImageType === Constants.bonusImageTypeLong) ? e?.angpowDisplayLong ?? '' : e?.angpowDisplay ?? ''
        const isHideLabel = getHideLabel()
        const isHidden = e?.isHidden ?? false
        let imageWidth = bonusImageType === Constants.bonusImageTypeLong ? '100%' : bonusImageWidth(templateDesign, screenType)
        let imageHeight = bonusImageType === Constants.bonusImageTypeLong ? 'auto' : bonusImageHeight(templateDesign, screenType)

        //Check if have custom angpow display
        var bonusAngpowImageUrl = angpaoImageUrl
        if (angpowDisplay.length > 0) {
            bonusAngpowImageUrl = Utils.shared.getBonusImagesLink(companyInfo, angpowDisplay)
        }

        const getBonusPayoutAmount = () => {
            //First check bonus type
            switch (bonusType) {
                case Constants.bonusTypeShare:
                    return Utils.shared.getAmountString(bonusPayoutAmount, currency, companyInfo?.company)
                default:
                    //If not share, check input type
                    switch (inputType) {
                        case Constants.bonusInputTypePercentage:
                            if (isEligible) {
                                return Utils.shared.getAmountString(bonusPayoutAmount, currency, companyInfo?.company)
                            } else {
                                return `${inputPercentage}%`;
                            }
                        case Constants.bonusInputTypeFixedAmount:
                            return Utils.shared.getAmountString(inputFixedAmount, currency, companyInfo?.company)
                        default:
                            return `${currency}?`;
                    }
            }
        }

        //Check if angpow should be hidden
        if (isHidden) {
            return null
        }

        const getLabelView = () => {
            if (isHideLabel) {
                //Hide
                return null
            }

            if (templateDesign === templateDesignModern) {
                return (
                    <Stack alignItems={'center'} justifyContent={'center'} sx={{
                        zIndex: 2,
                        position: 'absolute'
                    }} spacing={'4px'}>
                        <Typography sx={{
                            color: angpaoFontColor,
                            textAlign: 'center',
                            fontSize: fontSize,
                            fontFamily: fontFamily,
                            lineHeight: fontSize,
                        }}>
                            <b>{getBonusPayoutAmount()}</b>
                        </Typography>
                        <Typography sx={{
                            color: angpaoFontColor,
                            textAlign: 'center',
                            fontSize: fontSize,
                            fontFamily: fontFamily,
                            lineHeight: fontSize,
                        }}>
                            <b>{e.name}</b>
                        </Typography>
                    </Stack>
                )
            } else {
                return (
                    <>
                        <div style={{ position: 'absolute', top: 10, left: 5, right: 5, color: angpaoFontColor, zIndex: 2, textAlign: 'center', fontSize: fontSize, fontFamily: fontFamily, filter: `brightness(${isEligible ? '100%' : '60%'})` }}>
                            <b>{e.name}</b>
                        </div>
                        <div style={{ position: 'absolute', top: '50%', left: 5, right: 5, color: angpaoFontColor, zIndex: 2, textAlign: 'center', fontSize: amountFontSize, fontFamily: fontFamily, filter: `brightness(${isEligible ? '100%' : '60%'})` }}>
                            <b>{getBonusPayoutAmount()}</b>
                        </div>
                    </>
                )
            }
        }

        const angpaoCSS = companyInfo?.company?.angpaoCSS

        return (
            <Grid item>
                <>
                    <style>
                        {angpaoCSS}
                    </style>
                    <Box className="angpao" sx={{ position: 'relative', width: { bonusImageWidth: imageWidth }, height: { bonusImageHeight: imageHeight } }} key={`bonusContainer${index}`}>
                        <Button sx={{ backgroundColor: 'transparent', width: { bonusImageWidth: imageWidth }, height: { bonusImageHeight: imageHeight }, padding: 0 }} onClick={() => {
                            //Show bonus popup
                            setBonusPopup(bonusPopup => ({
                                ...bonusPopup,
                                bonus: e,
                                requirement: bonusPopupContext?.requirement,
                                callingBonusRequirement: bonusPopupContext?.callingBonusRequirement,
                            }))
                        }} >
                            <img src={bonusAngpowImageUrl} alt={e.name} width={imageWidth} height={imageHeight} loading="lazy" style={{ zIndex: 1, filter: `brightness(${isEligible ? '100%' : '60%'})` }}>
                            </img>
                            {getLabelView()}
                        </Button>
                    </Box>
                </>
            </Grid>
        )
    })
}
export const bonusImageWidth = (templateDesign, screenType) => {
    if (templateDesign === Constants.templateDesignModern) {
        return screenType.isMobile || screenType.isTablet ? '130px' : '160px'
    }

    return screenType.isMobile ? '107px' : '107px'
}
export const bonusImageHeight = (templateDesign, screenType) => {
    if (templateDesign === Constants.templateDesignModern) {
        return screenType.isMobile || screenType.isTablet ? '164px' : '202px'
    }

    return screenType.isMobile ? '128px' : '128px'
}
export default function BonusListView() {
    const navigate = useNavigate()

    //Contexts
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const { bonusPopup, setBonusPopup } = useContext(BonusPopupContext)
    const client = request({
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })

    //States
    const [isLoading, setIsLoading] = useState(false)

    const screenType = useScreenType()
    const template = useTemplateVariables()
    const templateDesign = companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic

    //Styles
    const styles = {
        gridLayout: {
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fill, ${bonusImageWidth(templateDesign, screenType)})`,
            gridAutoRows: bonusImageHeight(templateDesign, screenType),
            gridColumnGap: '10px',
            gridRowGap: '10px',
            width: '100%',
        }
    }

    //Theme Color
    var color = Constants.defaultMainColor
    const mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    //API Method
    const getPlayerSiteCompanyBonus = () => {
        setIsLoading(true)

        client.createREST('get-player-site-company-bonus', {
            secretKey: 'thebestsecretkey',
            company: companyInfo?.company?._id,
            platform: Utils.shared.getCurrentPlatform() === 'app' ? Constants.bonusPlatformApp : Constants.bonusPlatformWeb
        }, (response) => {
            setBonusPopup(bonusPopup => ({
                ...bonusPopup,
                bonusResult: response
            }))
        }, null, false, () => {
            setIsLoading(false)
        })
    }

    const bonusGroups = bonusPopup?.bonusResult?.bonusGroups
    const bonus = bonusPopup?.bonusResult?.bonus?.map((e) => {
        var tempBonus = e
        tempBonus.bonusGroupObject = bonusGroups?.find((group) => { return group?._id === e?.bonusGroup })
        return tempBonus
    }) ?? []

    //COMPONENT METHODS
    const getSkeletonView = () => {
        let shimmerColor = 'rgba(255, 255, 255, 0.2)'

        const getBonusSkeletonView = () => {
            return (
                <Skeleton animation="wave" variant='rounded' width={bonusImageWidth(templateDesign, screenType)} height={bonusImageHeight(templateDesign, screenType)} sx={{ bgcolor: shimmerColor }} />
            )
        }

        if (templateDesign === Constants.templateDesignModern) {
            return (
                <Stack spacing={'24px'} direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
                    {getBonusSkeletonView()}
                    {getBonusSkeletonView()}
                    {getBonusSkeletonView()}
                    {getBonusSkeletonView()}
                    {getBonusSkeletonView()}
                </Stack >
            )
        } else {
            return (
                <Stack spacing={1} sx={{ width: '100%' }}>
                    <div style={styles.gridLayout}>
                        {getBonusSkeletonView()}
                        {getBonusSkeletonView()}
                        {getBonusSkeletonView()}
                    </div>
                    <Skeleton animation="wave" variant="text" width='80px' sx={{ fontSize: '1rem', bgcolor: shimmerColor }} />
                    <div style={styles.gridLayout}>
                        {getBonusSkeletonView()}
                        {getBonusSkeletonView()}
                        {getBonusSkeletonView()}
                    </div>
                </Stack >
            )
        }
    }
    const getMainView = () => {
        //Only show bonus list view if there is any bonus
        if (bonusGroupsWithBonus(bonusGroups, bonus).length > 0) {
            //Check template design
            switch (templateDesign) {
                case Constants.templateDesignModern:
                    const getSpacing = () => {
                        if (screenType.isMobile || screenType.isTablet) {
                            return '8px'
                        }

                        return '24px'
                    }
                    const getJustifyContent = () => {
                        if (screenType.isMobile || screenType.isTablet) {
                            return 'start'
                        }

                        return 'center'
                    }

                    return (
                        <Box className="hideScrollBar" sx={{ paddingTop: '16px', width: '100%', overflowX: 'scroll' }}>
                            <Stack direction={'row'} spacing={getSpacing()} alignItems={'center'} justifyContent={getJustifyContent()}>
                                <Box sx={{ width: '16px' }} />
                                {bonusByGroup(null, bonus, companyInfo, templateDesign, screenType, bonusPopup, setBonusPopup)}
                                <Box sx={{ paddingRight: '4px' }}>
                                    <Button onClick={() => {
                                        navigate('/promotions')
                                    }}>
                                        <Box sx={{ backgroundColor: '#F4F6F8', borderRadius: '16px' }} alignItems={'center'}>
                                            <div style={{ padding: '16px 36px', color: 'black', maxLines: 1, whiteSpace: 'nowrap' }}>{'VIEW MORE'}</div>
                                        </Box>
                                        <Stack sx={{ alignItems: 'right', width: '100%', position: 'relative', paddingRight: '12px' }}>
                                            <Box sx={{ borderRadius: '12px', height: '24px', width: '24px', backgroundColor: template.navigationFontColor, position: 'absolute', top: 'calc(50% - 12px)', right: 0 }}>
                                                <KeyboardArrowRightRounded htmlColor={'#173559'} />
                                            </Box>
                                        </Stack>
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    )
                default:
                    return bonusGroupsWithBonus(bonusGroups, bonus).map((group, index) => {
                        let isHideLabel = group?.isHideLabel ?? false
                        let bonusImageType = group?.bonusImageType ?? Constants.bonusImageTypeNormal

                        return (
                            <Stack key={group?._id} sx={{ marginTop: '5px', marginBottom: '5px' }}>
                                {/* Check if should hide group label */}
                                <div style={{ color: color, marginBottom: '5px', fontSize: '15px', fontFamily: 'Arial' }}>
                                    {
                                        isHideLabel ? null : (
                                            <b>{parse(group.name)}</b>
                                        )
                                    }
                                </div>
                                {/* Check bonus image type of group */}
                                {
                                    bonusImageType === Constants.bonusImageTypeLong ? (
                                        <Stack spacing={'10px'}>
                                            {bonusByGroup(group, bonus, companyInfo, templateDesign, screenType, bonusPopup, setBonusPopup)}
                                        </Stack>
                                    ) : (
                                        <div style={styles.gridLayout}>
                                            {bonusByGroup(group, bonus, companyInfo, templateDesign, screenType, bonusPopup, setBonusPopup)}
                                        </div>
                                    )
                                }
                            </Stack>
                        )
                    })
            }
        } else {
            return null
        }
    }

    //Use Effect
    useEffect(() => {
        getPlayerSiteCompanyBonus()
    }, [])

    if (isLoading) {
        return getSkeletonView()
    } else {
        return getMainView()
    }
}