import React, { useContext, useState, useEffect } from "react";
import { AlertDialogContext, CompanyInfoContext, ForgotPwdPopupContext, LoginPopupContext, PopupContext, RegisterPopupContext, UserInfoContext } from '../../App'
import Constants, { templateDesignModern } from '../../constants'
import InputTextField from '../../components/textfield/inputTextField'
import BaseButton from "../../components/button/button";
import { useNavigate, NavLink } from "react-router-dom";
import { request } from "../../feathers/feathersClient";
import Utils from "../../utils/utils";
import VerificationView from '../../components/auth/verificationView'
import { useTranslation } from "react-i18next"
import { Box, Button, Checkbox, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useTemplateVariables } from "../../utils/templateUtils";
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, CheckCircle, Circle, CircleOutlined, Close } from "@mui/icons-material";
import LoginSideImage from '../../images/svg/loginSideImage.svg'
import useScreenType from "../../hooks/useScreenType";

function Login() {
    const navigate = useNavigate()
    const template = useTemplateVariables()
    const screenType = useScreenType()

    // Context
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setPopup } = useContext(PopupContext)
    const { setOpenLogin } = useContext(LoginPopupContext)
    const { setOpenRegister } = useContext(RegisterPopupContext)
    const { setOpenForgotPwd } = useContext(ForgotPwdPopupContext)
    const { t } = useTranslation()
    const client = request({
        setAlertDialog: setAlertDialog,
        setCompanyInfo: setCompanyInfo,
        setUserInfo: setUserInfo,
        navigate: navigate
    })

    // States
    const [values, setValues] = useState({
        contactNumber: '',
        contactNumberPhoneCode: '',
        contactNumberError: '',
        password: '',
        passwordError: '',
        rememberMe: false,
    })

    const [isOTPMode, setIsOTPMode] = useState(false)
    const [otpValues, setOTPValues] = useState({
        verificationCode: '',
        verificationCodeError: '',
        userId: '',
        contactNumber: '',
        smsResendRemaining: 3,
    })

    //Styles
    const styles = {
        modern: {
            desktop: {
                backgroundColor: template.loginPageTheme.secondaryColor,
                width: 'calc(60% - 32px)',
                height: 'calc(100% - 32px)',
                position: 'absolute',
                right: 0,
                padding: '16px',
                borderRadius: '32px',
                overflow: 'hidden'
            },
            mobile: {
                backgroundColor: template.loginPageTheme.secondaryColor,
                padding: '16px',
                overflow: 'hidden',
                height: '100%'
            }
        }
    }

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    let loginPageHeaderImage = companyInfo?.company?.loginPageHeaderImage ?? ''
    const hasLoginPageHeader = loginPageHeaderImage.length > 0
    const loginPageHeaderImageUrl = Utils.shared.getCompanyImagesLink(companyInfo, loginPageHeaderImage, companyInfo?.company?._id)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //API Methods
    function performLogin() {
        if (values.contactNumber.length === 0) {
            setValues(values => ({ ...values, contactNumberError: `${t('AUTH_MOBILE_NUMBER')} ${t('GLOBAL_IS_MANDATORY')}` }))
            return
        }

        if (values.password.length === 0) {
            setValues(values => ({ ...values, passwordError: `${t('AUTH_PASSWORD')} ${t('GLOBAL_IS_MANDATORY')}` }))
            return
        }

        client.authenticate(
            `${values.contactNumberPhoneCode}${values.contactNumber}`,
            values.password,
            companyInfo.company._id,
            setUserInfo,
            () => {
                Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                    setOpenLogin(false)
                    navigate('/', { replace: true })
                }, true)
            },
            (error) => {
                //Check if OTP required
                if (error.code === Constants.feathers_error_code_otp_required) {
                    let data = error?.data

                    setOTPValues(otpValues => ({
                        ...otpValues,
                        verificationCode: '',
                        verificationCodeError: '',
                        userId: data?.userId,
                        contactNumber: data?.contactNumber,
                        smsResendRemaining: data?.smsResendLimit ?? 3
                    }))
                    setIsOTPMode(true)
                } else {
                    setAlertDialog(alertDialog => ({
                        ...alertDialog,
                        title: error?.name,
                        translationData: error?.data,
                        description: error.message,
                        isOpen: true
                    }))
                }
            }
        )
    }

    if (template.design === Constants.templateDesignModern) {
        return (
            <Box sx={{
                backgroundColor: template.loginPageTheme.primaryColor,
                height: '100%',
                width: '100%',
                position: 'relative'
            }}>
                <Stack sx={screenType.isMobile ? styles.modern.mobile : styles.modern.desktop} alignItems={'start'} justifyContent={'center'}>
                    {
                        isOTPMode ? (
                            //OTP Mode
                            <VerificationView userId={otpValues.userId} contactNumber={otpValues.contactNumber} remainingResend={otpValues.smsResendRemaining} onVerificationSucceeded={(auth) => {
                                //Store user
                                setUserInfo(auth.user)

                                //Store token
                                window.localStorage.setItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`, auth.accessToken)

                                //Authenticate feathers
                                client.reauthenticate(() => {
                                    Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                                        navigate('/', { replace: true })
                                    }, true)
                                })
                            }} />
                        ) : (
                            <Stack sx={{
                                padding: '32px',
                                width: 'calc(100% - 64px)'
                            }} spacing='32px'>
                                <Stack>
                                    <Typography sx={{
                                        fontSize: '18px',
                                        fontWeight: '700',
                                        lineHeight: '26px',
                                        color: template.loginPageTheme.primaryColor
                                    }}>
                                        Login
                                    </Typography>
                                    <Divider sx={{
                                        height: '2px',
                                        backgroundColor: 'white',
                                        width: '40%'
                                    }} />
                                    <Stack direction={'row'} spacing='4px'>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '22px',
                                            color: '#585858'
                                        }}>
                                            {t('AUTH_NO_ACCOUNT_YET')}
                                        </Typography>
                                        <Button sx={{
                                            padding: '0px',
                                            textTransform: 'none',
                                            minWidth: '0px'
                                        }} onClick={() => {
                                            setOpenLogin(false)
                                            Utils.shared.showRegisterPopup({
                                                companyInfo: companyInfo,
                                                setAlertDialog: setAlertDialog,
                                                setPopup: setPopup,
                                                navigate: navigate,
                                                referralCode: null,
                                                setOpenRegister: setOpenRegister
                                            })
                                        }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                lineHeight: '22px',
                                                color: template.loginPageTheme.primaryColor,
                                            }}>
                                                {t('AUTH_REGISTER')}
                                            </Typography>
                                        </Button>
                                    </Stack>
                                </Stack>
                                <Stack spacing='0px'>
                                    <Stack spacing='8px'>
                                        <InputTextField
                                            value={values.contactNumber}
                                            type='tel'
                                            placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_MOBILE_NUMBER')}`}
                                            showError={values.contactNumberError.length > 0}
                                            errorText={values.contactNumberError}
                                            onTextChanged={(value) => {
                                                setValues(values => ({
                                                    ...values,
                                                    contactNumber: value,
                                                    contactNumberError: ''
                                                }))
                                            }}
                                            onSubmit={() => {
                                                performLogin()
                                            }}
                                            onPhoneCodeChanged={(value) => {
                                                setValues(values => ({
                                                    ...values,
                                                    contactNumberPhoneCode: value,
                                                    contactNumberError: ''
                                                }))
                                            }} />
                                        <InputTextField
                                            value={values.password}
                                            type='password'
                                            placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_PASSWORD')}`}
                                            showError={values.passwordError.length > 0}
                                            errorText={values.passwordError}
                                            onTextChanged={(value) => {
                                                setValues(values => ({
                                                    ...values,
                                                    password: value,
                                                    passwordError: ''
                                                }))
                                            }}
                                            onSubmit={() => {
                                                performLogin()
                                            }} />
                                    </Stack>
                                    <Stack sx={{
                                        padding: '0px 8px'
                                    }}>
                                        <Stack direction='row' alignItems={'center'}>
                                            <Checkbox checked={values.rememberMe} icon={<CircleOutlined htmlColor="#828282" />} checkedIcon={<CheckCircle htmlColor="#828282" />} onChange={(e, checked) => {
                                                setValues(values => ({
                                                    ...values,
                                                    rememberMe: checked
                                                }))
                                            }} />
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                lineHeight: '22px',
                                                color: template.loginPageTheme.primaryColor
                                            }}>
                                                {'Remember Me'}
                                            </Typography>
                                            <Box sx={{ flexGrow: 1 }} />
                                            <Button sx={{
                                                textTransform: 'none'
                                            }} onClick={() => {
                                                setOpenLogin(false)
                                                Utils.shared.showForgotPasswordPopup({
                                                    companyInfo: companyInfo,
                                                    setPopup: setPopup,
                                                    setAlertDialog: setAlertDialog,
                                                    navigate: navigate,
                                                    setOpenForgotPwd: setOpenForgotPwd
                                                })
                                            }}>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    lineHeight: '22px',
                                                    color: template.loginPageTheme.primaryColor
                                                }}>
                                                    {'Forgot Password'}
                                                </Typography>
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <BaseButton title={t('AUTH_LOGIN')} color={template.loginPageTheme.primaryColor} textColor={template.loginPageTheme.secondaryColor} borderRadius="16px" fullWidth={true} enableGradient={false} className="" onTap={(event) => {
                                    //Perform login
                                    performLogin()
                                }} />
                                <Stack direction={'row'} spacing='4px'>
                                    <Button sx={{
                                        padding: '0px',
                                        textTransform: 'none'
                                    }} onClick={() => {
                                        setOpenLogin(false)
                                        navigate('/chat')
                                    }}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '22px',
                                            color: template.loginPageTheme.primaryColor
                                        }}>
                                            {t('AUTH_NEED_HELP_CLICK_HERE')}
                                        </Typography>
                                    </Button>
                                </Stack>
                            </Stack>
                        )
                    }

                    <IconButton onClick={() => {
                        setOpenLogin(false)
                    }} size="small" sx={{
                        backgroundColor: template.loginPageTheme.primaryColor,
                        position: 'absolute',
                        top: 16,
                        right: 16
                    }}>
                        <Close htmlColor={template.loginPageTheme.secondaryColor} fontSize="small" />
                    </IconButton>
                </Stack>
                {
                    screenType.isMobile ? null : (
                        <Stack direction={'row'} sx={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            alignItems: 'end'
                        }}>
                            <img src={LoginSideImage} height={'100%'} width={'90%'} />
                        </Stack>
                    )
                }
            </Box>
        )
    } else {
        return isOTPMode ? (
            //OTP Mode
            <div style={{ margin: '20px' }}>
                <VerificationView userId={otpValues.userId} contactNumber={otpValues.contactNumber} remainingResend={otpValues.smsResendRemaining} onVerificationSucceeded={(auth) => {
                    setOpenLogin(false)

                    //Store user
                    setUserInfo(auth.user)

                    //Store token
                    window.localStorage.setItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`, auth.accessToken)

                    //Authenticate feathers
                    client.reauthenticate(() => {
                        Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                            navigate('/', { replace: true })
                        }, true)
                    })
                }} />
            </div>
        ) : (
            //Login Mode
            <Stack direction={screenType.isMobile ? 'column' : 'row'}>
                {
                    hasLoginPageHeader ? (
                        <img src={loginPageHeaderImageUrl} alt='loginBannerImage' height={screenType.isMobile ? 'auto' : '100%'} width={screenType.isMobile ? '100%' : '50%'} />
                    ) : null
                }
                <Stack spacing={'20px'} sx={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: '40px', width: 'calc(100% - 40px)', minWidth: '300px' }}>
                    <h3 style={{ textAlign: 'center', color: color }}>
                        {t('AUTH_TITLE')}
                    </h3>
                    <InputTextField
                        title={t('AUTH_MOBILE_NUMBER')}
                        value={values.contactNumber}
                        type='tel'
                        placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_MOBILE_NUMBER')}`}
                        helperText={t('AUTH_MOBILE_NUMBER_HELPER')}
                        showError={values.contactNumberError.length > 0}
                        errorText={values.contactNumberError}
                        onTextChanged={(value) => {
                            setValues(values => ({
                                ...values,
                                contactNumber: value,
                                contactNumberError: ''
                            }))
                        }}
                        onSubmit={() => {
                            performLogin()
                        }}
                        onPhoneCodeChanged={(value) => {
                            setValues(values => ({
                                ...values,
                                contactNumberPhoneCode: value,
                                contactNumberError: ''
                            }))
                        }} />
                    <InputTextField
                        title={t('AUTH_PASSWORD')}
                        value={values.password}
                        type='password'
                        placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_PASSWORD')}`}
                        helperText={t('AUTH_PASSWORD_HELPER')}
                        showError={values.passwordError.length > 0}
                        errorText={values.passwordError}
                        onTextChanged={(value) => {
                            setValues(values => ({
                                ...values,
                                password: value,
                                passwordError: ''
                            }))
                        }}
                        onSubmit={() => {
                            performLogin()
                        }} />
                    <div style={{ justifyContent: 'center', display: 'flex', marginBottom: '10px' }}>
                        <BaseButton title={t('AUTH_LOGIN')} color='#458f29' textColor='#ffffff' fullWidth={false} onTap={(event) => {
                            //Perform login
                            performLogin()
                        }} />
                    </div>
                    <h4 style={{ textAlign: 'center', color: color }}>
                        {t('AUTH_NO_ACCOUNT_YET')}
                    </h4>
                    <Stack spacing={'30px'}>
                        <div style={{ justifyContent: 'center', display: 'flex' }}>
                            <BaseButton title={t('AUTH_REGISTER')} color='#3c77a3' textColor='#ffffff' fullWidth={false} onTap={() => {
                                //Go to register page
                                Utils.shared.showRegisterPopup({
                                    companyInfo: companyInfo,
                                    setAlertDialog: setAlertDialog,
                                    setPopup: setPopup,
                                    navigate: navigate,
                                    referralCode: null,
                                    setOpenRegister: setOpenRegister
                                })
                            }} />
                        </div>
                        <Stack>
                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                <Button sx={{ textTransform: 'none', color: color }} onClick={() => {
                                    Utils.shared.showForgotPasswordPopup({
                                        companyInfo: companyInfo,
                                        setPopup: setPopup,
                                        setAlertDialog: setAlertDialog,
                                        navigate: navigate,
                                        setOpenForgotPwd: setOpenForgotPwd
                                    })
                                }}>
                                    {t('AUTH_FORGOT_PWD_CLICK_HERE')}
                                </Button>
                            </div>
                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                <Button sx={{ textTransform: 'none', color: color }} onClick={() => {
                                    navigate('/chat')
                                }}>
                                    {t('AUTH_NEED_HELP_CLICK_HERE')}
                                </Button>
                            </div>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        )
    }
}

export default Login;