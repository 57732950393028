import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import FeathersClient, { request } from "../../feathers/feathersClient";
import { CompanyInfoContext, LoginPopupContext, UserInfoContext } from '../../App'
import Utils from "../../utils/utils";

function RequireAuth({ children }) {
    const { setUserInfo } = useContext(UserInfoContext)
    const { companyInfo } = useContext(CompanyInfoContext)
    const { setOpenLogin } = useContext(LoginPopupContext)
    const navigate = useNavigate()
    const client = request({
        setUserInfo: setUserInfo
    })

    useEffect(() => {
        client.reauthenticate(
            null,
            () => {
                setUserInfo(null)

                Utils.shared.navigateLogin({
                    companyInfo: companyInfo,
                    navigate: navigate,
                    setOpenLogin: setOpenLogin
                })
            },
        )
    }, [])

    return children
}

export default RequireAuth