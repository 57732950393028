import { Grid } from "@mui/material"
import AmountView from "./amountView"
import Utils from "../../utils/utils"
import { useUserInfo } from "../../hooks/useUserInfo"
import { useCompanyInfo } from "../../hooks/useCompanyInfo"
import { useTemplateVariables } from "../../utils/templateUtils"
import { useTranslation } from "react-i18next"
import Constants from '../../constants'

export default function MinWithdrawalDetailsView() {
    const user = useUserInfo()
    const company = useCompanyInfo()
    const template = useTemplateVariables()
    const { t } = useTranslation()

    const playerTurnover = user?.playerTurnover ?? 0
    const minWithdrawal = company?.minWithdrawal ?? 0
    const currencyCode = company?.currencyCode ?? ''

    var finalValue = 0

    if (minWithdrawal > 0) {
        if (playerTurnover >= minWithdrawal) {
            finalValue = playerTurnover
        } else {
            finalValue = minWithdrawal
        }
    } else {
        finalValue = playerTurnover
    }

    const title = t('GLOBAL_MIN_WITHDRAWAL')

    if (template.design === Constants.templateDesignModern) {
        return (
            <Grid item flexGrow={1}>
                <AmountView title={title} value={Utils.shared.getAmountString(finalValue, '', company)} />
            </Grid>
        )
    } else {
        return (
            <div style={{ fontSize: '12px' }}>
                {`${title} : ${Utils.shared.getAmountString(finalValue, currencyCode, company)}`}
            </div>
        )
    }
}