import { Button, Stack, Typography } from "@mui/material"
import Utils from "../../utils/utils"
import { useNavigate } from "react-router-dom"
import { useUserInfo } from "../../hooks/useUserInfo"
import { useTemplateVariables } from "../../utils/templateUtils"
import { useTranslation } from "react-i18next"
import { AlertDialogContext, CompanyInfoContext } from "../../App"
import { useContext } from "react"
import { WithdrawalIcon } from "../customSVG"
import { templateDesignModern } from "../../constants"
import { Outbox } from "@mui/icons-material"
import BaseButton from "./button"

export default function WithdrawalButton({ invertColor = false, buttonOnly = false }) {
    const navigate = useNavigate()
    const user = useUserInfo()
    const template = useTemplateVariables()
    const { t } = useTranslation()

    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)

    const withdrawalButtonCSS = companyInfo?.company?.withdrawalButtonCSS
    const getLabel = () => {
        return (
            <Typography style={{
                fontSize: '8px',
                fontWeight: '600',
                lineHeight: '120%',
                textTransform: 'uppercase'
            }}>
                {t('HOME_WITHDRAWAL')}
            </Typography>
        )
    }

    if (template.design === templateDesignModern) {
        return (buttonOnly) ? (
            <BaseButton title={t('HOME_WITHDRAWAL')} customCSS={withdrawalButtonCSS ?? ''} className={'withdrawalButton'} color={template.withdrawalButtonColor} textColor={template.withdrawalButtonFontColor} borderRadius="16px" fontWeight={'600'} enableGradient={false} onTap={(e) => {
                Utils.shared.navigateWithdrawal(user, companyInfo, setAlertDialog, navigate)
            }} />
        ) : (
            <>
                <style>
                    {withdrawalButtonCSS}
                </style>
                <Button sx={{
                    padding: 0,
                    margin: 0,
                    minWidth: '0px'
                }} className="withdrawalButton" onClick={() => {
                    Utils.shared.navigateWithdrawal(user, companyInfo, setAlertDialog, navigate)
                }}>
                    {
                        withdrawalButtonCSS ? getLabel() : (
                            <Stack alignItems={'center'} sx={{
                                color: invertColor ? Utils.shared.shadeColor(template.navigationFontColor, 4) : template.mainFontColor,
                            }} spacing='2px'>
                                <WithdrawalIcon fill={template.navigationFontColor} secondary={invertColor ? Utils.shared.shadeColor(template.navigationFontColor, 4) : null} />
                                {getLabel()}
                            </Stack>
                        )
                    }
                </Button>
            </>
        )
    } else {
        return (
            <BaseButton title={t('HOME_WITHDRAWAL')} icon={<Outbox />} color={template.withdrawalButtonColor} textColor={template.withdrawalButtonFontColor} className={'withdrawalButton'} customCSS={withdrawalButtonCSS} onTap={(e) => {
                Utils.shared.navigateWithdrawal(user, companyInfo, setAlertDialog, navigate)
            }} />
        )
    }
}