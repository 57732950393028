import { Button, Stack, Typography } from "@mui/material"
import Utils from "../../utils/utils"
import { useUserInfo } from "../../hooks/useUserInfo"
import { AlertDialogContext, CompanyInfoContext } from "../../App"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useTemplateVariables } from "../../utils/templateUtils"
import { useTranslation } from "react-i18next"
import { DepositIcon } from "../customSVG"
import { templateDesignModern } from "../../constants"
import { MoveToInbox } from "@mui/icons-material"
import BaseButton from "./button"

export default function DepositButton({ invertColor = false, buttonOnly = false }) {
    const navigate = useNavigate()
    const user = useUserInfo()
    const template = useTemplateVariables()
    const { t } = useTranslation()

    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)

    const depositButtonCSS = companyInfo?.company?.depositButtonCSS
    const getLabel = () => {
        return (
            <Typography style={{
                fontSize: '8px',
                fontWeight: '600',
                lineHeight: '120%',
                textTransform: 'uppercase'
            }}>
                {t('HOME_DEPOSIT')}
            </Typography>
        )
    }

    if (template.design === templateDesignModern) {
        return (buttonOnly) ? (
            <BaseButton title={t('HOME_DEPOSIT')} customCSS={depositButtonCSS ?? ''} className="depositButton" color={template.depositButtonColor} textColor={template.depositButtonFontColor} borderRadius="16px" fontWeight={'600'} enableGradient={false} onTap={(e) => {
                Utils.shared.navigateDeposit(user, companyInfo, setAlertDialog, navigate)
            }} />
        ) : (
            <>
                <style>
                    {depositButtonCSS}
                </style>
                <Button sx={{
                    padding: 0,
                    margin: 0,
                    minWidth: '0px'
                }} className="depositButton" onClick={() => {
                    Utils.shared.navigateDeposit(user, companyInfo, setAlertDialog, navigate)
                }}>
                    {
                        (depositButtonCSS) ? getLabel() : (
                            <Stack alignItems={'center'} sx={{
                                color: invertColor ? Utils.shared.shadeColor(template.navigationFontColor, 4) : template.mainFontColor,
                            }} spacing={'2px'}>
                                <DepositIcon fill={template.navigationFontColor} secondary={invertColor ? Utils.shared.shadeColor(template.navigationFontColor, 4) : null} />
                                {getLabel()}
                            </Stack>
                        )
                    }
                </Button>
            </>
        )
    } else {
        return (
            <BaseButton className="depositButton" title={t('HOME_DEPOSIT')} icon={<MoveToInbox />} customCSS={depositButtonCSS} color={template.depositButtonColor} textColor={template.depositButtonFontColor} onTap={(e) => {
                Utils.shared.navigateDeposit(user, companyInfo, setAlertDialog, navigate)
            }} />
        )
    }
}