import { Stack } from '@mui/material';
import Constants from '../../constants'
import BaseButton from '../button/button';
import { PopupContext, RegisterPopupContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useTemplateVariables } from '../../utils/templateUtils';

export default function RegisterPopup({ memberRegistrationMethod, referralCode, onTap }) {
    const navigate = useNavigate()
    const template = useTemplateVariables()

    //Contexts
    const { setOpenRegister } = useContext(RegisterPopupContext)
    const { popup, setPopup } = useContext(PopupContext)
    const { t } = useTranslation()
    const whatsappMethod = 'whatsapp'
    const telegramMethod = 'telegram'
    const lineMethod = 'line'

    const getButtonsBasedOnRegisterMethod = () => {
        switch (memberRegistrationMethod) {
            case Constants.memberRegistrationMethodsWhatsAppNoReplyAndTelegram:
                return (
                    <>
                        <BaseButton title={t('GLOBAL_WHATSAPP')} color={Constants.buttonGreenColor} onTap={() => {
                            setPopup(popup => ({
                                ...popup,
                                isOpen: false,
                                children: null,
                                shouldNotClosePopupOnBackClick: false,
                                onClosed: () => { }
                            }))

                            onTap(whatsappMethod)
                        }} />
                        <BaseButton title='TELEGRAM' color={Constants.buttonBlueColor} onTap={() => {
                            setPopup(popup => ({
                                ...popup,
                                isOpen: false,
                                children: null,
                                shouldNotClosePopupOnBackClick: false,
                                onClosed: () => { }
                            }))

                            onTap(telegramMethod)
                        }} />
                    </>
                )
            case Constants.memberRegistrationMethodsWhatsappAndSMSRegister:
            case Constants.memberRegistrationMethodsWhatsappNoReplyAndSMSRegister:
            case Constants.memberRegistrationMethodsTelegramAndSMSRegister:
            case Constants.memberRegistrationMethodsSMSRegisterAndLine:
                var buttonTitle = t('GLOBAL_WHATSAPP')
                var buttonColor = Constants.buttonGreenColor
                var method = whatsappMethod

                switch (memberRegistrationMethod) {
                    case Constants.memberRegistrationMethodsWhatsappAndSMSRegister:
                    case Constants.memberRegistrationMethodsWhatsappNoReplyAndSMSRegister:
                        buttonTitle = t('GLOBAL_WHATSAPP')
                        buttonColor = Constants.buttonGreenColor
                        method = whatsappMethod
                        break
                    case Constants.memberRegistrationMethodsTelegramAndSMSRegister:
                        buttonTitle = 'TELEGRAM'
                        buttonColor = Constants.buttonBlueColor
                        method = telegramMethod
                        break
                    case Constants.memberRegistrationMethodsSMSRegisterAndLine:
                        buttonTitle = 'LINE'
                        buttonColor = Constants.buttonGreenColor
                        method = lineMethod
                        break
                    default:
                        break
                }

                return (
                    <>
                        <BaseButton title={t('GLOBAL_SMS')} onTap={() => {
                            if (template.design === Constants.templateDesignModern) {
                                setPopup(popup => ({
                                    ...popup,
                                    isOpen: false,
                                    children: null,
                                    shouldNotClosePopupOnBackClick: false,
                                    onClosed: () => {
                                        setOpenRegister(true)
                                    }
                                }))
                            } else {
                                setPopup(popup => ({
                                    ...popup,
                                    isOpen: false,
                                    children: null,
                                    shouldNotClosePopupOnBackClick: false,
                                    onClosed: () => { }
                                }))

                                if (referralCode?.length > 0) {
                                    navigate(`/register?referralCode=${referralCode}`)
                                } else {
                                    navigate('/register')
                                }
                            }
                        }} />
                        <BaseButton title={buttonTitle} color={buttonColor} onTap={() => {
                            setPopup(popup => ({
                                ...popup,
                                isOpen: false,
                                children: null,
                                shouldNotClosePopupOnBackClick: false,
                                onClosed: () => { }
                            }))

                            onTap(method)
                        }} />
                    </>
                )
            default:
                //SMS only
                return null
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }} >
            <div style={{ fontSize: '14px' }} >
                <b>{'REGISTER OPTIONS'}</b>
            </div>
            <div style={{ height: '20px' }} />
            <Stack direction='column' spacing='10px'>
                {
                    getButtonsBasedOnRegisterMethod()
                }
            </Stack>
        </div>
    )
}