import { useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { AlertDialogContext, CompanyInfoContext, UserInfoContext } from "../../App"
import { request } from '../../feathers/feathersClient'
import Utils from "../../utils/utils"

function AutoLogin({isAdminLogin = false}) {
    let { uniqueId } = useParams()
    let navigate = useNavigate()

    //Context
    const { setCompanyInfo } = useContext(CompanyInfoContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const client = request({
        setAlertDialog: setAlertDialog, 
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })

    //API METHODS
    const getPlayerUniqueId = () => {
        //Call authenticate to clear feathers
        client.performLogout(() => {
            client.create('get-player-unique-id', {
                playerUniqueIdAutoLink: uniqueId,
                isAdminLogin: isAdminLogin,
                secretKey: 'thebestsecretkey',
            }, null, (auth) => {
                //Store user
                setUserInfo(auth.user)
    
                //Store token
                window.localStorage.setItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`, auth.accessToken)
    
                //Authenticate feathers
                client.reauthenticate(() => {
                    Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                        navigate('/')
                    }, true)
                })
            }, (error) => {
                setAlertDialog(alertDialog => ({
                    ...alertDialog,
                    title: error?.name,
                    translationData: error?.data,
                    description: error.message,
                    isOpen: true,
                    onClosed: () => {
                        //Back to home
                        Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                            navigate('/')
                        })
                    }
                }))
            })
        })
    }

    useEffect(() => {
        if (uniqueId && uniqueId.length > 0) {
            getPlayerUniqueId()
        } else {
            navigate('/', { replace: true })
        }
    }, [])

    return (
        <div>

        </div>
    )
}

export default AutoLogin