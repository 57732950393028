import React, { useContext } from "react"
import { Snackbar } from '@mui/material'
import { SnackBarContext } from '../../App'
import MuiAlert from '@mui/material/Alert';

function SnackBar() {
    const { snackBar, setSnackBar } = useContext(SnackBarContext)

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar(snackBar => ({
            ...snackBar,
            isOpen: false
        }))
    };

    return (
        <Snackbar open={snackBar.isOpen} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={snackBar.severity} sx={{ width: '100%' }}>
                <noindex>
                    {snackBar.title}
                </noindex>
            </Alert>
        </Snackbar>
    )
}

export default SnackBar