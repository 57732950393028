import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CompanyInfoContext, UserInfoContext } from "../../App"
import FeathersClient, { request } from '../../feathers/feathersClient'

export function useLiveDeposit() {
    let navigate = useNavigate()

    //Context
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const client = request({
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })

    //State
    const [data, setData] = useState(Array(5).fill(null))
    const [list, setList] = useState(Array(5).fill(null))

    //Ref
    const dataRef = useRef(data)

    //API Methods
    const findLiveTransactions = () => {
        client.find('live-transactions', {
            query: {
                company: companyInfo?.company?._id,
                $sort: {
                    createdAt: -1
                },
                type: 1,
                $populate: ['company', 'user', 'game'],
                $limit: 5,
            },
        }, (response) => {
            setData(response?.data ?? [])
        }, null, null, false)
    }

    //SOCKET METHODS
    const startSocket = () => {
        FeathersClient.listen('live-transactions', 'created', 'liveDeposit', (newData) => {
            if (newData?.type === 1) {
                let tempList = [...dataRef.current]
                tempList.pop()
                tempList = [newData, ...tempList]
                setData(tempList)
            }
        })
    }

    useEffect(() => {
        findLiveTransactions()
        startSocket()
    }, [])
    useEffect(() => {
        let mounted = true

        if (mounted) {
            dataRef.current = data

            let tempList = [...list]
            data?.forEach((e, index) => {
                tempList[index] = e
            })
            setList(tempList)
        }

        return function cleanup() {
            mounted = false
        }
    }, [data])

    return list
}

export function useLiveGameWithdrawal() {
    let navigate = useNavigate()

    //Context
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const client = request({
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })

    //State
    const [data, setData] = useState(Array(5).fill(null))
    const [list, setList] = useState(Array(5).fill(null))

    //Ref
    const dataRef = useRef(data)

    //API Methods
    const findLiveTransactions = () => {
        client.find('live-transactions', {
            query: {
                company: companyInfo?.company?._id,
                $sort: {
                    createdAt: -1
                },
                type: 2,
                $populate: ['company', 'user', 'game'],
                $limit: 5,
            },
        }, (response) => {
            setData(response?.data ?? [])
        }, null, null, false)
    }

    //SOCKET METHODS
    const startSocket = () => {
        FeathersClient.listen('live-transactions', 'created', 'liveWithdrawal', (newData) => {
            if (newData?.type === 2) {
                let tempList = [...dataRef.current]
                tempList.pop()
                tempList = [newData, ...tempList]
                setData(tempList)
            }
        })
    }

    useEffect(() => {
        findLiveTransactions()
        startSocket()
    }, [])
    useEffect(() => {
        let mounted = true

        if (mounted) {
            dataRef.current = data

            let tempList = [...list]
            data?.forEach((e, index) => {
                tempList[index] = e
            })

            setList(tempList)
        }

        return function cleanup() {
            mounted = false
        }
    }, [data])

    return list
}